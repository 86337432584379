import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionViewModel } from './SelectionViewModel';
import { SelectionAnimation } from './SelectionAnimation';
import { ModelBoxContext } from 'src_common/common/websocket2/common/ModelBoxContext';
import {
    PriceType as PriceTypeApi,
    PriceHistoryItemType,
    SelectionModelType,
    SpPriceType,
    FeedSettingsType,
    BetLimitsType,
    SelectionSettingsType,
} from 'src_common/common/websocket2/modelsApi/Market';
import { Value } from 'src_common/common/mobx-utils/Value';
import { TypeId } from 'src_common/common/websocket2/type';
import { MarketId, SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { RacingFormDetailsModelType } from 'src_common/common/websocket2/modelsApi/Event';

export type PriceType = PriceTypeApi;

export class SelectionModel {
    private readonly record: Value<SelectionModelType>;
    private readonly modelBoxContext: ModelBoxContext;
    private readonly animation: SelectionAnimation;

    public constructor(
        modelBoxContext: ModelBoxContext,
        animation: SelectionAnimation,
        record: Value<SelectionModelType>
    ) {
        this.modelBoxContext = modelBoxContext;
        this.animation = animation;
        this.record = record;
    }

    public getData(): SelectionModelType {
        return this.record.getValue();
    }

    public getRawData(): SelectionModelType {
        return this.record.getValue();
    }

    public get computedMetaData(): undefined | Record<string, string | null | undefined> {
        const event = this.getEvent();

        if (event !== null) {
            const participants = event.participants;

            for (const item of Object.values(participants)) {
                if (item.name === this.name) {
                    return item.metadata;
                }
            }
        }
    }

    public get computedRacingFormDetails(): undefined | null | RacingFormDetailsModelType[] {
        const event = this.getEvent();

        if (event !== null) {
            const participants = event.participants;

            for (const participant of Object.values(participants)) {
                if (participant.name === this.name) {
                    return participant.racingFormDetails;
                }
            }
        }
    }

    public get participantId(): number | null {
        const event = this.getEvent();

        if (event !== null) {
            const participants = event.participants;

            for (const item of Object.values(participants)) {
                if (item.name === this.name) {
                    return item.id;
                }
            }
        }

        return null;
    }

    /**
     * @deprecated - please use id2
     */
    public get id(): number {
        return this.getData().id;
    }

    public get id2(): SelectionId {
        return this.modelBoxContext
            .websocketId()
            .getSelectionId(this.getData().eventId, TypeId.getMarketId(this.getData().marketId), this.getData().id);
    }

    public get uuid(): string | null {
        return this.getData().uuid ?? null;
    }

    /**
     * @deprecated - The new trading will only temporarily support this old id
     */
    public get selectionIdentifiers(): string | undefined {
        return this.templateId;
    }

    public get display(): boolean {
        const display = this.getData().display;
        return display === true ? true : false;
    }

    public get eventId(): number {
        return this.getData().eventId;
    }

    public getEvent(): EventModel | null {
        const eventId = this.eventId;
        return this.modelBoxContext.getEvent(eventId);
    }

    /**
     * @deprecated Please use marketId2 instead.
     */
    public get marketId(): TypeId.MarketId {
        return this.getData().marketId;
    }

    public get marketId2(): MarketId {
        return this.modelBoxContext
            .websocketId()
            .getMarketId(this.getData().eventId, TypeId.getMarketId(this.getData().marketId));
    }

    public get spOnly(): boolean | void {
        const parentMarket = this.getMarket();

        if (parentMarket !== null) {
            return parentMarket.spOnly;
        }

        return false;
    }

    public get displayOrder(): number | undefined {
        return this.getData().displayOrder ?? undefined;
    }

    public getMarket(): MarketModel | null {
        const marketId = this.marketId;
        return this.modelBoxContext.getMarket(marketId);
    }

    public isEventStarted(): boolean {
        const event = this.getEvent();

        if (event !== null) {
            return event.timeSettingsStarted;
        }

        return false;
    }

    public get price(): PriceType | undefined {
        const price = this.getData().price;
        if (price === null) {
            return undefined;
        }

        return price;
    }

    public get oldPrice(): PriceType | undefined {
        const priceHistory = this.getData().priceHistory;

        if (priceHistory !== null && priceHistory !== undefined) {
            const priceModel = priceHistory[0];

            if (priceModel !== undefined) {
                const price = {
                    ...priceModel.p,
                    empty: undefined,
                };

                return price;
            }
        }

        return undefined;
    }

    public get resultType(): string | null {
        const type = this.getData().result?.type;
        if (type === null || type === undefined) {
            return null;
        }
        return type;
    }

    public get resultPlace(): number | null {
        const place = this.getData().result?.place;
        if (place === null || place === undefined) {
            return null;
        }
        return place;
    }

    public get spPrice(): SpPriceType | null {
        const { spPrice } = this.getData();
        if (spPrice === null || spPrice === undefined) {
            return null;
        }
        return spPrice;
    }

    public get sp(): boolean {
        return this.getMarket()?.sp ?? false;
    }

    public get isSP(): boolean {
        const parentEvent = this.getEvent();
        if (parentEvent !== null) {
            return this.sp && parentEvent.timeSettingsStarted !== true;
        }

        return false;
    }

    public get shouldDisplay(): boolean {
        return this.display && this.active;
    }

    public get templateId(): string | undefined {
        const template = this.getData().template;

        if (template !== undefined) {
            return template.id;
        }

        return undefined;
    }

    public get templateMarketId(): string | void {
        const template = this.getData().template;

        if (template !== undefined) {
            return template.marketTemplateId;
        }

        return undefined;
    }

    public get active(): boolean {
        return this.getData().active ?? false;
    }

    public get activated(): boolean {
        const parentMarket = this.getMarket();

        if (parentMarket !== null) {
            return parentMarket.activated && this.active;
        }

        return false;
    }

    private forViewModel(sp?: boolean): SelectionViewModel | null {
        const eventModel = this.getEvent();
        if (eventModel === null) {
            return null;
        }

        const marketModel = this.getMarket();
        if (marketModel === null) {
            return null;
        }

        return new SelectionViewModel(this.modelBoxContext, eventModel, marketModel, this, sp, this.animation);
    }

    public forView(sp?: boolean): SelectionViewModel | null {
        return this.forViewModel(sp);
    }

    public get name(): string {
        return this.getData().name ?? '';
    }

    public get state(): string {
        const fieldState = this.getData().state;
        return fieldState === undefined ? '' : fieldState;
    }

    public get isMetaData(): boolean {
        const metaData = this.computedMetaData;
        if (metaData === undefined) {
            return false;
        }
        return true;
    }

    public get metaDataSilkUrl(): string | undefined | null {
        const metaData = this.computedMetaData;

        if (metaData !== undefined) {
            // In old trading the key is defined in lowercase, whereas new trading uses camelCase
            // After switching to new trading lowercase option can be removed
            const silkuri = metaData.silkuri ?? metaData.silkUri;

            if (typeof silkuri === 'string') {
                if (silkuri.includes('sportingsolutions')) {
                    const parts = silkuri.split('/');

                    const chunk1 = parts[3];
                    const chunk2 = parts[5];
                    const chunk3 = parts[6];
                    const chunk4 = parts[7];

                    if (chunk1 !== undefined && chunk2 !== undefined && chunk3 !== undefined && chunk4 !== undefined) {
                        return `/api-web/silks/${chunk1}/${chunk2}/${chunk3}/${chunk4}`;
                    }

                    //return `/api-web/silks/${parts[3]}/${parts[5]}/${parts[6]}/${parts[7]}`;
                }
            }

            return silkuri;
        }
    }

    public get metaDataSilk(): string | undefined | null {
        const metaData = this.computedMetaData;
        if (metaData !== undefined) {
            return metaData.silk;
        }
    }

    public get metaDataJockey(): string | undefined | null {
        const metaData = this.computedMetaData;
        if (metaData !== undefined) {
            return metaData.jockey;
        }
    }

    public get metaDataTrainer(): string | undefined | null {
        const metaData = this.computedMetaData;
        if (metaData !== undefined) {
            return metaData.trainer;
        }
    }

    //trapNumberByProviderName() To remove with new trading service
    public get metaDataNumber(): string | undefined | null {
        const metaData = this.computedMetaData;
        if (metaData !== undefined) {
            return metaData.number;
        }
        return this.trapNumberByProviderName;
    }

    public get metaDataDrawn(): string | undefined | null {
        const metaData = this.computedMetaData;
        if (metaData !== undefined) {
            return metaData.drawn;
        }
    }

    public get metaDataStatus(): string | undefined | null {
        const metaData = this.computedMetaData;
        if (metaData !== undefined) {
            return metaData.status;
        }
    }

    //trapNumberByProviderName() To remove with new trading service
    public get trapNumberByProviderName(): string | null {
        const data = this.getData().platformObject?.name;
        if (data === undefined) {
            return null;
        }
        const trapNumber = data.slice(0, 1);
        if (isNaN(parseInt(trapNumber, 10)) === false) {
            return trapNumber;
        }
        return null;
    }

    public get marketDisplayTemplate(): string | undefined {
        const market = this.getMarket();
        if (market !== null) {
            return market.displayTemplateFirst;
        }
    }

    public get marketDisplayOrder(): number | undefined {
        const market = this.getMarket();
        if (market !== null) {
            return market.displayOrder;
        }
    }

    public get marketSelectionOrdering(): string | undefined {
        const market = this.getMarket();
        if (market !== null) {
            return market.displayOrderTag;
        }
    }

    /**
     * @deprecated - The new trading will only temporarily support this old id
     */
    public get identifier(): string | undefined {
        return this.selectionIdentifiers;
    }

    public get metaDataWeight(): string | undefined | null {
        return this.computedMetaData?.weight ?? null;
    }

    public get metaDataAge(): string | null {
        return this.computedMetaData?.age ?? null;
    }

    public get metaDataForm(): string | undefined | null {
        return this.computedMetaData?.form ?? null;
    }

    public get metaDataRunnerComment(): string | undefined | null {
        return this.computedMetaData?.runnerComment ?? null;
    }

    public get metaDataCourseType(): string | undefined | null {
        const metaData = this.computedMetaData;
        if (metaData !== undefined) {
            return metaData.courseType;
        }
    }

    public get priceHistory(): Array<PriceHistoryItemType> {
        return this.getData().priceHistory ?? [];
    }

    public getTag(name: 'price-boost' | 'pricewise'): string | undefined {
        return this.getData().tags[name];
    }

    public getAllTags(): Record<string, string> {
        return this.getData().tags;
    }

    public get feedSettings(): FeedSettingsType | undefined | null {
        return this.getData().feedSettings;
    }

    public get betLimits(): BetLimitsType | undefined | null {
        return this.getData().betLimits;
    }

    public get selectionSettings(): SelectionSettingsType | undefined | null {
        return this.getData().selectionSettings;
    }

    public setPriceAnimation(direction: 'up' | 'down'): void {
        this.animation.startAnimation(direction);
    }
}
