import styled from '@emotion/styled';

import { WatchLiveButton } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/watchLiveButton/WatchLiveButton';

import { withConfig } from 'src/withConfig';

import { css, SerializedStyles } from '@emotion/react';



export const RaceHeaderListMeta = withConfig(theme => styled('ul', { label: 'RaceHeaderListMeta' })` 

    display: flex;

    flex-flow: row wrap;

    font-size: ${theme.star.fontSize.small};

    font-weight: ${theme.star.fontWeight.regular};

    max-width: 100%;

    line-height: 1.3334;

    &:only-of-type {

        li:last-of-type {

            margin-right: 0;

            &::after {

                content: none;

            }

        }

    }

`);



interface RaceHeaderListItemMetaPropsType {

    isCourseType?: boolean;

}



export const RaceHeaderListItemMeta = withConfig(theme => styled('li', { label: 'RaceHeaderListItemMeta' })<RaceHeaderListItemMetaPropsType>`

    display: inline-block;

    margin: 0 16px 0 0;

    position: relative;

    &::after {

        background: currentcolor;

        bottom: 0;

        content: '';

        height: calc(12px / 1.33); /* fontSize.small / 1.33 */

        left: 100%;

        margin: auto 0 auto 7px;

        position: absolute;

        top: -1px;

        width: 1px;

    }

    ${({ isCourseType }): false | SerializedStyles => isCourseType === true && css`

        @media not ${theme.star.mediaQuery.tablet} {

            flex: 1 100%;

            white-space: nowrap;

            overflow: hidden;

            text-overflow: ellipsis;

            margin-right: 15%;

            width: 100%;

        }

    `}

`);



export const RaceHeaderListItemMetaHandicap = styled(RaceHeaderListItemMeta, { label: 'RaceHeaderListItemMetaHandicap' })`

    margin-right: 5px;

    &::after {

        content: none;

    }

`;



export const RaceHeaderListEwTerms = withConfig(theme => styled('ul', { label: 'RaceHeaderListEwTerms' })`

    display: inline-block;

    font-size: ${theme.star.fontSize.small};

    line-height: 1.3334;

`);



export const RaceHeaderListItem = withConfig(theme => styled('li', { label: 'RaceHeaderListItem' })`

    display: inline-block;

    font-weight: ${theme.star.fontWeight.regular};

    &:not(:last-of-type) {

        margin-right: 5px;

    }

`);



export const WatchLiveButtonRaceCard = styled(WatchLiveButton, { label: 'WatchLiveButtonRaceCard' })`

    margin: 6px 0 4px;

`;

