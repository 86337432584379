import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface WarningIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const NetworkIcon = observer('NetworkIcon', ({ className, onClick }: WarningIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            fill='none'
            height='96'
            onClick={onClick}
            viewBox='0 0 96 96'
            width='96'
            xmlns='http://www.w3.org/2000/svg'
            data-test='network-icon'
        >
            <path
                fill='fill'
                d='M73.833 81 42.5 49.5c-2.611.611-5.042 1.528-7.292 2.75a24.965 24.965 0 0 0-6.041 4.583l-7-7.166A35.233 35.233 0 0 1 27.917 45a43.707 43.707 0 0 1 6.583-3.5L27 34a49.884 49.884 0 0 0-6.375 3.875A45.465 45.465 0 0 0 15 42.667L8 35.5a49.207 49.207 0 0 1 5.542-4.792 70.817 70.817 0 0 1 6.125-4.041l-7-7L17.333 15l61.334 61.333L73.833 81ZM48 75.667c-2.333 0-4.306-.82-5.917-2.459-1.61-1.639-2.416-3.597-2.416-5.875 0-2.333.805-4.305 2.416-5.916C43.694 59.806 45.667 59 48 59c2.333 0 4.306.806 5.917 2.417 1.61 1.61 2.416 3.583 2.416 5.916 0 2.278-.805 4.236-2.416 5.875-1.611 1.64-3.584 2.459-5.917 2.459Zm19.667-19.834L50.833 39c4.5.444 8.709 1.583 12.625 3.417 3.917 1.833 7.375 4.25 10.375 7.25l-6.166 6.166ZM81 42.667c-4.278-4.278-9.236-7.625-14.875-10.042C60.486 30.208 54.445 29 48 29a44.044 44.044 0 0 0-6.667.5l-8.5-8.5a56.265 56.265 0 0 1 7.459-1.5c2.527-.333 5.097-.5 7.708-.5 7.889 0 15.25 1.472 22.083 4.417C76.917 26.36 82.89 30.389 88 35.5l-7 7.167Z'
            />
        </SvgElement>
    );
});
