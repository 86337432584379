import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { useAppStateContext } from 'src/appState/AppState';
import { ErrorRawType } from 'src_server/handlers/apiWeb/betting/postPossibleBets';

const parseMinimumOdds = (debugDetails: string): number => {
    // INFO: Just in case if debugDetails is not a string, there is a chance to get an error
    if (typeof debugDetails !== 'string') {
        return 0;
    }

    try {
        // debug value for this error looks like: BetMinOdds [0.333 GTE 1.5]
        const numericValues = debugDetails.match(/-?\d+(\.\d+)?/g);

        return numericValues === null ? 0 : parseFloat(numericValues[1] ?? '0');
    } catch {
        return 0;
    }
};

const getErrorMessage = (error: ErrorRawType): JSX.Element => {
    switch (error.field) {
        case 'potentialReturn': {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const debugDetails = error.debugDetails as string;

            const minOdds = parseMinimumOdds(debugDetails);

            return (
                <I18n
                    langKey='betslip.free-bets.minimum-odds-message'
                    defaultText='Minimum Odds on free bets are {minOdds}'
                    params={{ minOdds }}
                />
            );
        }
        case 'selectedFreeBets':
            return (
                <I18n
                    langKey='betslip.free-bets.stake-exceeded-message'
                    defaultText='Lower your stake if you want to use your bet credits.'
                />
            );
        case 'bets[].freeBets':
            return (
                <I18n
                    langKey='betslip.free-bets.bonus-exceeded-message'
                    defaultText="Added free bets can't cover all bets total stakes"
                />
            );
        default:
            return <span>{error.code}</span>;
    }
};

export const BonusEngineFreeBetErrorMessages = observer('BonusEngineFreeBetErrorMessages', () => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { bonusEngineFreeBetErrors } = betSlipState.basicBetSlipState;

    if (bonusEngineFreeBetErrors.length === 0) {
        return null;
    }

    const firstError = bonusEngineFreeBetErrors[0];

    if (firstError === undefined) {
        return null;
    }

    return (
        <Messages
            type='error'
            message={getErrorMessage(firstError)}
        />
    );
});
