import { RaceSummaryCaption, RunnerCell, RunnerCaption, SelectionCaption, RaceSummaryRow, PlaceCaption, SilkCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { SelectionCast } from 'src/domains/sportsbook/webview/components/selectionCast/SelectionCast';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const ExtraPlacesRaceSummaryCaption = withConfig(theme => styled(RaceSummaryCaption, { label: 'ExtraPlacesRaceSummaryCaption' })`
    height: 32px;
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: ${theme.star.selection.borderColor};
    width: 100%;
`);

export const ExtraPlacesRunnerCell = styled(RunnerCell, { label: 'ExtraPlacesRunnerCell' })`
    border-right: 0;
`;

export const ExtraPlacesRunnerCaption = withConfig(theme => styled(RunnerCaption, { label: 'ExtraPlacesRunnerCaption' })`
    border-right: 0;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const ExtraPlacesPlaceCaption = withConfig(theme => styled(PlaceCaption, { label: 'ExtraPlacesPlaceCaption' })`
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const ExtraPlacesSilkCaption = withConfig(theme => styled(SilkCaption, { label: 'ExtraPlacesSilkCaption' })`
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const ExtraPlacesSelectionCaption = withConfig(theme => styled(SelectionCaption, { label: 'ExtraPlacesSelectionCaption' })`
    flex: 0 0 64px;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const ExtraPlacesSelectionCast = withConfig(theme => styled(SelectionCast, { label: 'ExtraPlacesSelectionCast' })`
    flex: 0 0 64px;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const PlacesRaceSummaryRowHeader = styled(RaceSummaryRow, { label: 'PlacesRaceSummaryRowHeader' })`
    border-right: 0;
`;
