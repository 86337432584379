import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import { Trap } from 'src/domains/sportsbook/webview/components/trap/Trap';
import { SilkIcon } from 'src/domains/sportsbook/assets/icons/SilkIcon';
import { withConfig } from 'src/withConfig';

export const SelectionWrapper = styled('div', { label: 'SelectionWrapper' })`
    display: flex;
    height: 100%;
`;

export const RaceBoxWeight = withConfig(theme => styled('span', { label: 'RaceBoxWeight' })`
    align-items: center;
    border-left: 1px solid ${theme.star.racingNextOff.raceBox.borderColor};
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 172px;
    font-size: ${theme.star.fontSize.xRegular};
    height: 100%;
    padding-left: 10px;
    white-space: nowrap;
    width: 172px;
`);

export const RaceBoxWeightTitle = styled('b', { label: 'RaceBoxWeightTitle' })`
    padding: 0 5px 0 0;
`;

export const OutrightFill = styled('div', { label: 'OutrightFill' })`
    margin-left: 12px;
`;

const setRowBox = (isExtendSelection: boolean, theme: EmotionTheme): SerializedStyles => {
    if (isExtendSelection) {
        return css`
            @media ${theme.star.mediaQuery.xxMobile} {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
        `;
    }

    return css`
        flex-flow: column nowrap;
        justify-content: center;
    `;
};

interface RaceBoxWrapperPropsType {
    isExtendSelection: boolean;
}

export const RaceBoxWrapper = withConfig(theme => styled('div', { label: 'RaceBoxWrapper' })<RaceBoxWrapperPropsType>`
    ${({ isExtendSelection }): SerializedStyles => setRowBox(isExtendSelection, theme)}
    display: flex;
    flex: 1 1 0%;
    height: 100%;
    overflow: hidden;
    padding-right: 8px;
`);

const setRaceDesc = (truncateText: boolean): SerializedStyles => {
    return css`
        display: block;
        text-align: left;
        width: 100%;
        ${truncateText === true ? css`
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        `: null
        }
    `;
};

export const RacerName = withConfig(theme => styled('span', { label: 'RacerName' })<{truncateRacerName?: boolean}>`
    ${({ truncateRacerName }): SerializedStyles => setRaceDesc(truncateRacerName ?? true)}
    padding: ${({ truncateRacerName }): string => truncateRacerName === true ? '0' : '4px 0'};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

export const JockeyDescription = withConfig(theme => styled('span', { label: 'JockeyDescription' })`
    ${setRaceDesc(false)};
    color: ${theme.star.racingNextOff.raceBox.txtColorSecondary};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`);

export const RaceBoxSilk = styled('div', { label: 'RaceBoxSilk' })`
    align-items: center;
    display: flex;
    flex: 0 0 50px;
    height: 100%;
    justify-content: center;
`;

export const TrapWrapper = styled(Trap, { label: 'TrapWrapper' })`
    height: 24px;
    width: 24px;
`;

export const LazyImageWrapper = styled(LazyImage, { label: 'LazyImageWrapper' })`
    height: 22px;
    width: 29px;
`;

export const LazyImageWrapperNew = styled('div', { label: 'LazyImageWrapperNew' })`
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    flex-shrink: 0;
    justify-content: center;
    width: 50px;
`;

export const SilkIconWrapper = withConfig(theme => styled(SilkIcon, { label: 'SilkIconWrapper' })`
    fill: ${theme.star.racingNextOff.raceBox.bgColor};
`);

export const SilkIconWrapperNew = withConfig(theme => styled(SilkIcon, { label: 'SilkIconWrapperNew' })`
    fill: ${theme.star.racingNextOff.raceBox.bgColor};
    width: 40px;
`);

export const SilkIconWrapperNextRaces = withConfig(theme => styled(SilkIcon, { label: 'SilkIconWrapperNextRaces' })`
    fill: ${theme.star.racingNextOff.raceBox.bgColor};
    width: 32px;
`);
