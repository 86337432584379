import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const BannerWrapper = withConfig(theme => styled('div', { label: 'BannerWrapper' })`
    align-items: center;
    background-color: ${theme.star.srgBanner.bgColor};
    display: flex;
    justify-content: center;
    padding: 12px;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.srgBanner};
    @media ${theme.star.mediaQuery.desktop} {
        position: relative;
    }
`);

export const IdentityWrapper = withConfig(theme => styled('div', { label: 'IdentityWrapper' })`
    display: flex;
    width: 100%;
    flex-direction: column;

    @media ${theme.star.mediaQuery.tablet} {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`);

export const BannerContainer = styled('div', { label: 'BannerContainer' })`
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
`;

export const BannerHeader = withConfig(theme => styled('p', { label: 'BannerHeader' })`
    color: ${theme.star.srgBanner.txtColor};
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3333;
    margin: 0;
`);

export const BannerText = withConfig(theme => styled('p', { label: 'BannerText' })`
    color: ${theme.star.srgBanner.txtColorSecondary};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3333;
    margin: 0;
    text-align: left;
`);

export const BannerButton = withConfig(theme => styled(Button, { label: 'BannerButton' })`
    align-self: flex-start;
    position: relative;
    text-transform: capitalize;
    padding: 8px 12px;
    margin-top: 8px;
    margin-left: 0;

    @media ${theme.star.mediaQuery.tablet} {
        align-self: center;
        margin-top: 0;
        margin-left: 12px;
    }
`);

