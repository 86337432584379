import styled from '@emotion/styled';
import { PromotionsIcon } from 'src/domains/layouts/shared/assets/icons/PromotionsIcon';
import { withConfig } from 'src/withConfig';

export const DetailsTableMessage = withConfig(theme => styled('div', { label: 'DetailsTableMessage' })`
    align-items: center;
    background-color: ${theme.star.raceCardColors.bgColorDetailsTableMessage};
    color: ${theme.star.raceCardColors.txtColorInProgress};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    justify-content: center;
    line-height: 1.3334;
    padding: 16px 32px 17px;
    position: relative;
`);

export const MegaphoneIcon = styled(PromotionsIcon, { label: 'MegaphoneIcon' })`
    fill: currentcolor;
    margin-right: 5px;
    width: 18px;
`;

export const FilterContainer = withConfig(theme => styled('div', { label: 'FilterContainer' })`
    align-items: center;
    background-color: ${theme.star.raceCardColors.bgColorMarketsFilters};
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: ${theme.star.raceCardColors.borderColorHeaderDetails};
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 8px 0 8px 8px;
`);
