import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const HighLightedTextWrapper = withConfig(theme => styled('span', { label: 'HighLightedTextWrapper' })`

    color: ${theme.star.searchBar.txtColorQuinary};

    display: block;

    font-size: ${theme.star.fontSize.small};

    line-height: 1.3334;

`);



export const TextEmphasised = withConfig(theme => styled('strong', { label: 'TextEmphasised' })`

    color: ${theme.star.searchBar.txtColor};

`);

