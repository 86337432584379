import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    GamblingSafetyLink,
    GamblingSafetyTopBarLine,
    GamblingSafetyTopBarWrapper,
} from './GamblingSafetyTopBar.style';
import { GambleAwareLogo } from 'src/domains/layouts/shared/assets/iconography/logos/responsible-gambling/GambleAwareLogo';
import { GamblingHelpline } from 'src/domains/layouts/shared/assets/iconography/logos/responsible-gambling/GamblingHelpline';
import { TakeTimeToThink } from 'src/domains/layouts/shared/assets/iconography/logos/responsible-gambling/TakeTimeToThinkLogo';

export const GamblingSafetyTopBar = observer('GamblingSafetyTopBar', () => {
    return (
        <GamblingSafetyTopBarWrapper>
            <GamblingSafetyLink
                target='_blank'
                href='https://www.gambleaware.org/'
            >
                <GambleAwareLogo />
            </GamblingSafetyLink>

            <GamblingSafetyTopBarLine />

            <GamblingSafetyLink
                target='_blank'
                href='https://www.gamcare.org.uk/get-support/talk-to-us-now/'
            >
                <GamblingHelpline />
            </GamblingSafetyLink>

            <GamblingSafetyTopBarLine />

            <GamblingSafetyLink
                target='_blank'
                href='http://taketimetothink.co.uk/'
            >
                <TakeTimeToThink />
            </GamblingSafetyLink>
        </GamblingSafetyTopBarWrapper>
    );
});
