import * as React from 'react';
import styled from '@emotion/styled';

interface PropsType {
    className?: string;
}

const SvgElement = styled('svg', { label: 'SvgElement' })<PropsType>`
    display: block;
    .st0 {
        fill: #ffffff;
    }
    .st1 {
        fill: #ff5f00;
    }
    .st2 {
        fill: #ff8300;
    }
`;

export const LogoGamblingTherapy = ({ className }: PropsType): JSX.Element => (
    <SvgElement
        xmlns='http://www.w3.org/2000/svg'
        width='auto'
        height='48'
        fill='none'
        viewBox='0 0 510 150'
        className={className}
    >
        <g xmlns='http://www.w3.org/2000/svg'>
            <path
                className='st0'
                d='M71.5,112.9c9.1,0,13.7,4.5,13.7,4.5l-4.4,8.1c0,0-3.2-3.2-8.5-3.2c-6.6,0-9.3,4-9.3,8.6   c0,5.4,3.6,9.2,8.9,9.2c3.3,0,5.9-1.7,6.2-2.2v-1.6h-4.5v-7.6H87v19.9h-8.5v-1.5c0-0.5,0-1,0.1-1.1h-0.2c-0.4,0.6-3.3,3.3-8.9,3.3   c-9.6,0-17.4-7.2-17.4-18.2C52.1,120.8,59.7,112.9,71.5,112.9z'
            />
            <path
                className='st0'
                d='M108.3,112.9c11.3,0,18.9,7.7,18.9,18c0,10.5-7.6,18.5-18.9,18.5s-18.9-7.9-18.9-18.5   C89.3,120.6,96.9,112.9,108.3,112.9z M108.3,139.9c4.6,0,8-3.7,8-9c0-5.1-3.4-8.6-8-8.6s-8,3.5-8,8.6   C100.3,136.3,103.7,139.9,108.3,139.9z'
            />
            <path
                className='st0'
                d='M130.6,113.5H146c7.7,0,13,4.2,13,11.6c0,4-2.1,7.8-5.4,9.9v0.1c0.1,0.2,0.8,1.1,1.5,2.4l6.2,11.3h-11.6   l-5.5-11.1h-3v11.1h-10.7v-35.3H130.6z M144.5,129.5c2.4,0,3.7-1.4,3.7-3.8c0-2.2-0.9-3.7-4.1-3.7h-2.8v7.4h3.2V129.5z'
            />
            <path
                className='st0'
                d='M163.4,113.5h14.3c10.9,0,17.9,6.5,17.9,17.6s-7,17.7-17.9,17.7h-14.3V113.5z M177,140.1c4.8,0,7.6-3.1,7.6-9   c0-5.9-3-8.9-7.6-8.9h-3v17.9H177z'
            />
            <path
                className='st0'
                d='M216.4,112.9c11.3,0,18.9,7.7,18.9,18c0,10.5-7.6,18.5-18.9,18.5c-11.3,0-18.9-7.9-18.9-18.5   C197.4,120.6,205,112.9,216.4,112.9z M216.4,139.9c4.6,0,8-3.7,8-9c0-5.1-3.4-8.6-8-8.6s-8,3.5-8,8.6   C208.4,136.3,211.7,139.9,216.4,139.9z'
            />
            <path
                className='st0'
                d='M238.8,113.5H249l8.7,14.5c1.5,2.4,2.5,5.4,2.5,5.4h0.2c0,0-0.4-3.2-0.4-5.4v-14.5h10.3v35.3h-10.4l-8.7-15.1   c-1.3-2.1-2.5-5.3-2.5-5.3h-0.2c0,0,0.4,3.1,0.4,5.3v15.1h-10.1L238.8,113.5L238.8,113.5z'
            />
            <path
                className='st0'
                d='M286.3,113.5h11.6l4.4,14.2c0.7,2.3,1.5,5.7,1.5,5.7h0.2c0,0,0.7-3.4,1.5-5.7l4.4-14.2h11.6l2.6,35.3h-9.9   l-1-14.7c-0.2-2.7,0-5.7,0-5.7H313c0,0-0.9,3.5-1.6,5.7l-3.2,10h-8.6l-3.3-10c-0.7-2.2-1.6-5.7-1.6-5.7h-0.2c0,0,0.1,3,0,5.7   l-0.9,14.7h-10L286.3,113.5z'
            />
            <path
                className='st0'
                d='M345.5,112.9c11.3,0,18.9,7.7,18.9,18c0,10.5-7.6,18.5-18.9,18.5s-18.9-7.9-18.9-18.5   C326.5,120.6,334.1,112.9,345.5,112.9z M345.5,139.9c4.6,0,8-3.7,8-9c0-5.1-3.4-8.6-8-8.6s-8,3.5-8,8.6   C337.5,136.3,340.9,139.9,345.5,139.9z'
            />
            <path
                className='st0'
                d='M385.1,112.9c11.3,0,18.9,7.7,18.9,18c0,10.5-7.6,18.5-18.9,18.5s-18.9-7.9-18.9-18.5   C366.2,120.6,373.8,112.9,385.1,112.9z M385.1,139.9c4.6,0,8-3.7,8-9c0-5.1-3.4-8.6-8-8.6s-8,3.5-8,8.6   C377.1,136.3,380.5,139.9,385.1,139.9z'
            />
            <path
                className='st0'
                d='M407.5,113.5h14.3c10.9,0,17.9,6.5,17.9,17.6s-7,17.7-17.9,17.7h-14.3V113.5z M421.1,140.1   c4.8,0,7.6-3.1,7.6-9c0-5.9-3-8.9-7.6-8.9h-3v17.9H421.1z'
            />
            <path
                className='st0'
                d='M449.1,134.8l-11.6-21.3h11.4l3.4,7.3c1,2.2,2,5.3,2,5.3h0.2c0,0,1-3.1,2.1-5.3l3.3-7.3h11.5l-11.6,21.3v14   h-10.7L449.1,134.8L449.1,134.8z'
            />
            <g>
                <circle
                    className='st1'
                    cx='21'
                    cy='131.1'
                    r='9.3'
                />
                <polygon
                    className='st1'
                    points='21,119.3 25.2,115.1 25.2,110.1 16.8,110.1 16.8,115.1   '
                />
                <polygon
                    className='st2'
                    points='15.2,120.9 16.7,115.2 14.2,110.8 6.9,115 9.4,119.4   '
                />
                <polygon
                    className='st1'
                    points='10.9,125.2 9.3,119.4 4.9,116.9 0.7,124.2 5.1,126.7   '
                />
                <polygon
                    className='st2'
                    points='9.3,131.1 5.1,126.9 0,126.9 0,135.3 5.1,135.3   '
                />
                <polygon
                    className='st1'
                    points='10.9,136.9 5.1,135.4 0.7,137.9 4.9,145.2 9.3,142.7   '
                />
                <polygon
                    className='st2'
                    points='15.2,141.3 9.4,142.8 6.9,147.2 14.2,151.4 16.7,147   '
                />
                <polygon
                    className='st1'
                    points='21,142.8 16.8,147 16.8,152.1 25.2,152.1 25.2,147   '
                />
                <polygon
                    className='st2'
                    points='26.9,141.3 25.4,147 27.9,151.4 35.2,147.2 32.7,142.8   '
                />
                <polygon
                    className='st1'
                    points='31.2,137 32.7,142.7 37.1,145.3 41.4,138 37,135.4   '
                />
                <polygon
                    className='st2'
                    points='32.8,131.1 37,135.3 42.1,135.3 42.1,126.9 37,126.9   '
                />
                <polygon
                    className='st1'
                    points='31.2,125.2 37,126.7 41.4,124.2 37.1,116.9 32.8,119.4   '
                />
                <polygon
                    className='st2'
                    points='26.9,120.9 32.7,119.4 35.2,115 27.9,110.8 25.4,115.2   '
                />
            </g>
        </g>
        <polygon
            xmlns='http://www.w3.org/2000/svg'
            className='st1'
            points='450.6,53.9 425.9,53.9 412.5,53.9 0,53.9 0,100.2 412.5,100.2 425.9,100.2 451.4,100.2 473.9,76.8 '
        />
        <polygon
            xmlns='http://www.w3.org/2000/svg'
            className='st1'
            points='277.2,0 252.5,0 239.1,0 0,0 0,46.3 239.1,46.3 252.5,46.3 278,46.3 300.5,22.9 '
        />
        <g xmlns='http://www.w3.org/2000/svg'>
            <path
                className='st0'
                d='M16.4,11.1H8V5.8h22.8v5.3h-8.4v29.6h-5.9V11.1H16.4z'
            />
            <path
                className='st0'
                d='M35,5.8h6v14.8h10.8V5.8h5.9v34.9h-5.9V26H41v14.7h-6V5.8z'
            />
            <path
                className='st0'
                d='M64.5,5.8h16.6v5.3H70.5v9.3H79v5.3h-8.5v9.6h11.2v5.3H64.5V5.8z'
            />
            <path
                className='st0'
                d='M86.8,5.8h10.1c1.3,0,3.1,0.3,4.6,1.1c3.1,1.5,5.3,4.8,5.3,9.3c0,4.6-2.5,8.1-5.1,9.6v0.1c0,0,0.4,0.6,1,1.9   l5.8,13h-6.6l-5.3-13h-3.8v13h-6V5.8z M96,22.4c2.5,0,4.7-2.1,4.7-5.7c0-2.1-0.7-3.8-1.9-4.7c-0.8-0.5-1.9-0.8-2.7-0.8h-3.3v11.2   C92.8,22.4,96,22.4,96,22.4z'
            />
            <path
                className='st0'
                d='M118.9,5.8h6.5l9.2,34.9h-6l-2.2-9h-8.5l-2.1,9h-6L118.9,5.8z M125.5,26.7l-2.2-9.4c-0.5-2-1-5.4-1-5.4h-0.1   c0,0-0.5,3.4-1,5.4l-2.3,9.4H125.5z'
            />
            <path
                className='st0'
                d='M138.5,5.8h9.9c6.1,0,10.3,4.6,10.3,11.4s-4.2,11.4-10.3,11.4h-3.9v12h-6V5.8z M147.4,23.4   c3.2,0,5.3-2.5,5.3-6.1c0-3.7-2.2-6.1-5.2-6.1h-3v12.2C144.5,23.4,147.4,23.4,147.4,23.4z'
            />
            <path
                className='st0'
                d='M168.8,26.1l-8.9-20.3h6.6l3.9,10.2c0.8,2.2,1.4,4.6,1.4,4.6h0.1c0,0,0.6-2.5,1.4-4.6l3.8-10.2h6.6l-8.9,20.3   v14.6h-5.9V26.1H168.8z'
            />
            <path
                className='st0'
                d='M196.1,5.8h16.2v5.3h-10.2v10.1h8.5v5.3h-8.5v14.2h-6V5.8z'
            />
            <path
                className='st0'
                d='M229.6,5.2c8.6,0,14,7.3,14,17.8c0,10.6-5.5,18.2-14,18.2c-8.6,0-14-7.6-14-18.3   C215.5,12.6,220.9,5.2,229.6,5.2z M229.6,35.5c4.9,0,7.9-5,7.9-12.5c0-7.4-2.9-12.1-7.9-12.1s-7.9,4.7-7.9,12   C221.7,30.6,224.6,35.5,229.6,35.5z'
            />
            <path
                className='st0'
                d='M249,5.8h10.1c1.3,0,3.1,0.3,4.6,1.1c3.1,1.5,5.3,4.8,5.3,9.3c0,4.6-2.5,8.1-5.1,9.6v0.1c0,0,0.4,0.6,1,1.9   l5.8,13h-6.6l-5.3-13H255v13h-6V5.8z M258.2,22.4c2.5,0,4.7-2.1,4.7-5.7c0-2.1-0.7-3.8-1.9-4.7c-0.8-0.5-1.9-0.8-2.7-0.8H255v11.2   C255,22.4,258.2,22.4,258.2,22.4z'
            />
            <path
                className='st0'
                d='M24.1,59c3.2,0,6.8,1.1,9.2,3l-2.6,5.1c-1.6-1.5-3.9-2.5-6.4-2.5c-5.9,0-9.2,5.1-9.2,12.3   c0,7.5,3.7,12.4,8.7,12.4c2.2,0,4.3-0.8,5.6-2.5v-5.5h-3.9V76h9.2v18.4H30v-1.3c0-0.6,0.1-1.3,0.1-1.3H30c-1.4,1.9-4.2,3.2-7.3,3.2   C15.7,95.1,9,88.4,9,77.1C9,66.3,15,59,24.1,59z'
            />
            <path
                className='st0'
                d='M46.7,59.6h6.5l9.2,34.9h-6l-2.2-9h-8.5l-2.1,9h-6L46.7,59.6z M53.3,80.5l-2.2-9.4c-0.5-2-1-5.4-1-5.4H50   c0,0-0.5,3.4-1,5.4l-2.3,9.4H53.3z'
            />
            <path
                className='st0'
                d='M67.5,59.6h6.6l4.7,16.9c0.5,2.1,1.1,5.3,1.1,5.3H80c0,0,0.6-3.2,1.1-5.3l4.7-16.9h6.6l2.2,34.9H89l-1.2-19.4   c-0.1-2.3,0-5.4,0-5.4h-0.1c0,0-0.6,3.5-1.2,5.6l-3.7,13.2H77l-3.7-13.2c-0.5-2-1.1-5.6-1.1-5.6H72c0,0,0.2,3.1,0,5.4l-1.2,19.4   h-5.6L67.5,59.6z'
            />
            <path
                className='st0'
                d='M100.5,59.6h9.4c5.6,0,9.6,3.2,9.6,9c0,3.3-1.5,6.1-4.1,7.5v0.1c3.6,1.1,5.2,4.5,5.2,8.3   c0,7-4.4,10.1-10.5,10.1h-9.6V59.6z M110.2,73.9c2,0,3.2-2,3.2-4.6s-1.3-4.4-3.7-4.4h-3.2v9H110.2z M110,89.2   c3.1,0,4.5-2.2,4.5-5.1c0-3.1-1.5-5.2-3.8-5.2h-4.2v10.3C106.5,89.2,110,89.2,110,89.2z'
            />
            <path
                className='st0'
                d='M125.9,59.6h6v29.6h11.2v5.3h-17.2C125.9,94.5,125.9,59.6,125.9,59.6z'
            />
            <path
                className='st0'
                d='M147.4,59.6h6v34.9h-6V59.6z'
            />
            <path
                className='st0'
                d='M160.2,59.6h6.3l9,19.7c0.9,2.1,1.8,5.3,1.8,5.3h0.2c0,0-0.3-3.3-0.3-5.4V59.6h5.6v34.9h-6.2l-9-19.8   c-0.9-2.2-1.8-5.3-1.8-5.3h-0.2c0,0,0.3,3.3,0.3,5.4v19.7h-5.6V59.6H160.2z'
            />
            <path
                className='st0'
                d='M203.1,59c3.2,0,6.8,1.1,9.2,3l-2.6,5.1c-1.6-1.5-3.9-2.5-6.4-2.5c-5.9,0-9.2,5.1-9.2,12.3   c0,7.5,3.7,12.4,8.7,12.4c2.2,0,4.3-0.8,5.6-2.5v-5.5h-3.9V76h9.2v18.4H209v-1.3c0-0.6,0.1-1.3,0.1-1.3H209   c-1.4,1.9-4.2,3.2-7.3,3.2c-7.1,0-13.7-6.6-13.7-17.9C188,66.3,194,59,203.1,59z'
            />
            <path
                className='st0'
                d='M234.8,59.6h6.5l9.2,34.9h-6l-2.2-9h-8.5l-2.1,9h-6L234.8,59.6z M241.4,80.5l-2.2-9.4c-0.5-2-1-5.4-1-5.4H238   c0,0-0.5,3.4-1,5.4l-2.3,9.4H241.4z'
            />
            <path
                className='st0'
                d='M254.4,59.6h9.7c8.6,0,14,6.4,14,17.4s-5.5,17.5-14,17.5h-9.7L254.4,59.6L254.4,59.6z M263.8,89.1   c5.1,0,8.1-4.2,8.1-12.1s-3.1-12-8.1-12h-3.4v24.2L263.8,89.1L263.8,89.1z'
            />
            <path
                className='st0'
                d='M283.5,59.6h9.7c8.6,0,14,6.4,14,17.4s-5.5,17.5-14,17.5h-9.7V59.6z M293,89.1c5.1,0,8.1-4.2,8.1-12.1   S298,65,293,65h-3.4v24.2L293,89.1L293,89.1z'
            />
            <path
                className='st0'
                d='M312.7,59.6h6v34.9h-6V59.6z'
            />
            <path
                className='st0'
                d='M339.3,59c3,0,6.2,0.9,8.5,2.9l-2.5,5.2c-1.2-1.3-3.6-2.4-5.8-2.4c-6,0-9.3,5.4-9.3,12.1   c0,6.8,3.5,12.7,9.2,12.7c2.6,0,4.7-1.1,6.5-2.6l2.5,5.1c-2.2,1.8-5.1,3.1-9.4,3.1c-9.4,0-14.9-7.8-14.9-18.1   C324,66.6,329.6,59,339.3,59z'
            />
            <path
                className='st0'
                d='M358.6,64.9h-8.4v-5.3H373v5.3h-8.4v29.6h-5.9V64.9H358.6z'
            />
            <path
                className='st0'
                d='M377.2,59.6h6v34.9h-6V59.6z'
            />
            <path
                className='st0'
                d='M402.6,59c8.6,0,14,7.3,14,17.8c0,10.6-5.5,18.2-14,18.2c-8.6,0-14-7.6-14-18.3C388.6,66.4,394,59,402.6,59z    M402.6,89.3c4.9,0,7.9-5,7.9-12.5c0-7.4-2.9-12.1-7.9-12.1s-7.9,4.7-7.9,12C394.7,84.4,397.7,89.3,402.6,89.3z'
            />
            <path
                className='st0'
                d='M422,59.6h6.3l9,19.7c0.9,2.1,1.8,5.3,1.8,5.3h0.2c0,0-0.3-3.3-0.3-5.4V59.6h5.6v34.9h-6.2l-9-19.8   c-0.9-2.2-1.8-5.3-1.8-5.3h-0.2c0,0,0.3,3.3,0.3,5.4v19.7H422V59.6z'
            />
        </g>
    </SvgElement>
);
