import { RaceSummaryCaption, RunnerCell, RunnerCaption, SelectionCaption, RaceSummaryRow, PlaceCaption, SilkCaption } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummary.style';
import { SelectionCast } from 'src/domains/sportsbook/webview/components/selectionCast/SelectionCast';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const LengthenTheOddsRaceSummaryCaption = withConfig(theme => styled(RaceSummaryCaption, { label: 'LengthenTheOddsRaceSummaryCaption' })`
    height: 32px;
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: ${theme.star.selection.borderColor};
    width: 100%;
`);

export const LengthenTheOddsRunnerCell = styled(RunnerCell, { label: 'LengthenTheOddsRunnerCell' })`
    border-right: 0;
`;

export const LengthenTheOddsRunnerCaption = withConfig(theme => styled(RunnerCaption, { label: 'LengthenTheOddsRunnerCaption' })`
    border-right: 0;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const LengthenTheOddsPlaceCaption = withConfig(theme => styled(PlaceCaption, { label: 'LengthenTheOddsPlaceCaption' })`
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const LengthenTheOddsSilkCaption = withConfig(theme => styled(SilkCaption, { label: 'LengthenTheOddsSilkCaption' })`
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const LengthenTheOddsSelectionCaption = withConfig(theme => styled(SelectionCaption, { label: 'LengthenTheOddsSelectionCaption' })`
    flex: 0 0 140px;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
    white-space: normal;
    word-wrap: break-word;
    padding: 4px;
    font-size: 7px;

    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 64px;
    }
`);

export const LengthenTheOddsSelectionCast = withConfig(theme => styled(SelectionCast, { label: 'LengthenTheOddsSelectionCast' })`
    flex: 0 0 140px;
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
`);

export const LengthenTheOddsRaceSummaryRowHeader = styled(RaceSummaryRow, { label: 'LengthenTheOddsRaceSummaryRowHeader' })`
    border-right: 0;
`; 
