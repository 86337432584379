import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface GambleAwareLogoPropsType {
    className?: string;
    onClick?: () => void;
}

export const GambleAwareLogo = observer(
    'GambleAwareLogo',
    ({ className, onClick }: GambleAwareLogoPropsType): JSX.Element => (
        <SvgElement
            className={className}
            onClick={onClick}
            width='91'
            height='12'
            viewBox='0 0 91 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M0.0161133 6.23086C0.0161133 2.088 1.82297 0 4.67325 0C6.64582 0 8.13725 0.961143 8.55096 3.13257L8.61725 3.48114L6.92696 3.84571L6.86068 3.53143C6.54525 2.07314 5.73382 1.50971 4.64011 1.50971C3.0984 1.50971 1.87211 2.85257 1.87211 6.11657C1.87211 9.19886 2.98297 10.4914 4.54068 10.4914C5.65154 10.4914 6.94411 9.912 6.94411 7.87314V7.52571H4.50754V6.08343H8.63439V11.8011H7.15896L7.05953 10.392C6.51211 11.5349 5.43554 12 4.17611 12C1.65725 11.9977 0.0161133 9.99314 0.0161133 6.23086Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M14.9966 11.0034C14.4823 11.5497 13.8035 11.9817 12.7098 11.9977C11.1521 11.9977 10.1406 10.9531 10.1406 9.61142C10.1406 8.15313 11.3509 7.43999 12.4115 7.1417L14.9806 6.41256V6.11428C14.9806 5.18628 14.4 4.72228 13.4892 4.72228C12.6435 4.72228 11.9646 5.25256 11.6663 5.84913L11.6161 5.94856L10.3063 5.25256L10.3898 5.08685C10.7543 4.34056 11.7658 3.31313 13.6378 3.31313C15.5098 3.31313 16.704 4.25828 16.704 5.96456V10.3234C16.704 10.7371 16.7212 11.2846 16.8035 11.6491L16.8366 11.7988H15.1132L15.096 11.7154C15.0458 11.4834 15.0126 11.2514 14.9966 11.0034ZM13.1566 10.688C13.9189 10.688 14.5989 10.24 14.9795 9.75999V7.67199L13.1898 8.21942C12.5109 8.43428 11.9132 8.78285 11.9132 9.49599C11.9132 10.2091 12.3943 10.6891 13.1566 10.6891V10.688Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M26.712 4.80571C26.0331 4.80571 25.32 5.26971 24.9223 5.66742V11.7988H23.1988V6.08113C23.1988 5.23542 22.768 4.80456 21.9394 4.80456C21.2605 4.80456 20.5474 5.26856 20.1497 5.66628V11.7977H18.4263V3.51313H20.0674V4.42514C20.6971 3.76228 21.592 3.31428 22.52 3.31428C23.68 3.31428 24.376 3.84456 24.7074 4.57371C25.3371 3.82742 26.2983 3.31428 27.2925 3.31428C29 3.31428 29.696 4.45828 29.696 5.70056V11.7988H27.9725V6.08113C27.9725 5.23542 27.5417 4.80456 26.7131 4.80456L26.712 4.80571Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M33.2251 11.0366L33.0594 11.7989H31.6011V0.198853H33.3245V4.19314C33.8216 3.62971 34.5839 3.31428 35.3794 3.31428C37.2685 3.31428 38.5451 4.72342 38.5451 7.54057C38.5451 10.3577 37.2022 11.9989 35.3131 11.9989C34.4674 11.9989 33.6891 11.6503 33.2251 11.0377V11.0366ZM34.9816 10.6057C35.9599 10.6057 36.7885 9.71085 36.7885 7.58971C36.7885 5.46857 35.9268 4.72228 34.9485 4.72228C34.2856 4.72228 33.7382 5.05371 33.3245 5.48457V9.81028C33.7554 10.2583 34.3188 10.6057 34.9816 10.6057Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M39.9692 0.198853H41.6927V11.7989H39.9692V0.198853Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M43.2173 7.77256C43.2173 5.15428 44.527 3.31428 46.8139 3.31428C48.4218 3.31428 50.111 4.27542 50.111 7.25828V8.03656H44.9076V8.13599C44.9076 9.74399 45.7533 10.6388 46.879 10.6388C47.6081 10.6388 48.2218 10.3406 48.6356 9.59428L48.735 9.41142L50.0938 9.99199L50.0116 10.1577C49.3978 11.384 48.3041 11.9977 46.8298 11.9977C44.7418 11.9977 43.2173 10.5223 43.2173 7.77142V7.77256ZM48.487 6.89371V6.77713C48.487 5.26856 47.6916 4.70513 46.8139 4.70513C45.9361 4.70513 45.0733 5.36799 44.9247 6.89256H48.4881L48.487 6.89371Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M54.6847 0.198853H57.1876L60.4687 11.7989H58.6287L57.8162 8.74971H53.9716L53.159 11.7989H51.4184L54.6847 0.198853ZM57.4196 7.32457L55.895 1.624L54.3704 7.32457H57.4196Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M60.0366 3.51313H61.76L63.1189 9.4297L64.6275 3.51313H66.2515L67.7269 9.4297L69.136 3.51313H70.7109L68.5726 11.7988H66.8652L65.3738 5.89942L63.8652 11.7988H62.1749L60.0366 3.51313Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M75.7969 11.0034C75.2826 11.5497 74.6038 11.9817 73.5101 11.9977C71.9523 11.9977 70.9409 10.9531 70.9409 9.61142C70.9409 8.15313 72.1501 7.43999 73.2118 7.1417L75.7809 6.41256V6.11428C75.7809 5.18628 75.2003 4.72228 74.2895 4.72228C73.4449 4.72228 72.7649 5.25256 72.4666 5.84913L72.4175 5.94856L71.1078 5.25256L71.1912 5.08685C71.5558 4.34056 72.5672 3.31313 74.4392 3.31313C76.3112 3.31313 77.5055 4.25828 77.5055 5.96456V10.3234C77.5055 10.7371 77.5226 11.2846 77.6049 11.6491L77.6381 11.7988H75.9146L75.8975 11.7154C75.8483 11.4834 75.8152 11.2514 75.7981 11.0034H75.7969ZM73.9581 10.688C74.7203 10.688 75.4003 10.24 75.7809 9.75999V7.67199L73.9912 8.21942C73.3112 8.43428 72.7146 8.78285 72.7146 9.49599C72.7146 10.2091 73.1958 10.6891 73.9581 10.6891V10.688Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M79.1772 3.51313H80.7852V5.07085C81.879 3.79428 82.8572 3.4137 83.6858 3.39656V5.00456C82.5258 5.00456 81.6138 5.65142 80.9018 6.29713V11.7988H79.1784V3.51313H79.1772Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
            <path
                d='M83.8662 7.77256C83.8662 5.15428 85.1759 3.31428 87.4628 3.31428C89.0708 3.31428 90.7611 4.27542 90.7611 7.25828V8.03656H85.5576V8.13599C85.5576 9.74399 86.4033 10.6388 87.5302 10.6388C88.2593 10.6388 88.8731 10.3406 89.2868 9.59428L89.3862 9.41142L90.7451 9.99199L90.6628 10.1577C90.049 11.384 88.9553 11.9977 87.4811 11.9977C85.3931 11.9977 83.8685 10.5223 83.8685 7.77142L83.8662 7.77256ZM89.1359 6.89371V6.77713C89.1359 5.26856 88.3405 4.70513 87.4616 4.70513C86.5828 4.70513 85.7211 5.36799 85.5725 6.89256H89.1359V6.89371Z'
                fill='#2B2428'
                fillOpacity='0.8'
            />
        </SvgElement>
    )
);
