import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const SelectionGroupWrap = styled('div', { label: 'SelectionGroupWrap' })`
    /* margin-top: 10px; */
`;

export const SelectionsContainer = withConfig(theme => styled('div', { label: 'SelectionsContainer' })`
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    @media ${theme.star.mediaQuery.tablet} {
        flex-flow: row nowrap;
    }
`);

export const SectionWrapper = styled('section', { label: 'SectionWrapper' })`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;
