import { LiveTvIcon } from 'src/domains/layouts/shared/assets/icons/LiveTvIcon';

import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const WatchLiveButtonWrapper = withConfig(theme => styled('button', { label: 'WatchLiveButtonWrapper' })<{isDisabled?: boolean; streamOn?: boolean}>`

    appearance: none;

    background-color: ${({ isDisabled }): string => isDisabled === true ? theme.star.watchLiveButton.bgColorSecondary : theme.star.watchLiveButton.bgColor};

    border: 0;

    color: ${({ isDisabled }): string => isDisabled === true ? theme.star.watchLiveButton.txtColorSecondary : theme.star.watchLiveButton.txtColor};

    cursor: ${({ isDisabled, streamOn }): string => isDisabled === true || streamOn === true ? 'initial' : 'pointer'};

    font-size: ${theme.star.fontSize.small};

    font-weight: ${theme.star.fontWeight.bold};

    line-height: 1.3334;

    padding: 5px 4px 4px 24px;

    position: relative;

    opacity: ${({ streamOn }): string => streamOn === true ? '0.4' : '1'};

`);



export const StreamIconButtonWrapper = styled(LiveTvIcon, { label: 'StreamIconButtonWrapper' })<{isDisabled?: boolean}>`

    bottom: 0;

    fill: currentcolor;

    left: 4px;

    margin: auto 0;

    position: absolute;

    top: 0;

    width: 16px;

`;

