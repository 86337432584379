import React from 'react';
import { IdAndFaceValidation, IdValidation } from 'idmission-web-sdk';
import { IdmissionSDKType } from 'src/domains/players/state/IdMission/IdMissionPopup/IdMissionPopup';
import { useAppStateContext } from 'src/appState/AppState';
import { PersonalData } from 'idmission-web-sdk/dist/components/submission/types';

interface PropsType {
    sessionId: string;
    openType: IdmissionSDKType;
    handleToggle: () => void;
    personalData: PersonalData;
    handleFinalize: (id: number, response: unknown) => Promise<void>;
    id: number;
}

export const IdMissionValidation = ({
    sessionId,
    openType,
    personalData,
    handleToggle,
    handleFinalize,
    id,
}: PropsType): JSX.Element | null => {
    const { appPlayersState } = useAppStateContext();

    const handleComplete = async (response: unknown): Promise<void> => {
        handleToggle();
        await handleFinalize(id, response);
        appPlayersState.identityState?.toggleIdMissionPopup();
        appPlayersState.identityState?.refreshIdMissionData();
    };

    const handleUserCancel = (): void => {
        handleToggle();
        appPlayersState.identityState?.toggleIdMissionPopup();
        appPlayersState.identityState?.refreshIdMissionData();
    };

    switch (openType) {
        case 'IdCard':
            return (
                <IdValidation
                    sessionId={sessionId}
                    onComplete={handleComplete}
                    onUserCancel={handleUserCancel}
                    personalData={personalData}
                    debugMode={false}
                />
            );

        case 'IdCardProofOfAddress':
            return (
                <IdValidation
                    sessionId={sessionId}
                    onComplete={handleComplete}
                    onUserCancel={handleUserCancel}
                    personalData={personalData}
                    captureAdditionalDocuments={[
                        {
                            name: 'POA',
                            processAsPOA: true,
                            description: 'POA',
                        },
                    ]}
                    debugMode={false}
                />
            );

        case 'IdCardAndFace':
            return (
                <IdAndFaceValidation
                    sessionId={sessionId}
                    onComplete={handleComplete}
                    onUserCancel={handleUserCancel}
                    personalData={personalData}
                    debugMode={false}
                />
            );

        case 'IdCardAndFaceProofOfAddress':
            return (
                <IdAndFaceValidation
                    sessionId={sessionId}
                    onComplete={handleComplete}
                    onUserCancel={handleUserCancel}
                    personalData={personalData}
                    captureAdditionalDocuments={[
                        {
                            name: 'POA',
                            processAsPOA: true,
                            description: 'POA',
                        },
                    ]}
                    debugMode={false}
                />
            );

        default:
            return null;
    }
};
