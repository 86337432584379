import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
// eslint-disable-next-line no-restricted-imports
import { useAsObservableSource } from 'mobx-react-lite';
import { RaceSummaryRowLengthenTheOdds } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/lengthenTheOddsSummary/RaceSummaryRowLengthenTheOdds';
import { RaceSummaryTwoColumnsList } from 'src/domains/sportsbook/webview/components/raceSummary/templates/scrollMarkets/RaceSummaryWithScroll';
import { useAppStateContext } from 'src/appState/AppState';
import {
    LengthenTheOddsSummaryPropsType,
    LengthenTheOddsSummaryState,
    getSelectionByName,
} from './LengthenTheOddsSummary.state';
import {
    getPlace,
    getRunner,
    NotRunningPriceView,
} from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';
import { ScrollRowWrapper } from 'src/domains/sportsbook/webview/components/raceSummary/templates/scrollMarkets/RaceSummaryWithScroll.style';
import {
    LengthenTheOddsSelectionCast,
    LengthenTheOddsRaceSummaryCaption,
    LengthenTheOddsRunnerCaption,
    LengthenTheOddsSelectionCaption,
    LengthenTheOddsPlaceCaption,
    LengthenTheOddsSilkCaption,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/lengthenTheOddsSummary/LengthenTheOddsSummary.style';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';

function selectionsBuilder(
    selectionName: string,
    markets: MarketModel[],
    isNonRunnerRow: boolean,
    oddsType: 'f' | 'd'
): Array<JSX.Element | null> | null {
    return markets.map((market) => {
        const selectionInCurrentRow = getSelectionByName(selectionName, market);
        if (isNonRunnerRow === true) {
            return <NotRunningPriceView key={selectionInCurrentRow?.id2.toOldId()} />;
        }

        if (selectionInCurrentRow === null) {
            return null;
        }

        return (
            <LengthenTheOddsSelectionCast
                key={selectionInCurrentRow.id2.key}
                selectionId={selectionInCurrentRow.id2}
                title={oddsType === 'd' ? selectionInCurrentRow.price?.d : selectionInCurrentRow.price?.f}
                borderLeft={true}
                index={selectionInCurrentRow.id}
            />
        );
    });
}

export const LengthenTheOddsSummary = observer(
    'LengthenTheOddsSummary',
    (propsIn: LengthenTheOddsSummaryPropsType): JSX.Element | null => {
        const props = useAsObservableSource(propsIn);
        const { appSportsBookState } = useAppStateContext();

        const [state] = useState(() => new LengthenTheOddsSummaryState(props));

        const selectionsByPrice = state.selectionsByPrice;
        if (selectionsByPrice === undefined) {
            return null;
        }

        const firstSelection = selectionsByPrice[0];
        if (firstSelection === undefined) {
            return null;
        }

        const marketModel = firstSelection.getMarket();
        if (marketModel === null) {
            return null;
        }
        const oddsTypeShort = appSportsBookState.getOddsFormat();

        const StaticList = (): JSX.Element => {
            return (
                <>
                    <LengthenTheOddsRaceSummaryCaption>
                        <LengthenTheOddsPlaceCaption>#</LengthenTheOddsPlaceCaption>
                        <LengthenTheOddsSilkCaption>
                            <I18n
                                langKey='events.race-summary.silk.label'
                                defaultText='Silk'
                            />
                        </LengthenTheOddsSilkCaption>
                        <LengthenTheOddsRunnerCaption>
                            <I18n
                                langKey='events.race-summary.runner.label'
                                defaultText='Runner'
                            />
                        </LengthenTheOddsRunnerCaption>
                    </LengthenTheOddsRaceSummaryCaption>

                    {selectionsByPrice.map((selection) => {
                        return (
                            <RaceSummaryRowLengthenTheOdds
                                key={selection.id2.key}
                                isNonRunner={state.isNonRunnerInRow(selection.name)}
                                selectionId={selection.id2}
                                runner={getRunner(selection, false, true, true)}
                                place={getPlace(selection)}
                            />
                        );
                    })}
                </>
            );
        };

        const ScrollList = (): JSX.Element => {
            return (
                <>
                    <LengthenTheOddsRaceSummaryCaption>
                        {state.marketsTerms.map((market) => (
                            <LengthenTheOddsSelectionCaption key={market.marketId.toOldId()}>
                                {market.terms}
                            </LengthenTheOddsSelectionCaption>
                        ))}
                    </LengthenTheOddsRaceSummaryCaption>
                    {selectionsByPrice.map((selection) => (
                        <ScrollRowWrapper
                            key={`price-row-${selection.id2.key}`}
                            isNonRunner={state.isNonRunnerInRow(selection.name)}
                        >
                            {selectionsBuilder(
                                selection.name,
                                state.sortedMarkets,
                                state.isNonRunnerInRow(selection.name),
                                oddsTypeShort
                            )}
                        </ScrollRowWrapper>
                    ))}
                </>
            );
        };

        return (
            <RaceSummaryTwoColumnsList
                staticListElements={StaticList()}
                scrollListElements={ScrollList()}
                columnCount={state.marketsTerms.length}
            />
        );
    }
);
