import { z } from 'zod';
import { TDetailedErrorResponse } from 'src_server/trpc/types/error/detailedError';

export enum BlockingNotificationType {
    changeOfLicense = 'changeOfLicense',
    termsUpdate = 'termsUpdate',
    kycDepositRestriction = 'kycDepositRestriction',
}

export const accountBasicDataResponse = z.object({
    addressLine1: z.string().nullable().optional(),
    addressLine2: z.string().nullable().optional(),
    // ageVerification: z.string(), //BO confidential,
    antePostRules: z.boolean(),
    // bankAccountNumber: z.string().nullable().optional(), //BO confidential,
    bankAccountSortCode: z.string().nullable().optional(),
    betReferralEnabled: z.boolean(),
    birthDate: z.string(),
    // bogLimit: z.number().nullable().optional(), //BO confidential
    bpEnabled: z.boolean(),
    cancelWithdrawal: z.boolean().nullable().optional(),
    cashoutEnabled: z.boolean(),
    chatEnabled: z.boolean(),
    city: z.string().nullable().optional(),
    // closureReasonId: z.number().nullable().optional(), //BO confidential,
    // commission: z.boolean(), //BO confidential
    complianceRestriction: z
        .object({
            eventTime: z.string(),
            pendingSuspensionTime: z.string(),
            source: z.string(),
        })
        .nullable()
        .optional(),
    contactPreferences: z.array(z.string()),
    coolingOffPeriodHours: z.number().nullable().optional(),
    country: z.string().nullable().optional(),
    countryClub: z.boolean().nullable().optional(),
    county: z.string().nullable().optional(),
    creditAccount: z.boolean(),
    // creditLimit: z.number(), //BO confidential
    // creditPaymentTerms: z.string(), //BO confidential
    currency: z.string(),
    dailyDepositLimit: z.number().nullable().optional(),
    dailyDepositLimitDateSet: z.string().nullable().optional(),
    dailyDepositLimitRequestDate: z.string().nullable().optional(),
    depositRestriction: z
        .object({
            description: z.string(),
            expiryDate: z.string().nullable().optional(),
            permanent: z.boolean(),
            source: z.string(),
            updatedAt: z.string(),
        })
        .nullable()
        .optional(),
    displayNetDepositLimitsPopUp: z.boolean().nullable().optional(),
    dragonCircle: z.boolean().nullable().optional(),
    email: z.string().nullable().optional(),
    externalId: z.string().nullable().optional(),
    failedLoginAttempts: z.number(),
    firstName: z.string().nullable().optional(),
    fullName: z.string().nullable().optional(),
    // gamStopVerification: z.string(), //BO confidential
    id: z.number(),
    incomeaccess: z.string().nullable().optional(),
    // isHVC: z.boolean().nullable().optional(),
    kycRequestExpireDate: z.string().nullable().optional(),
    kycStatus: z.string(),
    // kycWatchlist: z
    //     .object({
    //         pep: z.boolean(),
    //         reviewDate: z.string().nullable().optional(),
    //         sanctions: z.boolean(),
    //         validationDate: z.string().nullable().optional(),
    //     })
    //     .nullable()
    //     .optional(), //BO confidential
    landlinePhone: z
        .object({
            country: z.string(),
            number: z.string(),
            prefix: z.string(),
        })
        .nullable()
        .optional(),
    language: z.string().nullable().optional(),
    mailingEnabled: z.boolean(),
    marketing: z.boolean().nullable().optional(),
    marketingQuestionsAnswers: z.record(z.string(), z.string()).nullable().optional(),
    maxDailyDepositLimit: z.number().nullable().optional(),
    maxLifetimeNetDepositLimit: z.number().nullable().optional(),
    maxMonthlyDepositLimit: z.number().nullable().optional(),
    maxWeeklyDepositLimit: z.number().nullable().optional(),
    mobilePhone: z
        .object({
            country: z.string(),
            number: z.string(),
            prefix: z.string(),
        })
        .nullable()
        .optional(),
    monthlyDepositLimit: z.number().nullable().optional(),
    monthlyDepositLimitDateSet: z.string().nullable().optional(),
    monthlyDepositLimitRequestDate: z.string().nullable().optional(),
    name: z.string(),
    // nextReviewDate: z.string().nullable().optional(), //BO confidential,
    oddsFormat: z.string(),
    personalId: z.string().nullable().optional(),
    playBreakDuration: z.number().nullable().optional(),
    playBreakExpiry: z.string().nullable().optional(),
    playBreakRequestDate: z.string().nullable().optional(),
    pofStatus: z
        .union([z.literal('not-checked'), z.literal('requested'), z.literal('passed'), z.literal('failed')])
        .nullable()
        .optional(),
    postCode: z.string().nullable().optional(),
    printedStatement: z.boolean(),
    // promoBanned: z.boolean().nullable().optional(), //BO confidential,
    // promoID: z.string().nullable().optional(), //BO confidential,
    prompts: z
        .object({
            notificationList: z.array(
                z.object({
                    active: z.boolean(),
                    name: z.union([
                        z.literal(BlockingNotificationType.changeOfLicense),
                        z.literal(BlockingNotificationType.termsUpdate),
                        z.literal(BlockingNotificationType.kycDepositRestriction),
                    ]),
                })
            ),
        })
        .nullable()
        .optional(),
    pushNotificationsEnabled: z.boolean().nullable().optional(),
    // quickBetReferralEnabled: z.boolean(), //BO confidential,
    reactivationRequestedDate: z.string().nullable().optional(),
    realityCheckFrequency: z.number().nullable().optional(),
    realityCheckFrequencyDateSet: z.string().nullable().optional(),
    realityCheckFrequencyRequestDate: z.string().nullable().optional(),
    referrer: z.string().nullable().optional(),
    requestedDailyDepositLimit: z.number().nullable().optional(),
    requestedMonthlyDepositLimit: z.number().nullable().optional(),
    requestedRealityCheckFrequency: z.number().nullable().optional(),
    requestedWeeklyDepositLimit: z.number().nullable().optional(),
    // reviewNetLosses: z.number().nullable().optional(), //BO confidential,
    // reviewTotalDeposits: z.number().nullable().optional(), //BO confidential,
    rewardZone: z.boolean().nullable().optional(),
    ringFencedFunds: z.boolean(),
    roles: z.array(z.string()),
    // securityNotes: z.string().nullable().optional(), //BO confidential
    selfExclusionDate: z.string().nullable().optional(),
    selfExclusionDuration: z.number().nullable().optional(),
    selfExclusionExpiry: z.string().nullable().optional(),
    spOnlySports: z.array(z.string()).nullable().optional(),
    srgStatus: z
        .union([z.literal('not-started'), z.literal('requested'), z.literal('passed'), z.literal('failed')])
        .nullable()
        .optional(),
    // stakeFactor: z.number(), //BO confidential
    // stakeFactorInPlay: z.number(), //BO confidential
    statementFrequency: z.string(),
    status: z.string(),
    // statusDescription: z.string().nullable().optional(), //BO confidential
    surname: z.string().nullable().optional(),
    tartanClub: z.boolean().nullable().optional(),
    telebettingId: z.string().nullable().optional(),
    telebettingPassword: z.string().nullable().optional(),
    terms: z.boolean().nullable().optional(),
    title: z.string().nullable().optional(),
    // tradingNotes: z.string().nullable().optional(), //BO confidential
    type: z.string(),
    universe: z.string(),
    // watchlist: z.boolean(),
    weeklyDepositLimit: z.number().nullable().optional(),
    weeklyDepositLimitDateSet: z.string().nullable().optional(),
    weeklyDepositLimitRequestDate: z.string().nullable().optional(),
    monthlyActivityReportLastConfirmedAt: z.string().nullable().optional(),
});

export type AccountBasicDataResponseType = z.infer<typeof accountBasicDataResponse>;

export type ResponseType =
    | { responseStatus: 'success'; data: AccountBasicDataResponseType }
    | { responseStatus: 'error'; data: TDetailedErrorResponse };
