import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const LogoGroup = styled(`div`, { label: 'LogoGroup' })`
    display: flex;
    padding: 16px;
    gap: 8px;
`;

export const LogoContainerStyled = withConfig(theme => styled(Link, { label: 'LogoContainerStyled' }) <{ type: 'primary' | 'secondary' }>`
    display: flex;
    height: 56px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: ${({ type }): string => type === 'primary' ? theme.star.iconContainer.bgColor : theme.star.iconContainer.bgColorSecondary};
    color: ${({ type }): string => type === 'primary' ? theme.star.iconContainer.iconColor : theme.star.iconContainer.iconColorSecondary};
`);

