import {
    ApiFinalizeParamsType,
    apiFinalizeRequest,
    ApiFinalizeResponseType,
} from 'src/api_openapi/v2/idMission/apiFinalize';
import { apiGetSessionIdRequest, ApiGetSessionIdResponseType } from 'src/api_openapi/v2/idMission/apiGetSessionId';
import {
    apiVerifyProgressRequest,
    ApiVerifyProgressResponseType,
} from 'src/api_openapi/v2/idMission/apiVerifyProgress';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export class IdentityAPI {
    public static get = AutoWeakMap.create((common: Common) => new IdentityAPI(common));

    private constructor(private readonly common: Common) {}

    public getSessionId = async (account_id: number): Promise<ApiGetSessionIdResponseType> => {
        if (this.common.envVariables.gatewayApiHost === null) {
            throw new Error('Unknown gatewayApiHost');
        }

        return await apiGetSessionIdRequest(
            this.common.envVariables.gatewayApiHost,
            {
                account_id,
            },
            {
                authorization: `Bearer ${this.common.session.currentJwt}`,
            }
        );
    };

    public getVerifyProgress = async (account_id: number): Promise<ApiVerifyProgressResponseType> => {
        if (this.common.envVariables.gatewayApiHost === null) {
            throw new Error('Unknown gatewayApiHost');
        }

        return await apiVerifyProgressRequest(
            this.common.envVariables.gatewayApiHost,
            {
                account_id,
            },
            {
                authorization: `Bearer ${this.common.session.currentJwt}`,
            }
        );
    };

    public postFinalizeRequest = async (params: ApiFinalizeParamsType): Promise<ApiFinalizeResponseType> => {
        if (this.common.envVariables.gatewayApiHost === null) {
            throw new Error('Unknown gatewayApiHost');
        }

        return await apiFinalizeRequest(this.common.envVariables.gatewayApiHost, params, {
            authorization: `Bearer ${this.common.session.currentJwt}`,
        });
    };
}
