
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const GamblingSafetyTopBarWrapper = withConfig(theme => styled('div', { label: 'GamblingSafetyTopBarWrapper' })`
    height: 40px;
    gap: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.star.gamblingSafetyTopBar.bgColor};
`);

export const GamblingSafetyTopBarLine = withConfig(theme => styled('div', { label: 'GamblingSafetyTopBarLine' })`
    width: 1px;
    height: 12px;
    background-color: ${theme.star.gamblingSafetyTopBar.bgColorSecondary};
`);

export const GamblingSafetyLink = styled('a', { label: 'GamblingSafetyLink' })`
    display: flex;
    gap: 4px;
    height: 16px;
`;
