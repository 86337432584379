import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    LinkItemLinkType,
    NavigationPanel,
} from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { HelpSectionWrapper } from './NavigationSidebar.style';
import { useCommon } from 'src/domains/common/Common';
import { TraderChatState } from 'src/domains/players/state/traderChat/TraderChatState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { ConfigType } from 'src/domains/layouts/config/features/types';

const getLinks = (isAuth: boolean, traderChat: boolean, features: ConfigType): Array<LinkItemLinkType> => {
    const {
        footerAdditionalContent,
        footerUsefulLinks: { faq },
    } = features;
    const out: Array<LinkItemLinkType> = [
        {
            key: 'faq',
            to:
                faq === undefined
                    ? {
                          account: 'static',
                          static: 'help',
                      }
                    : faq.to,
            label: (
                <I18n
                    langKey='left-menu.faq.label'
                    defaultText="FAQ's"
                />
            ),
        },
    ];

    if (isAuth) {
        if (traderChat) {
            out.push({
                key: 'trader-chat',
                to: {
                    account: 'trader-chat',
                },
                label: (
                    <I18n
                        langKey='left-menu.live-chat.label'
                        defaultText='Live Chat'
                    />
                ),
            });
        }

        if (footerAdditionalContent === false) {
            out.push({
                key: 'limits',
                to: {
                    account: 'limits',
                },
                label: (
                    <I18n
                        langKey='left-menu.responsible-gambling.label'
                        defaultText='Responsible Gambling'
                    />
                ),
            });
        } else {
            out.push({
                key: 'safer-gambling',
                to: {
                    account: 'limits',
                },
                label: (
                    <I18n
                        langKey='left-menu.safer-gambling.label'
                        defaultText='Safer Gambling'
                    />
                ),
            });
        }
    }

    return out;
};

interface PropsType {
    withWrapper: boolean;
}

export const NavigationSidebarSectionSupport = observer(
    'NavigationSidebarSectionSupport',
    (props: PropsType): React.ReactElement | null => {
        const common = useCommon();
        const { withWrapper } = props;

        const sdkCustomer = SdkCustomer.get(common);
        const configComponents = ConfigComponents.get(common);
        const traderChatState = TraderChatState.get(common);

        const { config } = configComponents;
        const { navigationHelpSupport } = config;
        const authenticated = sdkCustomer.session.isAuthorized;

        if (navigationHelpSupport === false) {
            return null;
        }

        const panel = (
            <NavigationPanel
                title={
                    <I18n
                        langKey='left-menu.help-and-support.title'
                        defaultText='Help &amp; Support'
                    />
                }
                titleDataTest='help-support'
                links={getLinks(authenticated, traderChatState.isTraderChatForView, config)}
            />
        );

        if (withWrapper) {
            return <HelpSectionWrapper>{panel}</HelpSectionWrapper>;
        }

        return panel;
    }
);
