import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    DescriptionsWrapper,
    Description,
    MarkedTxt,
    AdditionalDescriptionUpdated,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/ringFencedFundsWrapper/RingFencedFundsWrapper.style';

export const RingFencedFundsMessageUpdated = observer('RingFencedFundsMessageUpdated', () => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState } = appLayoutsState;

    const markedTxt1 = languagesState.getTranslation(
        'account.top-up.ringfenced-funds.description-marked-1',
        'licence conditions detailing what we need to tell customers about their funds.'
    );
    const markedTxt2 = languagesState.getTranslation(
        'account.top-up.ringfenced-funds.additional-description-marked-2',
        'the customer funds insolvency ratings system.'
    );

    return (
        <DescriptionsWrapper>
            <Description data-test='ringfenced-funds-description'>
                <I18n
                    langKey='account.top-up.ringfenced-funds.description-line1'
                    defaultText='We are required by our licence to inform customers about what happens to funds which we hold on account for you in the event of insolvency. The Gambling Commission provides further information on our '
                />
                <MarkedTxt
                    href='https://www.gamblingcommission.gov.uk/guidance/customer-funds-segregation-disclosure-to-customers-and-reporting/advice-on-implementing-licence-condition-4-2-1-disclosure-to-customers'
                    target='_blank'
                    rel='noopener'
                    data-test='ringfenced-funds-not-protected'
                >
                    {markedTxt1}
                </MarkedTxt>
            </Description>

            <AdditionalDescriptionUpdated data-test='ringfenced-funds-additional-description'>
                <I18n
                    langKey='account.top-up.ringfenced-funds.description-line2'
                    defaultText='We hold customer funds separate from company funds. These funds are not protected in the event of insolvency: not protected segregation. For more information, please see '
                />
                <MarkedTxt
                    href='https://www.gamblingcommission.gov.uk/guidance/customer-funds-segregation-disclosure-to-customers-and-reporting/the-customer-funds-insolvency-ratings-system'
                    target='_blank'
                    rel='noopener'
                    data-test='ringfenced-funds-insolvency-ratings-system'
                >
                    {markedTxt2}
                </MarkedTxt>
            </AdditionalDescriptionUpdated>

            <Description data-test='ringfenced-funds-description'>
                <I18n
                    langKey='account.top-up.ringfenced-funds.description-line3'
                    defaultText='By clicking pay below you acknowledge receipt of this information.'
                />
            </Description>
        </DescriptionsWrapper>
    );
});
