import styled from '@emotion/styled';
import { RacingPostIcon } from 'src/domains/layouts/shared/assets/icons/RacingPostIcon';
import { LiveTvIcon } from 'src/domains/layouts/shared/assets/icons/LiveTvIcon';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

interface MeetingsListPropsType {
    isOpen: boolean;
}

export const MeetingsListStyle = withConfig(theme => styled('section', { label: 'MeetingsListStyle' }) <MeetingsListPropsType>`
    background-color: ${theme.star.meetingsListColors.bgColorSecondary};
    display: ${({ isOpen }): string => isOpen ? 'block' : 'none'};
    @media ${theme.star.mediaQuery.tablet} {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            border-top: 1px solid ${theme.star.meetingsRowColors.borderColor};
            left: 0;
            right: 0;
            top: 0;
            z-index: ${theme.star.zIndexGlobal.above};
        }
    }
`);

export const MeetingsListInfo = withConfig(theme => styled('div', { label: 'MeetingsListInfo' })`
    background: ${theme.star.meetingsListColors.bgColorTertiary};
    border-color: ${theme.star.meetingsRowColors.borderColor};
    border-style: solid;
    border-width: 0 1px;
    color: ${theme.star.meetingsListColors.txtColor};
    font-size: ${theme.star.fontSize.xRegular};
    margin: -1px 0 1px;
    padding: 10px 5px;
    text-align: center;
`);

export const MeetingRowName = withConfig(theme => styled('div', { label: 'MeetingRowName' })`
    align-items: center;
    background-color: ${theme.star.meetingsRowColors.bgColorTertiary};
    color: ${theme.star.headings.market.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3333;
    min-height: 24px;
    overflow: hidden;
    padding: 0 8px;
    text-overflow: ellipsis;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        border-right: 1px solid ${theme.star.meetingsRowColors.borderColor};
        flex: 0 0 156px;
        font-weight: ${theme.star.fontWeight.regular};
    }
    @media ${theme.star.mediaQuery.largeDesktop} {
        font-size: ${theme.star.fontSize.small};
    }
`);

export const MeetingRowNameChevron = styled(ChevronIcon, { label: 'MeetingRowNameChevron' })`
    height: 100%;
    width: 12px;
    margin: 0 8px 0 3px;
    fill: currentcolor;
`;

export const StreamIco = withConfig(theme => styled(LiveTvIcon, { label: 'StreamIco' })`
    margin-left: 5px;
    width: 16px;
    fill: ${theme.star.meetingsListColors.bgColorQuaternary};
`);

export const MeetingsRowWrapper = withConfig(theme => styled('div', { label: 'MeetingsRowWrapper' })`
    border: none;
    font-size: ${theme.star.fontSize.xRegular};
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    @media ${theme.star.mediaQuery.tablet} {
        border-left: 1px solid ${theme.star.meetingsRowColors.borderColor};
        display: flex;
        flex-direction: row;
        &::before,
        &::after {
            content: '';
            position: absolute;
        }
        &::before {
            border-bottom: 1px solid ${theme.star.meetingsRowColors.borderColor};
            left: 0;
            right: 0;
            bottom: 0;
            z-index: ${theme.star.zIndexGlobal.above};
        }
        &::after {
            border-right: 1px solid ${theme.star.meetingsRowColors.borderColor};
            bottom: 0;
            right: 0;
            top: 0;
        }
    }
`);

interface MeetingsEventLinkPropsType {
    isInProgress: boolean;
    isFinished: boolean;
}

export const MeetingsEventLink = withConfig(theme => styled(Link, { label: 'MeetingsEventLink' }) <MeetingsEventLinkPropsType>`
    align-items: center;
    background-color: ${theme.star.meetingsRowColors.bgColor};
    border-right: 1px solid ${theme.star.meetingsRowColors.borderColorSecondary};
    color: ${({ isInProgress, isFinished }): string => isInProgress || isFinished ? theme.star.meetingsRowColors.txtColorSecondary : theme.star.meetingsRowColors.txtColor};
    display: flex;
    flex-flow: row nowrap;
    flex: 0 0 70px;
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4285;
    height: 40px;
    justify-content: center;
    padding: 5px;
    position: relative;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
        opacity: 0.8;
    }
    @media ${theme.star.mediaQuery.tablet} {
        border-bottom: 1px solid ${theme.star.meetingsRowColors.borderColor};
        border-right: 1px solid ${theme.star.meetingsRowColors.borderColor};
        flex: 0 0 calc(100% / 8);
        min-width: 70px;
        padding: 3px 0;
    }
`);

export const TimeData = styled('span', { label: 'TimeData' })`
    display: block;
    max-width: 37px;
    width: 100%;
`;

export const RacingPostIconWrapper = withConfig(theme => styled(RacingPostIcon, { label: 'RacingPostIconWrapper' })`
    width: 16px;
    margin-right: 4px;
    fill: ${theme.star.meetingsRowColors.bgColorSecondary};
`);

export const LoaderWrapperMeetings = styled(Loader, { label: 'LoaderWrapperMeetings' })`
    justify-content: center;
`;
