import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';
import { LazyImage } from 'src/domains/sportsbook/webview/components/lazyImage/LazyImage';
import EMPTY_SILK_old from 'src/domains/layouts/shared/assets/silk/empty-hr-silk-old.webp';
import EMPTY_SILK from 'src/domains/sportsbook/webview/components/raceSummary/assets/empty-hr-silk.svg';
import { PriceHistoryNew } from 'src/domains/sportsbook/webview/components/priceHistory/PriceHistory';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import {
    PriceHistoryCell,
    RunnerCell,
    RunnerCellAntePost,
    SilkCell,
    RaceSummaryCaption,
    RaceSummaryRow,
    UnnamedFavouriteLabel,
    RunnerCellDetails,
    RunnerCellsWrapper,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/RaceSummaryNew.style';
import {
    SpInProgressNew,
    WinInProgressNew,
} from 'src/domains/sportsbook/webview/components/selectionRaceCard/SelectionRaceCard.style';
import { RunnerDetails } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummaryRowSport/RunnerDetails';
import { RaceSummaryRowHorseRacingNewState } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummaryRowSport/RaceSummaryRowHorseRacingNew.state';
import { RaceSummaryRowDetails } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummaryRowSport/RaceSummaryRowDetails';

interface PropsType {
    className?: string;
    antePost: boolean;
    isSpecials?: boolean;
    isUnnamedFavourite: boolean;
    tags?: React.ReactNode;
    runner?: React.ReactNode;
    sp?: React.ReactNode;
    winner?: React.ReactNode;
    selectionId: SelectionId;
    isNonRunner?: boolean;
    isRaceInProgress?: boolean;
    isPriceHistory?: boolean;
    dataTest?: string;
}

export const RaceSummaryRowHorseRacingNew = observer('RaceSummaryRowHorseRacingNew', (props: PropsType) => {
    const common = useCommon();
    const config = ConfigComponents.get(common).config;
    const [runnerDetailsState] = useState(() => new RaceSummaryRowHorseRacingNewState());

    const {
        antePost,
        isSpecials,
        className,
        isUnnamedFavourite,
        tags,
        runner,
        sp,
        winner,
        selectionId,
        isNonRunner,
        isRaceInProgress,
        isPriceHistory,
        dataTest,
    } = props;

    const selectionModel = selectionId.getModel();
    if (selectionModel === null) {
        return null;
    }

    const marketModel = selectionModel.getMarket();
    if (marketModel === null) {
        return null;
    }

    const isOnlyPrice = marketModel.newTemplateId === 'win-only';
    const silkUrl = selectionModel.metaDataSilkUrl;

    if (isUnnamedFavourite) {
        return (
            <RaceSummaryRow
                className={className}
                isNonRunner={isNonRunner}
                tags={tags}
                isAlternativeTag={config.isAlternativeRacingBoost}
                data-test={dataTest}
            >
                <UnnamedFavouriteLabel isAntePost={antePost}>{runner}</UnnamedFavouriteLabel>
                {isRaceInProgress === true ? <SpInProgressNew isPriceHistory={isPriceHistory} /> : sp}
                {tags}
            </RaceSummaryRow>
        );
    } else if (marketModel.newTemplateId.includes('place-betting')) {
        return (
            <ul>
                <li>
                    <RaceSummaryCaption className={className}>
                        <SilkCell>
                            <LazyImage
                                src={silkUrl}
                                placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                                alt={selectionModel.metaDataSilk}
                            />
                        </SilkCell>

                        <RunnerCellsWrapper>
                            <RunnerCell>{runner}</RunnerCell>
                            <RunnerDetails
                                runnerDetailsState={runnerDetailsState}
                                selectionModel={selectionModel}
                            />
                        </RunnerCellsWrapper>

                        {isRaceInProgress === true ? <WinInProgressNew isPriceHistory={isPriceHistory} /> : winner}
                        {tags}
                    </RaceSummaryCaption>

                    {config.isRasGoldActive
                        ? runnerDetailsState.expandedRaceId === selectionId.key && (
                              <RaceSummaryRowDetails selectionModel={selectionModel} />
                          )
                        : null}
                </li>
            </ul>
        );
    } else if (antePost) {
        const isHideRunnerInfo = selectionModel.name === '';
        return (
            <RaceSummaryRow
                className={className}
                isNonRunner={isNonRunner}
                tags={tags}
                isAlternativeTag={config.isAlternativeRacingBoost}
                data-test={dataTest}
            >
                <RunnerCellAntePost isHideRunnerInfo={isHideRunnerInfo}>{runner}</RunnerCellAntePost>
                {isRaceInProgress === true ? <WinInProgressNew isPriceHistory={isPriceHistory} /> : winner}
                {tags}
            </RaceSummaryRow>
        );
    } else if (isSpecials === true) {
        return (
            <RaceSummaryRow
                className={className}
                isNonRunner={isNonRunner}
                tags={tags}
                isAlternativeTag={config.isAlternativeRacingBoost}
                data-test={dataTest}
            >
                <RunnerCell>{runner}</RunnerCell>
                {isRaceInProgress === true ? <WinInProgressNew isPriceHistory={isPriceHistory} /> : winner}
                {tags}
            </RaceSummaryRow>
        );
    } else {
        return (
            <li>
                <ul>
                    <RaceSummaryRow
                        className={className}
                        isNonRunner={isNonRunner}
                        tags={tags}
                        isAlternativeTag={config.isAlternativeRacingBoost}
                        data-test={dataTest}
                    >
                        <SilkCell>
                            <LazyImage
                                src={silkUrl}
                                placeholder={config.shouldDisplaySilkWithoutQuestionMark ? EMPTY_SILK : EMPTY_SILK_old}
                                alt={selectionModel.metaDataSilk}
                            />
                        </SilkCell>

                        <RunnerCellDetails>
                            {runner}

                            <RunnerDetails
                                runnerDetailsState={runnerDetailsState}
                                selectionModel={selectionModel}
                            />
                        </RunnerCellDetails>

                        {isOnlyPrice === true ? (
                            isRaceInProgress === true ? (
                                <WinInProgressNew isPriceHistory={isPriceHistory} />
                            ) : (
                                winner
                            )
                        ) : (
                            <>
                                <PriceHistoryCell
                                    className='race-summary__selection__price-history'
                                    isRaceInProgress={isRaceInProgress}
                                >
                                    <PriceHistoryNew selectionId={selectionId} />
                                </PriceHistoryCell>
                                {isRaceInProgress === true ? (
                                    <WinInProgressNew isPriceHistory={isPriceHistory} />
                                ) : (
                                    winner
                                )}
                                {isRaceInProgress === true ? <SpInProgressNew isPriceHistory={isPriceHistory} /> : sp}
                                {tags}
                            </>
                        )}
                    </RaceSummaryRow>

                    {config.isRasGoldActive
                        ? runnerDetailsState.expandedRaceId === selectionId.key && (
                              <RaceSummaryRowDetails selectionModel={selectionModel} />
                          )
                        : null}
                </ul>
            </li>
        );
    }
});
