import React from 'react';
import { HorseRacingSortOrderType } from 'src/domains/sportsbook/webview/components/raceCard/RaceCompetition.state';
import { Dropdown } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/dropdown/Dropdown';
import { observer } from 'src/utils/mobx-react';

interface HorseRacingDropdownType {
    shouldShowHorseRacingDropdown: boolean;
    sortOrder: HorseRacingSortOrderType;
    setSortOrder: (sort: HorseRacingSortOrderType) => void;
}

export const HorseRacingDropdown = observer('HorseRacingDropdown', (props: HorseRacingDropdownType) => {
    const { setSortOrder, sortOrder, shouldShowHorseRacingDropdown } = props;
    const dropdownOptions = [
        {
            value: 'by-price',
            label: 'Sort by Price',
            onClick: (): void => setSortOrder('by-price'),
        },
        {
            value: 'by-place',
            label: 'Sort by Card',
            onClick: (): void => setSortOrder('by-place'),
        },
    ];

    if (shouldShowHorseRacingDropdown === true) {
        return (
            <Dropdown
                selected={sortOrder}
                options={dropdownOptions}
            />
        );
    } else {
        return null;
    }
});
