import React from 'react';
import { LogoGambleAwareV2 } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoGambleAwareV2';
import { LogoNationalGamblingHelpline } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoNationalGamblingHelpline';
import { LogoContainer } from 'src/domains/layouts/webview/components/logoContainer/LogoContainer';
import { LogoGroup } from 'src/domains/layouts/webview/components/logoContainer/LogoContainer.style';
import { observer } from 'src/utils/mobx-react';

interface PropsType {
    type?: 'primary' | 'secondary';
}

export const ResponsibleGamblingLinks = observer(
    'ResponsibleGamblingLinks',
    ({ type = 'primary' }: PropsType): JSX.Element => {
        return (
            <LogoGroup>
                <LogoContainer
                    to='https://www.gambleaware.org/'
                    type={type}
                >
                    <LogoGambleAwareV2 />
                </LogoContainer>
                <LogoContainer
                    to='https://www.gamcare.org.uk/get-support/talk-to-us-now/'
                    type={type}
                >
                    <LogoNationalGamblingHelpline />
                </LogoContainer>
            </LogoGroup>
        );
    }
);
