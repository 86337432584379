import styled from '@emotion/styled'; 
import { NFLIcon } from 'src/domains/layouts/shared/assets/icons/NFLIcon';
import { PreMatchWrapper } from 'src/domains/sportsbook/webview/components/matchLead/PreMatch.style';
import { withConfig } from 'src/withConfig';

export const PreMatchWrapperNfl = withConfig(theme => styled(PreMatchWrapper, { label: 'PreMatchWrapperNfl' })`
    padding: 4px 16px 16px;
    @media ${theme.star.mediaQuery.desktop} {
        padding: 8px 32px 32px;
    }
`);

export const PreMatchParticipantNameNfl = withConfig(theme => styled('div', { label: 'PreMatchParticipantNameNfl' })`
    align-items: center;
    color: ${theme.star.preMatch.txtColorTertiary};
    display: flex;
    flex: 0 0 calc(50% - 14px);
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.medium};
    justify-content: end;
    line-height: 1.1667;
    max-width: 180px;
    text-align: right;
    &:last-of-type {
        justify-content: start;
        text-align: left;
    }
    @media ${theme.star.mediaQuery.desktop} {
        flex-basis: calc(50% - 28px);
        font-size: ${theme.star.fontSize.xMedium};
        line-height: 1.2;
        max-width: 100%;
    }
`);

export const NflTeamLogotype = withConfig(theme => styled('img', { label: 'NflTeamLogotype' })`
    margin: 0 4px;
    width: 24px;
    @media ${theme.star.mediaQuery.desktop} {
        margin: 0 8px;
        width: 48px;
    }
`);

export const At = withConfig(theme => styled('div', { label: 'At' })`
    align-items: center;
    color: ${theme.star.preMatch.txtColorQuaternary};
    display: flex;
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
    justify-content: center;
    line-height: 1.4286;
    padding: 1px 7px 3px;
    flex: 0 0 28px;
    width: 28px;
    @media ${theme.star.mediaQuery.desktop} {
        flex: 0 0 56px;
        font-size: ${theme.star.fontSize.medium};
        line-height: 1.3334;
        margin: 0 8px;
        padding: 6px 15px 10px;
        width: 56px;
    }
`);

export const NFLIconWrapper = withConfig(theme => styled(NFLIcon, { label: 'NFLIconWrapper' })`
    display: block;
    margin: 2px auto 0 auto;
    width: 16px;
    @media ${theme.star.mediaQuery.desktop} {
        margin-top: 4px;
        width: 32px;
    }
`);
