import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    PolicyContent,
    HelpBoxTitle,
    HelpBoxSubHeader,
    ArrowBack,
    LoaderWrapper,
} from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/McBookieResetPasswordFormTerms.style';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';

interface PropsType {
    id: string;
    leftAlign?: boolean;
}

export const Policy = observer('Policy', (props: PropsType) => {
    const { id } = props;
    const { appPlayersState } = useAppStateContext();

    const staticPage = appPlayersState.usersState.getPage(id);
    const isLoading = staticPage.type === 'loading';
    const staticPageData = staticPage.type === 'ready' ? staticPage.value : null;
    const isLeftAligned = props.leftAlign ?? false;

    if (isLoading) {
        return (
            <LoaderWrapper>
                <Loader />
            </LoaderWrapper>
        );
    }

    if (staticPageData !== null && staticPageData !== undefined) {
        const { content } = staticPageData;
        return (
            <PolicyContent
                leftAlign={isLeftAligned}
                dangerouslySetInnerHTML={{ __html: content }}
            />
        );
    }

    return (
        <React.Fragment>
            <HelpBoxSubHeader>
                <HelpBoxTitle>404 not found</HelpBoxTitle>
                <ArrowBack position='left' />
            </HelpBoxSubHeader>

            <PolicyContent>404 NOT FOUND</PolicyContent>
        </React.Fragment>
    );
});
