import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { DepositSuccess } from './depositSuccess/DepositSuccess';
import { DepositFailure } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { observable, makeObservable, computed } from 'mobx';
import {
    DarkLoadingSpinner,
    LoadingSpinner,
} from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner';
import { timeout } from 'src_common/common/mobx-utils/timeout';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Amount } from 'src_common/common/amount/Amount';
import { Common, useCommon } from 'src/domains/common/Common';
import { TrpcClient } from 'src/appState/TrpcClient';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { RemainingLimitState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/remainingLimit/RemainingLimit.state';
import { AccountState } from 'src/domains/players/shared/Types';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

interface DepositFinalViewType {
    accountHelperMail: string;
    isSignup: boolean;
    transactionId?: number;
    hideDepositSuccess: () => void;
    paymentProvider: 'paysafe' | 'realex';
}

export interface TransactionCallbackResponseType {
    amount?: number;
    authCode?: string;
    createdAt: string;
    currency: string;
    orderId?: string;
    paymentMethod?: string;
    paymentProvider?: string; //for yaspa
    status: string;
}

export type TransactionCallbackType = {
    data: TransactionCallbackResponseType;
    transactionId: string | number | undefined;
};

export type FinalStepType =
    | {
          type: 'loading-view';
      }
    | {
          type: 'failure-view';
          failType: 'serverIssue' | 'contactGeneralIssue' | 'cancelledTransaction';
      }
    | {
          type: 'failure-view';
          failType: 'failWithReceipt';
          data: TransactionCallbackType;
      }
    | {
          type: 'success-view';
      };

class FinalViewState {
    public static get = AutoWeakMap.create(
        (common: Common, transactionId: number | undefined, paymentProvider: 'paysafe' | 'realex') =>
            new FinalViewState(common, transactionId, paymentProvider)
    );

    @observable public step: FinalStepType = { type: 'loading-view' };
    private readonly usersState: UsersState;
    private readonly account: AccountState;
    private readonly trpcClient: TrpcClient;
    private readonly googleTagManager: GoogleTagManagerState;
    private readonly remainingLimitState: RemainingLimitState;

    public constructor(
        private readonly common: Common,
        private readonly transactionId: number | undefined,
        paymentProvider: 'paysafe' | 'realex'
    ) {
        makeObservable(this);

        this.usersState = UsersState.get(common);
        this.account = AccountState.get(common);
        this.trpcClient = common.trpcClient;
        this.googleTagManager = GoogleTagManagerState.get(common);
        this.remainingLimitState = new RemainingLimitState(common, this.usersState);

        (async (): Promise<void> => {
            for (let i = 0; i < 15; i++) {
                if (this.transactionId === undefined) {
                    this.step = {
                        type: 'failure-view',
                        failType: 'serverIssue',
                    };
                    return;
                }

                const response = await (paymentProvider === 'paysafe' ? this.paysafeCallback : this.realexCallback)(
                    this.transactionId
                );

                if (response === null) {
                    await timeout(4000);
                    continue;
                }

                this.step = response;
                return;
            }

            if (this.step.type === 'loading-view') {
                this.step = { type: 'failure-view', failType: 'serverIssue' };
                return;
            }
        })().catch(() => {
            this.step = { type: 'failure-view', failType: 'serverIssue' };
        });
    }

    @computed public get showBalance(): string | undefined {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return this.usersState.money(new Amount(playableBalance));
        }
    }

    private paysafeCallback = async (transactionId: number): Promise<FinalStepType | null> => {
        const request = await this.trpcClient.client.paysafeRouter.checkoutCallbackPaysafe.query({
            transactionId: transactionId,
        });

        if (request.responseStatus === 'error') {
            return {
                type: 'failure-view',
                failType: 'serverIssue',
            };
        }
        const { status, createdAt, currency, merchantRefNum } = request.response;

        if (status === 'failed' || status === 'rejected') {
            return {
                type: 'failure-view',
                failType: 'failWithReceipt',
                data: {
                    data: {
                        orderId: merchantRefNum ?? undefined,
                        createdAt: createdAt,
                        currency: currency,
                        status,
                    },
                    transactionId: transactionId,
                },
            };
        }

        if (status === 'paid') {
            this.googleTagManager.addDepositTag(this.account.account);
            await this.remainingLimitState.depositLimitsDataResource.refresh();
            return {
                type: 'success-view',
            };
        }

        return null;
    };

    private realexCallback = async (transactionId: number): Promise<FinalStepType | null> => {
        try {
            const request = await this.common.trpcClient.client.realex.transactionCallbackRealex.query({
                transactionId,
            });
            if (request.responseStatus === 'error') {
                return {
                    type: 'failure-view',
                    failType: 'serverIssue',
                };
            }

            if (request.response.status === 'failed' || request.response.status === 'rejected') {
                return {
                    type: 'failure-view',
                    failType: 'failWithReceipt',
                    data: {
                        data: request.response,
                        transactionId,
                    },
                };
            }

            if (request.response.status === 'paid') {
                await this.remainingLimitState.depositLimitsDataResource.refresh();
                return {
                    type: 'success-view',
                };
            }

            return null;
        } catch (e) {
            return {
                type: 'failure-view',
                failType: 'serverIssue',
            };
        }
    };
}

export const DepositFinalView = observer(
    'DepositFinalView',
    ({ accountHelperMail, isSignup, transactionId, hideDepositSuccess, paymentProvider }: DepositFinalViewType) => {
        const common = useCommon();
        const state = FinalViewState.get(common, transactionId, paymentProvider);

        const renderContent = (): JSX.Element => {
            switch (state.step.type) {
                case 'loading-view':
                    return isSignup === true ? <LoadingSpinner /> : <DarkLoadingSpinner />;
                case 'failure-view':
                    return (
                        <DepositFailure
                            isSignup={isSignup}
                            issueType={state.step.failType}
                            accountHelperMail={accountHelperMail}
                            transactionCallback={
                                'failWithReceipt' === state.step.failType ? state.step.data : undefined
                            }
                            hideDepositSuccess={hideDepositSuccess}
                        />
                    );
                case 'success-view':
                    return (
                        <DepositSuccess
                            isSignup={isSignup}
                            balance={state.showBalance}
                            hideDepositSuccess={hideDepositSuccess}
                        />
                    );
            }
        };

        return renderContent();
    }
);
