import { action, computed, makeObservable, observable } from 'mobx';
import { Common } from 'src/domains/common/Common';
import { IdmissionSDKType } from './IdMissionPopup/IdMissionPopup';
import { WebsocketV1 } from 'src/domains/layouts/state/websocketV1/WebsocketV1';
import { ApiResponseWrapper } from 'src_common/common/mobx-utils/ApiResponseWrapper';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { IdentityAPI } from './IdentityAPI';
import { ApiVerifyProgressResponse200Type } from 'src/api_openapi/v2/idMission/apiVerifyProgress';

const createIdentity = (
    identityAPI: IdentityAPI,
    websocketV1: WebsocketV1
): AutoMap<[number], ApiResponseWrapper<ApiVerifyProgressResponse200Type | null>> =>
    new AutoMap(
        ([userId]) =>
            new ApiResponseWrapper(
                'getVerifyProgress',
                null,
                async (): Promise<ApiVerifyProgressResponse200Type | null> => {
                    const response = await identityAPI.getVerifyProgress(userId);

                    if (response.status === 200) {
                        return response.body;
                    }

                    return null;
                },
                (refresh) => {
                    refresh();

                    websocketV1.subscribe(`${userId}:Identity`, refresh);

                    return (): void => {
                        websocketV1.unsubscribe(`${userId}:Identity`, refresh);
                    };
                }
            )
    );

export class IdentityState {
    @observable private identityVerifyProgress: AutoMap<
        [number],
        ApiResponseWrapper<ApiVerifyProgressResponse200Type | null>
    >;

    @observable private shouldIdMissionBeOpened: boolean = true;
    private identityAPI: IdentityAPI;

    public constructor(private readonly common: Common) {
        makeObservable(this);

        this.identityAPI = IdentityAPI.get(this.common);
        this.identityVerifyProgress = createIdentity(this.identityAPI, WebsocketV1.get(this.common));
    }

    @computed public get processIdMission(): { type: IdmissionSDKType; isOpen: boolean; id: number | null } {
        const defResponse = { type: null, isOpen: false, id: null };

        const userId = this.common.session.userId;

        if (userId === null) {
            return defResponse;
        }

        const verifyProcess = this.identityVerifyProgress.get([userId]).data;

        if (verifyProcess === null) {
            return defResponse;
        }

        if (verifyProcess.progress === 'Finished') {
            return defResponse;
        }

        return {
            type: verifyProcess.verification_variant,
            isOpen: this.shouldIdMissionBeOpened,
            id: verifyProcess.id,
        };
    }

    public toggleIdMissionPopup(): void {
        this.shouldIdMissionBeOpened = !this.shouldIdMissionBeOpened;
    }

    @action public refreshIdMissionData = (): void => {
        const userId = this.common.session.userId;

        if (userId === null) {
            return;
        }

        this.identityVerifyProgress.get([userId]).refresh();
    };
}
