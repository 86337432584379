import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    ButtonSpinnerWrapper,
    ButtonUniversalWrapper,
} from 'src/domains/layouts/webview/components/common/button/Button.style';

export type ButtonVersionType =
    | 'primary'
    | 'secondary'
    | 'secondary-light'
    | 'secondary-dark'
    | 'tertiary-dark'
    | 'tertiary-light';

interface PropsType {
    children: React.ReactNode;
    className?: string;
    dataTest?: string;
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    size: 'large' | 'medium' | 'small' | 'xs';
    type?: 'button' | 'reset' | 'submit';
    /**
     * @deprecated
     * @description layout: star
     * - primary | secondary-dark | secondary-light
     * -----------------------------
     * @deprecated
     * @description layout: luckyKing
     * - primary | secondary | tertiary-dark | tertiary-light
     *
     *
     * -----------------------------
     * New version of layout should not be connected to operator. Each operator should have its own layoutOptions Config.
     */
    version?: ButtonVersionType;
    id?: string;
}

export const Button = observer(
    'Button',
    ({
        children,
        className,
        dataTest,
        disabled = false,
        isLoading = false,
        onClick,
        size = 'xs',
        type = 'button',
        version,
    }: PropsType) => {
        const {
            config: {
                layoutOptions: { buttonVariant },
            },
        } = useAppStateContext();

        return (
            <ButtonUniversalWrapper
                className={className}
                data-test={dataTest}
                data-test-button-disabled={disabled}
                disabled={disabled}
                layout={buttonVariant}
                isLoading={isLoading}
                onClick={onClick}
                size={size}
                type={type}
                version={version}
            >
                {isLoading ? <ButtonSpinnerWrapper size={size} /> : children}
            </ButtonUniversalWrapper>
        );
    }
);
