import * as React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { observer } from 'src/utils/mobx-react';
import bgPatternHexagon from 'src/domains/layouts/shared/assets/backgrounds/sports/bgPatternHexagon.webp';
import { DateTime } from 'src_common/utils/time/time';
import { PreMatchNFL } from 'src/domains/sportsbook/webview/components/matchLead/PreMatchNFL';
import {
    PreMatchDate,
    PreMatchParticipantName,
    PreMatchParticipants,
    PreMatchTime,
    PreMatchWrapper,
    Versus,
} from 'src/domains/sportsbook/webview/components/matchLead/PreMatch.style';
import { ParticipantModelType } from 'src_common/common/websocket2/modelsApi/Event';

export type TeamNameType = Pick<ParticipantModelType, 'role' | 'name'>;

export const getTeamName = (event: EventModel, type: string): TeamNameType | undefined => {
    for (const team of Object.values(event.participants)) {
        if (team.role === type) {
            return team;
        }
    }
};

interface MatchLeadDefaultPropsType {
    event: EventModel;
}

export const PreMatch = observer('PreMatch', ({ event }: MatchLeadDefaultPropsType) => {
    const appState = useAppStateContext();
    const {
        configComponents: { config },
    } = appState.appLayoutsState;
    const { scoreboard } = config;
    const { sport } = event;

    const startTime = DateTime.from(event.timeSettingsStartTime);

    const home = getTeamName(event, 'home');
    const away = getTeamName(event, 'away');

    const competitionName = event.competition2.getModel()?.name;

    const eventDateStart = startTime?.format('ddd Do MMM');
    const eventTimeStart = startTime?.format('HH:mm');

    if (sport === 'basketball') {
        if (scoreboard.liveTracker !== null) {
            for (const item of scoreboard.liveTracker) {
                if (item === sport) {
                    return null;
                }
            }
        }
    }

    if (sport === 'icehockey') {
        // temporary hide until new implementation - LT-1795
        return null;
    }

    // temporary fix for jumping prematch golf scoreboard
    // problem with event model timeSettingsTimeline - it frequently change from premach to started under some conditions
    // probably have to be fixed on BE (LT-1413)
    // WARNING: enable premach on golf could cause blinking effect

    if (sport === 'golf') {
        return null;
    }

    /* NBA and NHL have their own scoreboards */
    if (competitionName === 'US NBA' || competitionName === 'US NHL') {
        return null;
    }

    if (competitionName === 'NFL Matches' || competitionName === 'US NFL') {
        return <PreMatchNFL event={event} />;
    }

    return (
        <PreMatchWrapper bgImage={bgPatternHexagon}>
            <PreMatchDate>
                {eventDateStart}
                <PreMatchTime>{eventTimeStart}</PreMatchTime>
            </PreMatchDate>

            <PreMatchParticipants>
                <PreMatchParticipantName>{home === undefined ? '-' : home.name}</PreMatchParticipantName>

                <Versus />

                <PreMatchParticipantName>{away === undefined ? '-' : away.name}</PreMatchParticipantName>
            </PreMatchParticipants>
        </PreMatchWrapper>
    );
});
