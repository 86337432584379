import { computed, makeObservable } from 'mobx';
import { MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';

export function getSelectionByName(selectionName: string, marketModel: MarketModel): SelectionModel | null {
    for (const selection of marketModel.selections) {
        if (selection.name === selectionName) {
            return selection;
        }
    }

    return null;
}

export type LengthenTheOddsSummaryPropsType = {
    race: EventModel;
};

type MarketsTermsType = {
    terms: string;
    marketId: MarketId;
};

export class LengthenTheOddsSummaryState {
    public constructor(private readonly lengthenTheOddsProps: LengthenTheOddsSummaryPropsType) {
        makeObservable(this);
    }

    @computed public get sortedMarkets(): Array<MarketModel> {
        return this.lengthenTheOddsProps.race.marketLengthenTheOdds.sort((firstMarket, secondMarket) => {
            // Extract numbers from terms like "To Win By 1.00 Lengths or More"
            const getNumber = (term: string): number => {
                const regex = /(\d+(?:\.\d+)?)/;
                const match = regex.exec(term);

                if (match === null || match[1] === undefined) {
                    return 0;
                }

                return parseFloat(match[1]);
            };

            const numA = getNumber(firstMarket.name);
            const numB = getNumber(secondMarket.name);

            return numA - numB;
        });
    }

    @computed public get marketsTerms(): Array<MarketsTermsType> {
        const marketsSimpleData: Array<MarketsTermsType> = [];

        for (const market of this.sortedMarkets) {
            const terms = market.name;

            const data = {
                terms,
                marketId: market.id2,
            };

            marketsSimpleData.push(data);
        }

        return marketsSimpleData;
    }

    @computed public get marketSelections(): Array<SelectionModel> | null {
        const market = this.sortedMarkets[0];

        if (market !== undefined) {
            return market.selections;
        }

        return null;
    }

    @computed public get selectionsByPrice(): Array<SelectionModel> | undefined {
        const selections = this.marketSelections?.sort((selectionA, selectionB) => {
            const priceA = selectionA.price?.d;
            const priceB = selectionB.price?.d;
            if (priceA === undefined || priceB === undefined) {
                return 0;
            }
            return priceA - priceB;
        });

        if (selections === undefined) {
            return selections;
        }
        const sortedViaNonRunner = [];
        const sortedWithRunner = [];
        for (const selection of selections) {
            const nonRunner = this.isNonRunnerInRow(selection.name);
            if (nonRunner === true) {
                sortedViaNonRunner.push(selection);
            } else {
                sortedWithRunner.push(selection);
            }
        }

        return [...sortedWithRunner, ...sortedViaNonRunner];
    }

    public isNonRunnerInRow = (selectionName: string): boolean => {
        const markets = this.sortedMarkets;
        let isNonRunnerInRow = false;
        for (const market of markets) {
            const selectionInRow = getSelectionByName(selectionName, market);

            if (selectionInRow?.resultType === 'void') {
                isNonRunnerInRow = true;
            }
        }

        return isNonRunnerInRow;
    };
}
