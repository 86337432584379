import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const RaceOverviewWrapper = withConfig(theme => styled('div', { label: 'RaceOverviewWrapper' })`
    color: ${theme.star.raceCardDetails.txtColor};
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 16px 0;
`);

export const RaceOverviewHeader = withConfig(theme => styled('h4', { label: 'RaceOverviewHeader' })`
    font-weight: ${theme.star.fontWeight.bold};
    margin: 0 0 4px;
`);

export const RaceOverviewHeaderInline = styled(RaceOverviewHeader, { label: 'RaceOverviewHeaderInline' })`
    margin: 0 4px 0 0;
`;

export const TipsItem = styled('span', { label: 'TipsItem' })`
    position: relative;
    &::after {
        content: '-';
        margin: 0 4px;
    }
    &:last-of-type,
    &:only-of-type {
        &::after {
            content: none;
        }
    }
`;

interface RaceOverviewContentWrapperType {
    isExpanded: boolean;
}

export const RaceOverviewContentWrapper = styled('section', { label: 'RaceOverviewContentWrapper' }) <RaceOverviewContentWrapperType>`
    align-items: start;
    display: flex;
    flex-flow: ${({ isExpanded }): string => isExpanded ? 'column nowrap' : 'row nowrap'};
    gap: ${({ isExpanded }): string => isExpanded ? '8px' : '4px'};
    overflow: hidden;
    white-space: ${({ isExpanded }): string => isExpanded ? 'normal' : 'nowrap'};
`;

interface ArticleExcerptType {
    isExpanded: boolean;
}

export const ArticleExcerpt = styled('div', { label: 'ArticleExcerpt' })<ArticleExcerptType>`
    flex: 0 1 auto;
    hyphens: auto;
    overflow: hidden;
    text-align: justify;
    text-overflow: ${({ isExpanded }): string => isExpanded ? 'initial' : 'ellipsis'};
    white-space: ${({ isExpanded }): string => isExpanded ? 'normal' : 'nowrap'};
`;

interface ArticleOverviewType {
    isExpanded: boolean;
}

export const ArticleOverview = styled('div', { label: 'ArticleOverview' }) <ArticleOverviewType>`
    display: flex;
    position: ${({ isExpanded }): string => isExpanded ? 'static' : 'absolute'};
    visibility: ${({ isExpanded }): string => isExpanded ? 'visible' : 'hidden'};
    opacity: ${({ isExpanded }): string => isExpanded ? '1' : '0'};
    transition: opacity .2s ease;
`;

export const ReadMore = withConfig(theme => styled('button', { label: 'ReadMore' })`
    background: none;
    border: none;
    color: ${theme.star.raceCardDetails.txtColorSecondary};
    cursor: pointer;
    flex-shrink: 0;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    padding: 0;
`);
