import { action, computed, makeObservable, autorun } from 'mobx';
import { FindRouteType, Router } from 'src/domains/layouts/state/router/Router';
import { ExternalApiEmmiters } from 'src/domains/layouts/state/externalApi/ExternalApiEmmiters';
import { isMainViewRouteEq, RouteViewType, SportRacesTypeParamType } from './newRouter/mainRouteTypes';
import { tryMatch } from './newRouter/tryMatchRoute';
import {
    CreditsDrawerType,
    RightHandSideViewType,
    StaticRhsRouteType,
    tryMachAccountView,
} from './newRouter/rhsRouteType';
import { slug } from 'src/utils/deburr';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { IsLinkExternalBaseResult, isLinkExternalBase } from './isLinkExternalBase';
import { assertNever } from 'src_common/common/assertNever';
import { Common } from 'src/domains/common/Common';
import { EnvironmentState } from 'src/domains/layouts/state/environmentState/EnvironmentState';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { UniverseType } from 'src_common/common/universe';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

interface FreeParamsType {
    promo: string | null;
}

interface StaticParamType {
    param: string | null;
}

interface ResetPasswordParamsType {
    email: string | null;
    token: string | null;
    receivedVia: string | null;
    isVerify: boolean | null;
}

export type ToType = RouteViewType | RightHandSideViewType | string;
export class StarRouter {
    /**
     * @deprecated
     */
    private readonly router: Router;
    private readonly isMobileApp: boolean;
    private readonly outsideAllowedRoutes: Array<string>;
    private externalLinkOpen: null | ((url: string) => void) = null;

    public constructor(
        url: string,
        universe: UniverseType,
        isMobileApp: boolean,
        outsideAllowedRoutes: Array<string>,
        scrollToTop: () => void
    ) {
        makeObservable(this);

        this.isMobileApp = isMobileApp;
        this.router = new Router(url, universe, scrollToTop);
        this.outsideAllowedRoutes = outsideAllowedRoutes;
    }

    public static get = AutoWeakMap.create((common: Common) => StarRouter.fromCommon(common));

    public static fromCommon(common: Common): StarRouter {
        const url = common.startingUrl;
        const universe = common.envVariables.universe;
        const isMobileApp = EnvironmentState.get(common).isMobileApp();
        const outsideAllowedRoutes: Array<string> = ConfigComponents.get(common).config.outsideAllowedRoutes;
        const scrollToTop: () => void = () => {
            if (common.isBrowser === true) {
                window.scrollTo(0, 0);
            }
        };

        return new StarRouter(url, universe, isMobileApp, outsideAllowedRoutes, scrollToTop);
    }

    public get externalApiEmmiters(): ExternalApiEmmiters {
        return this.router.externalApiEmmiters;
    }

    public setExternalLinkOpen = (handler: null | ((url: string) => void)): void => {
        this.externalLinkOpen = handler;
    };

    //---------------------------------------------------------------------------------------------

    @computed public get currentView(): RouteViewType | null {
        const routeInner = this.router.routeInner;
        return tryMatch(routeInner);
    }

    @computed public get accountView(): RightHandSideViewType | StaticRhsRouteType | null {
        return tryMachAccountView(this.router.route.params);
    }

    @computed public get freeParams(): FreeParamsType {
        const promo = this.router.route.params['promo'];

        return {
            promo: promo ?? null,
        };
    }

    @computed public get staticParam(): StaticParamType {
        const param = this.router.route.params['static'];

        return {
            param: param ?? null,
        };
    }

    @computed public get resetPasswordParams(): ResetPasswordParamsType {
        const email = this.router.route.params['email'];
        const token = this.router.route.params['token'];
        const receivedVia = this.router.route.params['receivedVia'];
        const isVerify = this.router.route.params['isVerify'] === 'true';

        return {
            email: email ?? null,
            token: token ?? null,
            receivedVia: receivedVia ?? null,
            isVerify: isVerify,
        };
    }

    @computed public get isAccountParam(): boolean {
        return this.accountView?.account !== undefined;
    }

    @computed public get accountParam(): string | undefined | null {
        return this.accountView?.account;
    }

    @computed public get creditsTypeParam(): CreditsDrawerType | undefined | null {
        if (this.accountView?.account === 'credits') {
            return this.accountView.type;
        }
    }

    public onChangeCurrentView(
        callback: (prev_view: RouteViewType | null, next_view: RouteViewType | null) => void
    ): void {
        this.router.onChange((prev_url: FindRouteType, next_url: FindRouteType) => {
            const prev_view = tryMatch(prev_url);
            const next_view = tryMatch(next_url);

            if (JSON.stringify(prev_view) === JSON.stringify(next_view)) {
                //equal
            } else {
                callback(prev_view, next_view);
            }
        });

        const next_view = tryMatch(this.router.routeInner);
        callback(null, next_view);
    }

    public onChangeAccountView(
        callback: (prev_view: RightHandSideViewType | null, next_view: RightHandSideViewType | null) => void
    ): void {
        this.router.onChange((prev_url: FindRouteType, next_url: FindRouteType) => {
            const prev_view_account = tryMachAccountView(prev_url.params);
            const next_view_account = tryMachAccountView(next_url.params);

            if (JSON.stringify(prev_view_account) === JSON.stringify(next_view_account)) {
                //equal
            } else {
                callback(prev_view_account, next_view_account);
            }
        });

        const next_view_account = tryMachAccountView(this.router.routeInner.params);
        callback(null, next_view_account);
    }

    //---------------------------------------------------------------------------------------------

    public get isLogoutDontMoveToLoginPage(): boolean {
        return this.router.isLogoutDontMoveToLoginPage;
    }

    public emitEventLogoutClick(): void {
        this.router.emitEventLogoutClick();
    }

    public get isLoginDisabled(): boolean {
        return this.router.isLoginDisabled;
    }

    @action public redirectToLoginHomepage = (): void => {
        this.redirectTo({ name: 'homepage' });
        this.redirectTo({ account: 'login', promo: null });
    };

    public redirectToBetslip = (): void => {
        this.redirectTo({ account: 'betting' });
    };

    public redirectToHomepage = (): void => {
        this.redirectTo({
            name: 'homepage',
        });
    };

    public redirectToRacingSport = (id: 'horseracing' | 'greyhoundracing'): void => {
        this.redirectTo({
            name: 'sport',
            nameType: 'races',
            id: id,
            type: null,
            event: null,
        });
    };

    public redirectToEvent = (id: EventId): void => {
        autorun((dispose) => {
            const eventModel = id.getEventModel();

            if (eventModel === null) {
                return;
            }

            dispose.dispose();

            if (
                eventModel.sport === 'horseracing' ||
                eventModel.sport === 'greyhoundracing' ||
                eventModel.template === 'race'
            ) {
                this.redirectToRaceCard(id, eventModel.sport, eventModel.competition);
                return;
            }
            const slugFormatted = slug(eventModel.name);

            this.redirectTo({
                name: 'event',
                id: id.toOldId(),
                slug: slugFormatted,
            });
        });
    };

    public redirectToRaceCard = (selected: EventId | null, sport: string, collection: number | 'next-off'): void => {
        this.redirectTo({
            name: 'racecard',
            collection: collection,
            selected: selected?.toOldId() ?? null,
            sport: sport,
        });
    };

    public redirectToRaceMeetingsCard = (
        selected: number | null,
        sport: string,
        racecardBuildIds: Array<number>
    ): void => {
        this.redirectTo({
            name: 'racecard',
            collection: 'race-meetings',
            racecardBuildIds: racecardBuildIds,
            selected: selected,
            sport: sport,
        });
    };

    public redirectToCompetitionPage = (id: number, sport: string): void => {
        this.redirectTo({
            name: 'competition',
            id: id,
            slug: sport,
        });
    };

    public redirectToCasinoGameInfo = (): void => {
        this.redirectTo({ account: 'game-info' });
    };

    public redirectToCasinoPage = (): void => {
        this.redirectTo({
            name: 'casino',
        });
    };

    public redirectToCasinoTabPage = (tabId: string): void => {
        this.redirectTo({
            name: 'casino',
            tabId,
        });
    };

    public redirectToLiveCasinoTabPage = (tabId: string): void => {
        this.redirectTo({
            name: 'live-casino',
            tabId,
        });
    };

    public redirectToLiveCasinoPage = (): void => {
        this.redirectTo({
            name: 'live-casino',
        });
    };

    public redirectToVirtualsPage = (): void => {
        this.redirectTo({
            name: 'virtuals',
        });
    };

    public redirectToStarEvents = (): void => {
        this.redirectTo({
            name: 'starevents',
        });
    };

    public redirectToVerifyAccount = (): void => {
        this.redirectTo({ account: 'verify-account' });
    };
    public redirectToLogin = (): void => {
        this.redirectTo({ account: 'login', promo: null });
    };

    public redirectToSignUp = (): void => {
        this.redirectTo({ account: 'signup', promo: null });
    };

    public redirectToTopUp = (amount: number | null): void => {
        this.redirectTo({ account: 'top-up', amount, view: null });
    };

    public redirectToWithdraw = (): void => {
        this.redirectTo({ account: 'withdraw', view: null });
    };

    public redirectToResetPassword = (tokenValue: string, email: string, isVerify: boolean | null): void => {
        this.redirectTo({
            account: 'reset-password',
            token: tokenValue,
            email: email,
            receivedVia: 'sms',
            isVerify: isVerify,
        });
    };

    public redirectToResetPasswordTerms = (tokenValue: string, email: string, isVerify: boolean | null): void => {
        this.redirectTo({
            account: 'reset-password-terms',
            token: tokenValue,
            email: email,
            receivedVia: 'sms',
            isVerify,
        });
    };

    public redirectToResetPasswordCookies = (tokenValue: string, email: string, isVerify: boolean | null): void => {
        this.redirectTo({
            account: 'reset-password-cookies',
            token: tokenValue,
            email: email,
            receivedVia: 'sms',
            isVerify,
        });
    };

    public redirectToResetPasswordPrivacy = (tokenValue: string, email: string, isVerify: boolean | null): void => {
        this.redirectTo({
            account: 'reset-password-privacy',
            token: tokenValue,
            email: email,
            receivedVia: 'sms',
            isVerify,
        });
    };

    public redirectToFreeBetCreditsInfo = (): void => {
        this.redirectTo({ account: 'static', static: 'free-bet-credits' });
    };

    public redirectToAccountSummary = (): void => {
        this.redirectTo({ account: 'summary' });
    };

    public redirectToWallet = (): void => {
        this.redirectTo({ account: 'wallet' });
    };

    public redirectToAddCredit = (): void => {
        this.redirectTo({ account: 'add-credit' });
    };

    public redirectToTraderChat = (): void => {
        this.redirectTo({ account: 'trader-chat' });
    };

    public showPromoTermsAndConditions = (): void => {
        this.redirectTo({ account: 'static', static: 'terms-and-conditions-promotion' });
    };

    public redirectToTermsAndConditions = (): void => {
        this.redirectTo({ account: 'static', static: 'terms-and-conditions' });
    };

    public redirectToTermsAndConditionsPromoSpecial = (promo: string | null): void => {
        this.redirectTo({ account: 'terms-and-conditions-promos-special', promo: promo });
    };

    public redirectToStaticPage = (slug: string): void => {
        this.redirectTo({ account: 'static', static: slug });
    };

    public redirectToStaticResourceHeroWidget = (slug: string): void => {
        this.redirectTo({ account: 'static-resource-hero-widget', static: slug });
    };

    public redirectToCasinoBannerTerms = (): void => {
        this.redirectTo({ account: 'terms-and-conditions-casino-banner', static: null, collection: null });
    };

    public redirectToCarouselPromoTerms = (id: number): void => {
        this.redirectTo({ account: 'static-resource-carousel-promo-terms', promoId: id });
    };

    public redirectToHeadlineCarouselTerms = (id: number): void => {
        this.redirectTo({ account: 'static-resource-headline-promo-terms', promoId: id.toString() });
    };

    public redirectToBetHistory = (): void => {
        this.redirectTo({ account: 'bet-history' });
    };

    public redirectToTransactionHistory = (): void => {
        this.redirectTo({ account: 'transaction-history' });
    };

    public redirectToLimits = (): void => {
        this.redirectTo({ account: 'limits' });
    };

    public redirectToPreferences = (): void => {
        this.redirectTo({ account: 'preferences' });
    };

    public closeAccount = (): void => {
        this.redirectTo({ account: null });
    };

    /**
     * @deprecated
     */
    private parseUrl(url: string): RouteViewType | null {
        return tryMatch(this.router.parseUrl(url));
    }

    private shouldResetScroll(url: string): boolean {
        const oldView = this.currentView;
        const newView = this.parseUrl(url);

        if (oldView !== null && newView !== null && isMainViewRouteEq(oldView, newView) === false) {
            return true;
        }

        return false;
    }

    /**
     * @deprecated use NewLink component. This method can be use only in NewLink component
     * @param url
     * @returns
     */
    public handleUrlRedirection = (url: string): void => {
        const resultCheckExternal = this.isLinkExternal(url);

        switch (resultCheckExternal.type) {
            case 'parseError': {
                console.error(resultCheckExternal.message);
                return;
            }
            case 'external': {
                if (this.isMobileApp && this.externalLinkOpen !== null) {
                    this.externalLinkOpen(resultCheckExternal.url);
                } else {
                    window.open(resultCheckExternal.url, '_blank');
                }

                return;

                // if (this.isMobileApp && this.appExternalLinkOpenValue()) {
                //     this.openInAppModal(resultCheckExternal.url);
                // } else {
                //     window.open(resultCheckExternal.url, '_blank');
                // }
                // return;
            }
            case 'internal': {
                if (this.shouldResetScroll(url)) {
                    this.router.scrollToTop();
                }

                this.setUrl(resultCheckExternal.path);
                return;
            }
            case 'onserver': {
                //situation, impossible because we are on the server side
                return;
            }
            default: {
                return assertNever('', resultCheckExternal);
            }
        }
    };

    public isLinkExternal = (to: string): IsLinkExternalBaseResult => {
        // tslint:disable-next-line
        if (typeof window === 'undefined') {
            return isLinkExternalBase(null, this.outsideAllowedRoutes, to);
        }

        return isLinkExternalBase(window.location.host, this.outsideAllowedRoutes, to);
    };

    /**
     * @deprecated temporary todo delete
     */
    public setUrl(url: string): void {
        this.router.setUrl(url);
    }

    public buildUrlTo = (to: RouteViewType | RightHandSideViewType): string => {
        return this.router.buildUrlTo(to);
    };

    public redirectTo(to: RouteViewType | RightHandSideViewType): void {
        if ('account' in to) {
            this.router.redirect(undefined, to);
        } else {
            if (this.currentView !== null && isMainViewRouteEq(this.currentView, to) === false) {
                this.router.redirectTo(to, true);
                return;
            }

            this.router.redirectTo(to, false);
        }
    }

    @computed public get url(): string {
        return this.router.url;
    }

    public redirectToSportPage = (sport: string): void => {
        if (sport === 'horseracing' || sport === 'greyhoundracing') {
            this.redirectToRace(sport, null, null);
            return;
        }

        this.redirectTo({ name: 'sport', nameType: 'regular', id: sport });
    };

    public redirectToRace = (
        sport: 'horseracing' | 'greyhoundracing',
        type: SportRacesTypeParamType | null,
        event: { slug: string; id: number } | null
    ): void => {
        this.redirectTo({
            name: 'sport',
            nameType: 'races',
            id: sport,
            type,
            event,
        });
    };

    public redirectToRaceBuilder = (idsNew: EventId[], sport: string): void => {
        const ids = idsNew.map((id) => id.toOldId());

        this.redirectTo({
            name: 'racecard',
            collection: 'race-meetings',
            sport: sport,
            racecardBuildIds: ids,
            selected: null,
        });
    };

    public redirectToActionAddBetToBetslip = (): void => {
        this.router.redirect(null, { 'action-add-bet-to-betslip': null });
    };

    public clearBtagAndStag(): void {
        this.router.redirect(null, { btag: null, stag: null });
    }
}
