import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { buildValidator } from 'src_common/common/mobx-utils/buildValidator';

//http://10.110.0.32:8080/operator-events/${universe}/16935/markets/1522764
//http://10.110.0.32:8080/operator-markets/iyisans/1522764

const MetadataModelIO = t.record(t.string, t.union([t.string, t.null, t.undefined]));

// Data from RAS Gold
export const RacingFormDetailsModelIO = t.type({
    courseName: t.union([t.string, t.undefined]),
    courseType: t.union([t.string, t.undefined, t.null]),
    date: t.union([t.string, t.undefined]),
    distance: t.union([t.string, t.undefined]),
    finishPosition: t.union([t.number, t.undefined]),
    handicap: t.union([t.boolean, t.undefined]),
    runnersCount: t.union([t.number, t.undefined]),
    startingPrice: t.union([t.string, t.undefined, t.null]),
});

export type RacingFormDetailsModelType = t.TypeOf<typeof RacingFormDetailsModelIO>;

export const ParticipantModelIO = t.type({
    id: t.number,
    idv2: t.union([t.string, t.undefined, t.null]),
    metadata: t.union([MetadataModelIO, t.undefined]),
    name: t.union([t.string, t.undefined, t.null]),
    racingFormDetails: t.union([t.array(RacingFormDetailsModelIO), t.undefined, t.null]),
    role: t.union([t.string, t.undefined, t.null]),
});

export type ParticipantModelType = t.TypeOf<typeof ParticipantModelIO>;

const TimeSettingsIO = t.interface({
    startTime: t.string,
    tradedInPlay: t.boolean,
    timeZone: t.string,
    timeline: t.union([t.string, t.undefined, t.null]),
    offAtStartTime: t.union([t.boolean, t.undefined, t.null]),
});

export type TimeSettingsType = t.TypeOf<typeof TimeSettingsIO>;

const feedDataIO = t.union([
    t.record(
        t.string,
        t.union([t.string, t.null])),
    t.undefined
]);

const StreamIO = t.interface({
    id: t.number,
    // universe: t.string,
    // eventId: t.number,
    // eventName: t.string,
    // streamId: t.number,
    provider: t.string,
    geoRuleType: t.union([t.literal('DENY'), t.literal('ALLOW')]),
    geoRuleCountries: t.string,
    live: t.boolean,
});

export type StreamType = t.TypeOf<typeof StreamIO>;

const IncidentsIO = t.interface({
    period: t.union([t.undefined, t.null, t.string]),
    player: t.union([t.undefined, t.null, t.string]),
    time: t.union([t.undefined, t.null, t.interface({ minutes: t.union([t.number, t.null, t.undefined]), seconds: t.union([t.number, t.null, t.undefined]) })]),
    addedManually: t.union([t.undefined, t.null, t.boolean]),
    feedUpdatable: t.union([t.undefined, t.null, t.boolean]),
    deleted: t.union([t.undefined, t.null, t.boolean]),
    id: t.string,
    editedManually: t.union([t.undefined, t.null, t.boolean]),
    team: t.union([t.undefined, t.null, t.string]),
    bettable: t.union([t.undefined, t.null, t.interface({ type: t.union([t.string, t.null, t.undefined]) })])
});


const FeedSettingsIO = t.interface({
    eachWay: t.union([t.undefined, t.null, t.boolean]),
    updates: t.union([t.undefined, t.null, t.boolean]),
    prices: t.union([t.undefined, t.null, t.boolean]),
    results: t.union([t.undefined, t.null, t.boolean]),
});

const EventBasicModelIO = t.interface({
    sport: t.string,
});

export type EventBasicModelType = t.TypeOf<typeof EventBasicModelIO>;

export const decodeEventBasicModel = buildValidator('EventBasicModelIO', EventBasicModelIO, true);

const GradeItemIO = t.union([t.interface({ 
    id: t.union([ t.number, t.undefined, t.null ]),
    name: t.union([ t.string, t.undefined, t.null ]),
}), t.null, t.undefined]);

export type GradeItemType = t.TypeOf<typeof GradeItemIO>;

export const EventModelIO = t.interface({
    id: t.number,
    idv2: t.string,
    uuid: t.union([ t.string, t.undefined, t.null ]),
    sport: t.string,
    sportOriginal: t.string,
    competition: t.number,
    template: t.string,
    updated: t.interface({
        updatedAt: t.string,
    }),
    participants: t.record(t.string, ParticipantModelIO),
    timeSettings: TimeSettingsIO,
    feedData: feedDataIO,
    active: t.boolean,
    display: t.boolean,
    state: t.string,
    name: t.string,
    antePost: t.boolean,
    statistics: t.record(t.string, t.union([
        t.undefined,
        t.record(t.string, t.union([
            t.undefined,
            t.string
        ]))
    ])),
    stream: t.union([
        t.array(StreamIO),
        t.undefined,
        t.null
    ]),
    platformObject: t.union([
        t.interface({
            id: t.union([t.string, t.null, t.undefined]),
        }),
        t.null,
        t.undefined
    ]),
    tags: t.record(t.string, t.string),
    incidents: t.union([t.undefined, t.null, t.array(IncidentsIO)]),
    autoTakeDown: t.union([ t.boolean, t.undefined, t.null ]),
    eventType: t.union([ t.string, t.undefined, t.null ]),
    feedSettings: t.union([t.undefined, t.null, FeedSettingsIO]),
    grades: t.union([t.undefined, t.null,
        t.interface({
            inPlay: t.union([t.undefined, t.null, GradeItemIO]),
            prematch: t.union([t.undefined, t.null, GradeItemIO]),
            show: t.union([t.undefined, t.null, GradeItemIO]),
        })
    ]),
    selectedBetLimitGroups: t.union([t.undefined, t.null, t.interface({
        inPlay: t.string,
        prematch: t.string,
        show: t.string,
    })]),
    rawGameState: t.union([t.undefined, t.null, t.string])
});

export const isEventModel = createGuard(EventModelIO);

export const decodeEventModel = buildValidator('EventModelIO', EventModelIO, true);

export type EventModelType = t.TypeOf<typeof EventModelIO>;

export type EventModelProxyType = {
    active: boolean;
    antePost: boolean;
    autoTakeDown?: null | undefined | boolean;
    competition: number;
    display: boolean;
    eventType?: null | undefined | string;
    feedData?: null | undefined | Record<string, string>;
    feedSettings?: null | undefined | {
        eachWay?: null | undefined | boolean;
        prices?: null | undefined | boolean;
        results?: null | undefined | boolean;
        updates?: null | undefined | boolean;
    };
    grades?: null | undefined | {
        inPlay?: null | undefined | {
            id: number;
            name: string;
        };
        prematch?: null | undefined | {
            id: number;
            name: string;
        };
        show?: null | undefined | {
            id: number;
            name: string;
        };
    };
    id: number;
    idv2: string;
    incidents?: null | undefined | Array<{
        addedManually?: null | undefined | boolean;
        bettable?: null | undefined | {
            type?: null | undefined | string;
        };
        deleted?: null | undefined | boolean;
        editedManually?: null | undefined | boolean;
        feedUpdatable?: null | undefined | boolean;
        id?: null | undefined | string;
        period?: null | undefined | string;
        player?: null | undefined | string;
        team?: null | undefined | string;
        time?: null | undefined | {
            minutes?: null | undefined | number;
            seconds?: null | undefined | number;
        };
    }>;
    name: string;
    participants: Record<string, {
        id: number;
        idv2?: null | undefined | string;
        metadata?: null | undefined | Record<string, string>;
        name?: null | undefined | string;
        role?: null | undefined | string;
    }>;
    platformObject?: null | undefined | {
        feedProviderName?: null | undefined | string;
        id?: null | undefined | string;
        name?: null | undefined | string;
    };
    rawGameState?: null | undefined | string;
    revision: number;
    sport: string;
    state: string;
    statistics: Record<string, Record<string, string>>;
    stream?: null | undefined | Array<{
        eventId: number;
        eventName: string;
        geoRuleCountries: string;
        geoRuleType: 'DENY' | 'ALLOW';
        id: number;
        live: boolean;
        provider: string;
        streamId: number;
        universe: string;
    }>;
    tags: Record<string, string>;
    template: string;
    timeSettings: {
        offAtStartTime?: null | undefined | boolean;
        startTime: string;
        started: boolean;
        timeZone: string;
        timeline?: null | undefined | string;
        tradedInPlay: boolean;
    };
    updated: {
        updatedAt: string;
    };
    updatedInMs: number;
    uuid?: null | undefined | string;
};

export interface CreateEventDataProxyType {
    competition: number;
    competitionIdV2?: null | undefined | string;
    participants: Array<{
        name?: null | undefined | string;
        participant: string;
        role?: null | undefined | string;
    }>;
    sport: string;
    template: string;
    timeSettings: {
        startTime: string;
        timeZone: string;
    };
};

export interface OpenapiProxyUpdateEventParamsProxyType {
    event_id: number;
    requestBody: {
        active?: null | undefined | boolean;
        antePost?: null | undefined | boolean;
        autoTakeDown?: null | undefined | boolean;
        display?: null | undefined | boolean;
        feedSettings?: null | undefined | {
            eachWay?: null | undefined | boolean;
            prices?: null | undefined | boolean;
            results?: null | undefined | boolean;
            updates?: null | undefined | boolean;
        };
        grades?: null | undefined | {
            inPlay?: null | undefined | {
                id: number;
            };
            prematch?: null | undefined | {
                id: number;
            };
            show?: null | undefined | {
                id: number;
            };
        };
        markets?: null | undefined | Array<{
            betDelay: number;
            marketId: string;
            tradedInPlay: boolean;
        }>;
        name?: null | undefined | string;
        tags?: null | undefined | Record<string, Array<string>>;
        timeSettings?: null | undefined | {
            offAtStartTime?: null | undefined | boolean;
            startTime?: null | undefined | string;
            timeZone?: null | undefined | string;
            timeline?: null | undefined | string;
            tradedInPlay?: null | undefined | boolean;
        };
    };
}
