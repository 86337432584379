import * as React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import {
    YouDecideHeaderWrapper,
    YouDecideLabel,
    YouDecideDescription,
    YouDecideDescriptionContainer,
    YouDecideCashOutText,
    YouDecideDescWrapper,
    RabBuildAbetIconWrapper,
} from './YouDecideHeader.style';

export const YouDecideHeader = (): JSX.Element => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents } = appLayoutsState;
    const { config } = configComponents;

    return (
        <YouDecideHeaderWrapper>
            <RabBuildAbetIconWrapper />
            <YouDecideDescWrapper>
                <YouDecideLabel>{config.rabHeaderTitle}</YouDecideLabel>
                <YouDecideDescriptionContainer>
                    <YouDecideDescription>
                        <I18n
                            langKey='you-decide.header.create-bet.info'
                            defaultText='Create your own bet'
                        />
                    </YouDecideDescription>
                    {config.rabCashoutAvailable === true ? (
                        <YouDecideCashOutText>
                            (
                            <I18n
                                langKey='you-decide.header.create-bet.cashout-info'
                                defaultText='Unavailable for cashout'
                            />
                            )
                        </YouDecideCashOutText>
                    ) : null}
                </YouDecideDescriptionContainer>
            </YouDecideDescWrapper>
        </YouDecideHeaderWrapper>
    );
};
