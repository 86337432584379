import { autorun } from 'mobx';
import queryString from 'query-string';
import { AppState } from 'src/appState/AppState';
import { SelectionId } from 'src_common/common/websocket2/id/WebsocketId';

const parseMarketAndSelectionOldFormat = (rawId: string): { marketId: number; selectionId: number } | null => {
    const idChunks = rawId.split('-');

    if (idChunks.length === 2) {
        const marketIdRaw = idChunks[0];
        const selectionIdRaw = idChunks[1];

        if (marketIdRaw === undefined || selectionIdRaw === undefined) {
            return null;
        }

        const marketId = parseInt(marketIdRaw, 10);
        const selectionId = parseInt(selectionIdRaw, 10);

        if (!isNaN(marketId) && !isNaN(selectionId)) {
            return {
                marketId,
                selectionId,
            };
        }
    }

    return null;
};

const parseIntAndCheck = (value: string | undefined): number | null => {
    if (value === undefined) {
        return null;
    }

    const valueNumber = parseInt(value, 10);

    if (isNaN(valueNumber)) {
        return null;
    }

    return valueNumber;
};

export const parseMarketAndSelectionNewFormat = (
    rawId: string
): { eventId: number; marketId: number; selectionId: number } | null => {
    const idChunks = rawId.split('-');

    const [eventIdRaw, marketIdRaw, selectionIdRaw] = idChunks;

    const eventId = parseIntAndCheck(eventIdRaw);
    const marketId = parseIntAndCheck(marketIdRaw);
    const selectionId = parseIntAndCheck(selectionIdRaw);

    if (eventId === null || marketId === null || selectionId === null) {
        return null;
    }

    return {
        eventId,
        marketId,
        selectionId,
    };
};

const sendActionAddToBetslip = (
    appState: AppState,
    reaction: { dispose: () => void },
    selectionId: SelectionId
): void => {
    const selectionModel = selectionId.getModel();

    if (selectionModel !== null) {
        appState.appSportsBookState.betSlipState.legsState.betslipData.onAddSimpleBet(selectionModel.id2);
        reaction.dispose();
    }
};

const addToBetslipParse = (appState: AppState, chunk: string): string | null => {
    const ids = parseMarketAndSelectionOldFormat(chunk);

    if (ids !== null) {
        const { marketId, selectionId } = ids;
        const selectionIdNew = appState.appSportsBookState.models.id
            .getEventId(0)
            .getMarketId(marketId)
            .getSelectionId(selectionId);

        autorun((reaction) => {
            sendActionAddToBetslip(appState, reaction, selectionIdNew);
        });

        const origin = window.location.origin;

        return `The old type of linking was used. Expected new format: ${origin}/?action-add-bet-to-betslip=123456-${marketId}-${selectionId} (eventId-marketId-selectionId)`;
    }

    const idsNew = parseMarketAndSelectionNewFormat(chunk);

    if (idsNew !== null) {
        const { eventId, marketId, selectionId } = idsNew;

        const selectionIdNew = appState.appSportsBookState.models.id
            .getEventId(eventId)
            .getMarketId(marketId)
            .getSelectionId(selectionId);

        autorun((reaction) => {
            sendActionAddToBetslip(appState, reaction, selectionIdNew);
        });

        return null;
    }

    return `This part of the address "${chunk}" is incorrect - Expected format "number-number-number" (evemtId-marketId-selectionId)`;
};

//New-url
//http://127.0.0.1:3030/?action-add-bet-to-betslip=225961-18874546-56812103

export const starAppStateInitAddToBetslip = (
    appState: AppState,
    urlParams: queryString.ParsedQuery<string> | null
): void => {
    /*
        Acceptect url:
        http://127.0.0.1:3030/?action-add-bet-to-betslip=18874546-56812103
        http://127.0.0.1:3030/?action-add-bet-to-betslip=41096264-116699063
        http://127.0.0.1:3030/?action-add-bet-to-betslip=41096264-116699063,41096264-116699065

        ?marketId-selectionId
        ?marketId-selectionId,marketId-selectionId
    */
    const addToBetslip = urlParams === null ? null : urlParams['action-add-bet-to-betslip'];
    if (typeof addToBetslip === 'string') {
        const chunks = addToBetslip.split(',');

        for (const chunk of chunks) {
            const error = addToBetslipParse(appState, chunk);

            if (error !== null) {
                console.error(`Problem with adding to betslip: ${addToBetslip}`);
                console.error(error);
            }
        }

        appState.appLayoutsState.starRouter.redirectToActionAddBetToBetslip();
    }
};
