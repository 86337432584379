import * as React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    ResponsibleInfo,
    SingleItem,
    SingleItemLink,
    LinkWrapper,
    LogoGameCareWrapper,
    LogoGambleAwareWrapper,
    LogoGamstopWrapper,
    Logo18PlusWrapper,
    LogoProblemGamblingWrapper,
    LogoGamblingCommissionWrapper,
    LogoRow,
    LogoIbasWrapper,
} from './ResponsibleGambling.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { FooterHeader } from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/content/FooterContent.style';
import problemGambling from 'src/domains/layouts/webview/modules/layoutSkeleton/footer/responsibleGambling/assets/problem-gambling.webp';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LogoGamblingTherapy } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoGamblingTherapy';
import { LogoGamCareHelpline } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoGamCareHelpline';
import { LogoTakeTimeToThink } from 'src/domains/layouts/shared/assets/logotypes/responsible-gambling/LogoTakeTimeToThink';

export const ResponsibleGamblingInfo = observer('ResponsibleGamblingInfo', (): JSX.Element => {
    const common = useCommon();
    const { operatorFullName: companyName, footerAdditionalContent } = common.features;
    const link = (): JSX.Element => {
        if (footerAdditionalContent === true) {
            return (
                <LinkWrapper
                    to={{
                        account: 'static',
                        static: 'safer-gambling',
                    }}
                >
                    <I18n
                        langKey='footer.commitment.safer-gambling'
                        defaultText='Safer Gambling'
                    />
                </LinkWrapper>
            );
        }
        return (
            <LinkWrapper
                to={{
                    account: 'static',
                    static: 'responsible-gambling',
                }}
            >
                <I18n
                    langKey='footer.commitment.responsible-gambling'
                    defaultText='Responsible Gambling'
                />
            </LinkWrapper>
        );
    };

    return (
        <>
            <I18n
                langKey='footer.commitment'
                defaultText='{companyName} is committed to'
                params={{ companyName }}
            />
            <br />
            {link()}
        </>
    );
});

export const ResponsibleGamblingDefault = observer('ResponsibleGamblingDefault', (): JSX.Element => {
    const common = useCommon();
    const {
        isGamblingComissionRGLogo,
        problemGambling: problemGamblingFlag,
        depositLimitsType,
        footerAdditionalContent,
    } = ConfigComponents.get(common).config;
    const languagesState = LanguagesState.get(common);

    const ibasLabel = languagesState.getTranslation(
        'footer.responsible.gambling.ibas.label',
        'read more about ibas UK'
    );
    const gamcareLabel = languagesState.getTranslation(
        'footer.responsible.gambling.gamcare.label',
        'read more about gamcare'
    );
    const gamblingCommissionLabel = languagesState.getTranslation(
        'footer.responsible.gambling.gamblingcomission.label',
        'read more about gambling comission'
    );
    const gambleAwareLabel = languagesState.getTranslation(
        'footer.responsible.gambling.gamble-aware.label',
        'read more about be gamble aware'
    );
    const gamstopLabel = languagesState.getTranslation(
        'footer.responsible.gambling.gamstop.label',
        'read more about gamstop.co.uk'
    );
    const problemgamblingLabel = languagesState.getTranslation(
        'footer.responsible.gambling.problemgambling.label',
        'read more about problemgambling.ie'
    );
    const gamCareHelplineLabel = languagesState.getTranslation(
        'footer.responsible.gambling.gamcarehelpline.label',
        'read more at gamcare.org.uk'
    );
    const takeTimeToThinkLabel = languagesState.getTranslation(
        'footer.responsible.gambling.taketimetothink.label',
        'read more at taketimetothink.co.uk'
    );
    const gamblingTherapyLabel = languagesState.getTranslation(
        'footer.responsible.gambling.gamblingtherapy.label',
        'read more at gamblingtherapy.org'
    );

    return (
        <React.Fragment>
            <FooterHeader>
                {depositLimitsType === 'calendarType' ? (
                    <I18n
                        langKey='footer.safer.gambling'
                        defaultText='Safer Gambling'
                    />
                ) : (
                    <I18n
                        langKey='footer.responsible.gambling'
                        defaultText='Responsible Gambling'
                    />
                )}
            </FooterHeader>

            <LogoRow>
                <SingleItem data-test='18-plus-logo'>
                    <Logo18PlusWrapper />
                </SingleItem>

                <SingleItem>
                    <SingleItemLink
                        aria-label={ibasLabel}
                        to='https://www.ibas-uk.com/'
                        dataTest='responsible-gambling-ibas'
                    >
                        <LogoIbasWrapper />
                    </SingleItemLink>
                </SingleItem>

                <SingleItem>
                    <SingleItemLink
                        aria-label={gamcareLabel}
                        to='https://www.gamcare.org.uk/'
                        dataTest='responsible-gambling-gamecare'
                    >
                        <LogoGameCareWrapper />
                    </SingleItemLink>
                </SingleItem>
            </LogoRow>

            <LogoRow
                isCentered={true}
                isWrap={true}
            >
                <SingleItem>
                    <SingleItemLink
                        aria-label={gamstopLabel}
                        to='https://www.gamstop.co.uk/'
                        dataTest='responsible-gambling-gamstop'
                    >
                        <LogoGamstopWrapper />
                    </SingleItemLink>
                </SingleItem>
                {isGamblingComissionRGLogo === true ? (
                    <SingleItem>
                        <SingleItemLink
                            aria-label={gamblingCommissionLabel}
                            to='https://www.gamblingcommission.gov.uk/'
                            dataTest='responsible-gambling-gamblingcomission'
                        >
                            <LogoGamblingCommissionWrapper />
                        </SingleItemLink>
                    </SingleItem>
                ) : null}
            </LogoRow>

            {problemGamblingFlag === true ? (
                <LogoRow>
                    <SingleItem>
                        <SingleItemLink
                            aria-label={problemgamblingLabel}
                            to='https://www.problemgambling.ie/'
                        >
                            <LogoProblemGamblingWrapper
                                imgBase={{ src: problemGambling }}
                                alt='Problem gambling logo'
                            />
                        </SingleItemLink>
                    </SingleItem>
                </LogoRow>
            ) : null}

            <LogoRow>
                <SingleItem>
                    <SingleItemLink
                        aria-label={gambleAwareLabel}
                        to='https://www.gambleaware.org/'
                        dataTest='responsible-gambling-gambleaware'
                    >
                        <LogoGambleAwareWrapper />
                    </SingleItemLink>
                </SingleItem>
            </LogoRow>

            {footerAdditionalContent === true && (
                <>
                    <LogoRow>
                        <SingleItem>
                            <SingleItemLink
                                aria-label={gamCareHelplineLabel}
                                to='https://www.gamcare.org.uk/get-support/talk-to-us-now/'
                            >
                                <LogoGamCareHelpline />
                            </SingleItemLink>
                        </SingleItem>
                    </LogoRow>

                    <LogoRow>
                        <SingleItem>
                            <SingleItemLink
                                aria-label={takeTimeToThinkLabel}
                                to='https://www.taketimetothink.co.uk/'
                            >
                                <LogoTakeTimeToThink />
                            </SingleItemLink>
                        </SingleItem>
                    </LogoRow>

                    <LogoRow>
                        <SingleItem>
                            <SingleItemLink
                                aria-label={gamblingTherapyLabel}
                                to='https://gamblingtherapy.org/'
                            >
                                <LogoGamblingTherapy />
                            </SingleItemLink>
                        </SingleItem>
                    </LogoRow>
                </>
            )}

            <ResponsibleInfo data-test='responsible-gambling-section-responsible-gambling-link'>
                <ResponsibleGamblingInfo />
            </ResponsibleInfo>
        </React.Fragment>
    );
});
