import { action, makeObservable, observable } from 'mobx';

export class RaceCardHeaderState {
    @observable public isExpanded: boolean = false;

    public constructor() {
        makeObservable(this);
    }

    @action public handleExpanded = (): void => {
        this.isExpanded = !this.isExpanded;
    };
}
