/** @returns 1st, 2nd, 3rd, 4th etc. */
export const formatAsOrdinalSuffix = (position: number): string => {
    if (position < 0) {
        throw new Error('Ordinal suffixes are not defined for negative numbers.');
    }

    const lastTwoDigits = position % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return `${position}th`;
    }

    const lastDigit = position % 10;

    switch (lastDigit) {
        case 1:
            return `${position}st`;
        case 2:
            return `${position}nd`;
        case 3:
            return `${position}rd`;
        default:
            return `${position}th`;
    }
};
