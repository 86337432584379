import { observer } from 'src/utils/mobx-react';

import {
    OverlayDefault,
    Content,
    PopupHeader,
    PopupDescription,
    TermsBox,
    TermsBoxInner,
    BtnWrapper,
    AcceptBtn,
    LinkWrapper,
} from 'src/domains/players/webview/components/Account/BlockingNotifications/BlockingNotifications.style';
import React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Policy } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/Policy';
import { useAppStateContext } from 'src/appState/AppState';
import { UniverseComponent } from 'src/domains/common/universes';

interface BlockingLicenceNotificationPropsType {
    onConfirm: () => Promise<void>;
}

export const BlockingLicenceNotification = observer(
    'BlockingLicenceNotificationPropsType',
    ({ onConfirm }: BlockingLicenceNotificationPropsType): JSX.Element | null => {
        const {
            config: { accountHelperMail },
        } = useAppStateContext();

        return (
            <OverlayDefault data-test='overlay-prompt'>
                <Content>
                    <UniverseComponent>
                        {(universeModule): React.ReactElement => <universeModule.compliancePopupLogo />}
                    </UniverseComponent>

                    <PopupHeader>
                        <I18n
                            langKey='account.change-licence-notification.title'
                            defaultText='Change of Licence'
                        />
                    </PopupHeader>
                    <PopupDescription>
                        <I18n
                            langKey='account.change-licence-notification.description-part-1'
                            defaultText='DragonBet is now operating under the DragonBet Ltd Gambling Commission licence. To continue to use your account, please accept the new terms and conditions.'
                        />
                    </PopupDescription>
                    <TermsBox>
                        <TermsBoxInner>
                            <Policy
                                leftAlign
                                id='terms-and-conditions'
                            />
                        </TermsBoxInner>
                    </TermsBox>
                    <PopupDescription>
                        <I18n
                            langKey='account.change-licence-notification.description-part-2'
                            defaultText='Your account history, account balance, open bets and payment methods are unaffected. All your favourite DragonBet products are still available to bet on.'
                        />
                    </PopupDescription>

                    <PopupDescription>
                        <I18n
                            langKey='account.change-licence-notification.description-part-3'
                            defaultText='If you do not agree to have your account transferred to the DragonBet Ltd licence, do not click the Accept button and contact'
                        />
                        <LinkWrapper to={`mailto:${accountHelperMail}`}>
                            <I18n
                                langKey='account.deposit.info-page.email'
                                defaultText=' {accountHelperMail}'
                                params={{ accountHelperMail }}
                            />
                        </LinkWrapper>
                    </PopupDescription>

                    <BtnWrapper>
                        <AcceptBtn
                            onClick={onConfirm}
                            size='medium'
                        >
                            <I18n
                                langKey='account.change-license-notification.confirm-button.label'
                                defaultText='Accept'
                            />
                        </AcceptBtn>
                    </BtnWrapper>
                </Content>
            </OverlayDefault>
        );
    }
);
