import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const RaceSummaryRowDetailsWrapper = withConfig(theme => styled('li', { label: 'RaceSummaryRowDetailsWrapper' })`
    background-color: ${theme.star.raceSummary.bgColor};
    border-style: solid;
    border-color: ${theme.star.raceSummary.borderColor};
    border-width: 0 0 1px 0;
    padding: 12px 16px;
    @media ${theme.star.mediaQuery.tablet} {
        border-width: 0 1px 1px 1px;
    }
`);

export const HeadingRaceDetailsWrapper = withConfig(theme => styled('header', { label: 'HeadingRaceDetailsWrapper' })`
    color: ${theme.star.raceCardDetails.txtColor};
    display: flex;
    flex-flow: column nowrap;
    font-size: ${theme.star.fontSize.xSmall};
    gap: 4;
    line-height: 1.4;
    margin-bottom: 24px;
`);

export const HeadingRaceDetails = styled('h4', { label: 'HeadingRaceDetails' })`
    margin: 0;
`;

export const RaceDetailsListItem = withConfig(theme => styled('li', { label: 'RaceDetailsListItem' })`
    border-bottom: 1px solid ${theme.star.raceSummary.borderColor};
    display: flex;
    flex-flow: column nowrap;
    font-size: ${theme.star.fontSize.xSmall};
    gap: 4px;
    line-height: 1.4;
    margin: 0;
    padding: 12px 0;
    &:first-of-type {
        padding-top: 0;
    }
    &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
    }
    @media ${theme.star.mediaQuery.tablet} {
        flex-flow: row wrap;
        gap: 8px;
    }
`);

export const RaceDetailsName = styled ('span')`
    display: inline-block;
    margin-left: 10px;
`;

export const RaceDetailsWrapper = withConfig(theme => styled ('div')`
    display: flex;
    flex-flow: row nowrap;
    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 50%;
        margin-left: auto;
    }
`);

export const RunnerCountWrapper = styled ('div')`
    display: flex;
    flex-flow: row nowrap;
`;

export const DetailsItem = styled('span', { label: 'DetailsItem' })`
    position: relative;
    &::after {
        content: '•';
        margin: 0 8px;
    }
    &:last-of-type {
        &::after {
            content: none;
        } 
    }
`;

export const DetailsItemEmpty = withConfig(theme => styled('span', { label: 'DetailsItemEmpty' })`
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
`);

