import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { Card } from 'src/domains/players/webview/components/Account/common/card/Card';
import { BadgeGroup } from 'src/domains/players/webview/components/Account/common/badge/BadgeGroup';
import {
    ButtonsWrapper,
    CreditsCardButton,
    CreditsCardConditionsContainer,
    CreditsCardContainer,
    CreditsCardHeader,
    CreditsCardRow,
    CreditsCardRowLabel,
    CreditsCardRowValue,
} from './Credits.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Badge } from 'src/domains/players/webview/components/Account/common/badge/Badge';
import { BonusWinningsWalletViewType } from 'src/api_openapi/v2/bonus-engine/schemas';
import { CreditsState } from './Credits.state';
import { GameSharedModel } from 'src/domains/casino/state/AppCasino.state';
import { useAppStateContext } from 'src/appState/AppState';
import { useCommon } from 'src/domains/common/Common';
import { TermsPage } from 'src/domains/players/webview/components/SignUp';
import { TermsDrawerState } from './TermsDrawer/TermsDrawer.state';

interface CreditsBonusBalanceCardPropsType {
    bonus: BonusWinningsWalletViewType;
    creditsState: CreditsState;
    getGameModelByLaunchGameId: (gameId: string | number) => GameSharedModel | null;
}

export const CreditsBonusBalanceCard = observer(
    'CreditsBonusBalanceCard',
    ({ bonus, creditsState, getGameModelByLaunchGameId }: CreditsBonusBalanceCardPropsType) => {
        const common = useCommon();
        const {
            appPlayersState: { customKeyboard, usersState },
        } = useAppStateContext();

        const [state] = useState<TermsDrawerState>(() => new TermsDrawerState(common, customKeyboard, usersState));
        const campaignId = bonus.campaignId ?? null;

        return (
            <Card haveBorder={true}>
                {state.displayTermsPage ? (
                    <TermsPage
                        title='TERMS & CONDITIONS'
                        content={state.terms ?? 'Error occurred'}
                        closePage={state.closePage}
                        hasArrow
                    />
                ) : null}
                <CreditsCardContainer>
                    <div>
                        <CreditsCardHeader>
                            <div>{creditsState.getCreditValue(bonus.balance)}</div>
                            <div>
                                <Badge type={creditsState.mapBadgeTypeBasedOnStatus(bonus.status)}>
                                    {bonus.status}
                                </Badge>
                            </div>
                        </CreditsCardHeader>
                    </div>
                    <CreditsCardConditionsContainer>
                        {bonus.eligibleGames === undefined || bonus.eligibleGames === null ? null : (
                            <CreditsCardRow>
                                <CreditsCardRowLabel>
                                    <I18n
                                        langKey='account.credits.eligibleGames:'
                                        defaultText='Eligible Games:'
                                    />
                                </CreditsCardRowLabel>
                                <CreditsCardRowValue>
                                    <BadgeGroup
                                        badges={creditsState.mapEligibleGamesToNames(
                                            bonus.eligibleGames,
                                            getGameModelByLaunchGameId
                                        )}
                                    />
                                </CreditsCardRowValue>
                            </CreditsCardRow>
                        )}

                        {bonus.playthrough === undefined || bonus.playthrough === null ? null : (
                            <CreditsCardRow>
                                <CreditsCardRowLabel>
                                    <I18n
                                        langKey='account.credits.playthrough'
                                        defaultText='Play Through'
                                    />
                                </CreditsCardRowLabel>
                                <CreditsCardRowValue>{bonus.playthrough}x</CreditsCardRowValue>
                            </CreditsCardRow>
                        )}
                    </CreditsCardConditionsContainer>
                    <ButtonsWrapper>
                        {campaignId === null ? null : (
                            <CreditsCardButton
                                size='small'
                                isLeft
                                onClick={(): void => state.showTermsPage('free-spins', bonus.campaignId)}
                                version='tertiary-dark'
                            >
                                <I18n
                                    langKey='account.credits.view-terms'
                                    defaultText='View Terms'
                                />
                            </CreditsCardButton>
                        )}

                        <CreditsCardButton
                            size='medium'
                            onClick={(): void => creditsState.redirectToCasino(null)}
                            disabled={creditsState.isButtonDisabled(bonus.status)}
                        >
                            <I18n
                                langKey='account.credits.play-now'
                                defaultText='Play Now'
                            />
                        </CreditsCardButton>
                    </ButtonsWrapper>
                </CreditsCardContainer>
            </Card>
        );
    }
);
