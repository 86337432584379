import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

/* 1 - You can delete this if z-index will be deleted in Selection component */
export const MoreLessGroup = withConfig(theme => styled('div', { label: 'MoreLessGroup' })`
    border-top: solid 1px ${theme.star.moreLessButton.borderColor};
    text-align: center;
    position: relative; /* 1 */
    margin-top: -1px; /* 1 */
`);

export const LinkMoreLessWrapper = withConfig(theme => styled('button', { label: 'LinkMoreLessWrapper' })`
    background-color: ${theme.star.marketHeader.bgColor};
    border: 0;
    color: ${theme.star.marketHeader.txtColor};
    cursor: pointer;
    display: inline-block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    padding: 12px 10px;
    position: relative;
    text-decoration: none;
    transition: background-color .35s ease, color .35s ease;
    width: 100%;
    &:hover {
        background-color: ${theme.star.marketHeader.bgColorSecondary};
        color: ${theme.star.marketHeader.txtColor};
    }
`);

export const SelectionsGroup = styled('section', { label: 'SelectionsGroup' })`
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
`;

export const SelectionList = styled('ul', { label: 'SelectionList' })`
    border-right: 0;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    width: 100%;
`;

export const DetailsEachWay = withConfig(theme => styled('ul', { label: 'DetailsEachWay' })`
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    margin: 0;
    padding: 0 8px 0 8px;
    white-space: nowrap;
`);

export const DetailsEachWayItem = styled('li', { label: 'DetailsEachWayItem' })`
    margin-left: 5px;
    &:first-of-type {
        margin-left: 0;
    }
`;
