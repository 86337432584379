import React, { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import {
    AccountTabs,
    AccountOverlay,
    TabsWrapper,
    EmptyTab,
} from 'src/domains/players/webview/components/Account/accountParts/Account.style';
import { Tab } from 'src/domains/players/webview/components/Account/accountParts/Tab';
import { AccountDrawerState } from 'src/domains/players/webview/components/Account/accountDrawer/AccountDrawer.state';
import { AccountTabsBuilderState } from 'src/domains/players/webview/components/Account/accountDrawer/AccountTabsBuilder.state';
import { PromoSidebar } from 'src/domains/players/webview/components/Account/promoSidebar/PromoSidebar';
import { RouteViewType } from 'src/domains/layouts/state/router/newRouter/mainRouteTypes';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Common, useCommon } from 'src/domains/common/Common';
import { PageType } from 'src/domains/players/state/CmsDrawerPagesState';

export class AccountDrawerLocalState {
    public readonly tabBuilderData: AccountTabsBuilderState;
    public readonly accountDrawerData: AccountDrawerState;

    public constructor(
        configComponents: ConfigComponents,
        language: LanguagesState,
        appCasinoState: AppCasinoState,
        usersState: UsersState,
        starRouter: StarRouter,
        common: Common
    ) {
        this.tabBuilderData = new AccountTabsBuilderState(
            configComponents,
            language,
            appCasinoState,
            usersState,
            common
        );

        this.accountDrawerData = new AccountDrawerState(this.getTabBuilderState(), starRouter, common.session);
    }

    public getTabBuilderState = (): AccountTabsBuilderState => {
        return this.tabBuilderData;
    };

    public getAccountDrawerState = (): AccountDrawerState => {
        return this.accountDrawerData;
    };
}

interface AccountDrawerPropsType {
    promotionSlug: string;
    currentView: RouteViewType | null;
}

const isOverlayFullCover = (
    starRouter: StarRouter,
    newGamblingCommissionRegulations: boolean,
    pageType: PageType | null
): boolean => {
    if (newGamblingCommissionRegulations === true) {
        const staticRouteName = starRouter.staticParam.param;
        const isOverlayFullCover = staticRouteName === 'safer-gambling' || pageType === 'responsible-gambling';
        return isOverlayFullCover;
    }

    return false;
};

export const AccountDrawer = observer('AccountDrawer', (props: AccountDrawerPropsType): JSX.Element | null => {
    const { appLayoutsState, appCasinoState, appPlayersState } = useAppStateContext();
    const { starRouter, configComponents, languagesState, bannersBoxState, popupState } = appLayoutsState;
    const { config } = configComponents;
    const { usersState, cmsDrawerPagesState } = appPlayersState;
    const common = useCommon();

    const routeName = props.currentView?.name ?? '';
    const overlayFullCover = isOverlayFullCover(
        starRouter,
        config.newGamblingCommissionRegulations,
        cmsDrawerPagesState.pageType
    );

    const [drawerState] = useState(
        () =>
            new AccountDrawerLocalState(
                configComponents,
                languagesState,
                appCasinoState,
                usersState,
                starRouter,
                common
            )
    );

    const state = drawerState.getAccountDrawerState();

    useEffect((): (() => void) => {
        state.setIsMounted();
        return reaction(
            () => state.futureTabName,
            () => state.onNewTabRequest()
        );
    }, [routeName]);

    /**
     * Some sub-components of this Component trigger `redirect` immediately after mounting (see `VerifyAccountTab`).
     * Mentioned `redirect` had been done before this component were mounted.
     * Because this component is listening to "redirects" (AFTER it is mounted - because of `useEffect`), we have to do it in that way.
     * For now, `AccountDrawer` is mounted before its sub-components.
     */

    if (state.isMounted === false) {
        return null;
    }

    const { currentTabData, futureTabData, currentTabDataForHeader, futureTabDataForHeader } = state;
    let currentTab = <EmptyTab key='empty-tab' />;
    if (state.currentTabName !== null) {
        currentTab = (
            <Tab
                key={state.currentTabName.account}
                tabName={state.currentTabName}
                showHeader={state.shouldDisplayMainHeader(state.currentTabName)}
                tabData={currentTabData}
                tabDataForHeader={currentTabDataForHeader}
            />
        );
    }

    let futureTab = null;
    if (state.areTabsDiffer && state.futureTabName !== null) {
        futureTab = (
            <Tab
                key={state.futureTabName.account}
                tabName={state.futureTabName}
                showHeader={state.shouldDisplayMainHeader(state.futureTabName)}
                tabData={futureTabData}
                tabDataForHeader={futureTabDataForHeader}
            />
        );
    }

    return (
        <>
            <AccountTabs
                desktopNoRightSpace={config.cryptoOperatorAccountType || popupState.rightPanelAttachedToRightEdge}
                key='account__tabs--with-header'
                isInvisible={!state.isOpened}
                topOffset={bannersBoxState.boxHeight}
                data-test='Tab'
            >
                <TabsWrapper
                    className='account__tab'
                    isLeaving={state.isDuringForwardAnimation}
                    isEntering={state.isDuringBackwardAnimation}
                    animationDuration={state.animationDurationInSeconds}
                    onTransitionEnd={state.finishAnimation}
                    data-test={state.isDuringAnimation ? 'animating' : ''}
                >
                    {currentTab}
                    {futureTab}
                </TabsWrapper>
            </AccountTabs>

            <AccountOverlay
                isVisible={state.isOpening}
                onClick={starRouter.closeAccount}
                isFullCover={overlayFullCover}
            />

            <PromoSidebar
                promotionSlug={props.promotionSlug}
                key='promo-sidebar'
            />
        </>
    );
});
