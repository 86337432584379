import React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { SearchState } from 'src/domains/layouts/webview/components/search/Search.state';
import { HighlightedText } from 'src/domains/sportsbook/shared/Components';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import {
    SearchResultsHeader,
    ContentResultsList,
    InputInnerWrapper,
    SearchResultsWrapper,
    SearchInput,
    SearchWrapper,
    SearchIconWrapper,
    CloseIconWrapper,
    ResultsItemTime,
    NotFoundItem,
    ResultListItem,
    LinkOverlay,
} from 'src/domains/layouts/webview/components/search/Search.style';
import { SidebarSlideIconWrapper } from 'src/domains/layouts/webview/modules/LayoutSkeleton';
import { DateTime } from 'src_common/utils/time/time';

interface SearchEventsResultItemPropsType {
    searchState: SearchState;
    event: EventModel;
    query: string;
    selectedEventIdForView: number | null;
}

const SearchEventsResultItem = observer('SearchEventsResultItem', (props: SearchEventsResultItemPropsType) => {
    const { searchState, event, query, selectedEventIdForView } = props;
    const isElementActive = selectedEventIdForView === event.id;

    const onClick = (): void => {
        searchState.redirectToEvent(event);
    };

    const onHover = (e: React.SyntheticEvent<Element, Event>): void => {
        e.stopPropagation();
        e.preventDefault();
        searchState.setSelectedEventId(event.id);
    };

    return (
        <ResultListItem
            isElementActive={isElementActive}
            to={undefined}
            onClick={onClick}
            onMouseEnter={onHover}
        >
            <HighlightedText
                name={event.name}
                query={query}
            />
            <ResultsItemTime>{DateTime.from(event.timeSettingsStartTime)?.format('ddd h:mma')}</ResultsItemTime>
        </ResultListItem>
    );
});

interface SearchEventsResultResultPropsType {
    searchState: SearchState;
}

const SearchEventsResult = observer('SearchEventsResult', (props: SearchEventsResultResultPropsType) => {
    const { searchState } = props;
    const { query, events, selectedEventIdForView } = searchState;

    if (events.length === 0) {
        return (
            <NotFoundItem>
                <I18n
                    langKey='events.search.no-events-found'
                    defaultText='No events have been found. Please try another search term.'
                />
            </NotFoundItem>
        );
    }

    return (
        <ContentResultsList key='events-result'>
            {events.map((event) => (
                <SearchEventsResultItem
                    key={event.id}
                    searchState={searchState}
                    event={event}
                    query={query}
                    selectedEventIdForView={selectedEventIdForView}
                />
            ))}
        </ContentResultsList>
    );
});

export const Search = observer('Search', () => {
    const { appLayoutsState } = useAppStateContext();
    const { languagesState, breakpointsState, searchState, configComponents, starRouter } = appLayoutsState;
    const {
        config: { layout },
    } = configComponents;

    const { query } = searchState;
    const isLargeDesktop = breakpointsState.largeDesktop.isBiggerOrEq;
    const { getTranslation } = languagesState;

    const isSearchResultsOpened = query.length > 2;
    const isSearchBarDesktopOpened = searchState.isShow;
    const isMobileOpen = starRouter.accountParam === 'navigation';

    return (
        <>
            {isLargeDesktop === true && isSearchBarDesktopOpened ? (
                <LinkOverlay
                    isSearchBarDesktopOpened={isSearchBarDesktopOpened}
                    onClick={searchState.handleCloseAndRedirect}
                />
            ) : isSearchResultsOpened ? (
                <LinkOverlay
                    isSearchResultsOpened={isSearchResultsOpened}
                    onClick={searchState.handleCloseAndRedirect}
                    isMobileOpen={isMobileOpen}
                />
            ) : null}
            <SearchWrapper key='search'>
                <InputInnerWrapper isSearchBarDesktopOpened={isSearchBarDesktopOpened}>
                    {isLargeDesktop === true && isSearchBarDesktopOpened ? (
                        <SidebarSlideIconWrapper
                            position='left'
                            onClick={searchState.handleCloseAndRedirect}
                        />
                    ) : null}

                    <SearchInput
                        isSearchBarDesktopOpened={isSearchBarDesktopOpened}
                        layout={layout}
                        onChange={searchState.onInputChange}
                        onClick={searchState.handleOpen}
                        onKeyDown={searchState.handleMove}
                        placeholder={getTranslation('events.search.placeholder', 'Search sports')}
                        type='search'
                        value={query}
                    />

                    {query.length < 1 ? (
                        <SearchIconWrapper isSearchBarDesktopOpened={isSearchBarDesktopOpened} />
                    ) : null}

                    {query.length > 0 ? (
                        <CloseIconWrapper
                            isSearchBarDesktopOpened={isSearchBarDesktopOpened}
                            onClick={searchState.clearQuery}
                        />
                    ) : null}
                </InputInnerWrapper>

                {isSearchResultsOpened ? (
                    <SearchResultsWrapper isSearchResultsOpened={isSearchResultsOpened}>
                        <SearchResultsHeader>
                            <I18n
                                langKey='events.search.header'
                                defaultText='Events Found'
                            />
                        </SearchResultsHeader>

                        <SearchEventsResult searchState={searchState} />
                    </SearchResultsWrapper>
                ) : null}
            </SearchWrapper>
        </>
    );
});
