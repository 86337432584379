import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { BlockingKycDepositRestrictionNotification } from './BlockingKycDepositRestrictionNotification';
import { BlockingLicenceNotification } from './BlockingLicenceNotification';
import { useAppStateContext } from 'src/appState/AppState';
import { BlockingNotificationType } from 'src_server/trpc/types/accountModel/accountResponse';
import { BlockingTermsNotification } from './BlockingTermsNotification';

interface BlockingNotificationsPropsType {
    blockingNotificationType: BlockingNotificationType;
}

export const BlockingNotifications = observer(
    'BlockingNotifications',
    ({ blockingNotificationType }: BlockingNotificationsPropsType): JSX.Element => {
        const {
            appPlayersState: { blockingNotificationsState },
        } = useAppStateContext();

        switch (blockingNotificationType) {
            case BlockingNotificationType.changeOfLicense:
                return (
                    <BlockingLicenceNotification
                        onConfirm={blockingNotificationsState.handleConfirmNewLicenceNotification}
                    />
                );
            case BlockingNotificationType.termsUpdate:
                return <BlockingTermsNotification onConfirm={blockingNotificationsState.handleConfirmNewTerms} />;
            case BlockingNotificationType.kycDepositRestriction:
                return (
                    <BlockingKycDepositRestrictionNotification
                        onConfirm={blockingNotificationsState.handleConfirmKycDepositRestrictionNotification}
                    />
                );
        }
    }
);
