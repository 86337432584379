import { MarketModel } from './MarketModel';
import { ModelBoxContext } from 'src_common/common/websocket2/common/ModelBoxContext';
import { LazyComputed, compareArrays } from 'src_common/common/websocket2/common/LazyComputed';
import {
    EventModelType as EventModelType,
    GradeItemType,
    ParticipantModelType,
    StreamType,
} from 'src_common/common/websocket2/modelsApi/Event';
import { parseScore, SingleScoreType } from 'src_common/common/websocket2/models/parseScore';
import { Value } from 'src_common/common/mobx-utils/Value';
import { EventMarketItemType } from 'src_common/common/websocket2/modelsApi/EventMarkets';
import { EventListGroupEventItemType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { TypeId } from 'src_common/common/websocket2/type';
import * as t from 'io-ts';
import { createGuard } from 'src_common/common/createGuard';
import { assertNever } from 'src_common/common/assertNever';
import { CompetitionId, EventId, MarketId } from 'src_common/common/websocket2/id/WebsocketId';
import { DateTime } from 'src_common/utils/time/time';

const TimelineIO = t.union([t.literal('prematch'), t.literal('show'), t.literal('started'), t.literal('finished')]);

const isTimeline = createGuard(TimelineIO);

export type TimelineType = t.TypeOf<typeof TimelineIO>;

export const convertTimeSettingsTimeline = (timelineIn: string | null | undefined): TimelineType => {
    const timeline = timelineIn?.toLocaleLowerCase();

    if (isTimeline(timeline)) {
        return timeline;
    }

    console.error('event.timeSettingsTimeline: Invalid event status', timeline);

    return 'prematch';
};

// const ScoreValueIO = t.interface({
//     home: t.union([t.number, t.string]),
//     away: t.union([t.number, t.string]),
// });

// export type ScoreValueType = t.TypeOf<typeof ScoreValueIO>;

export type ScoreValueType = {
    home: number | string;
    away: number | string;
};

//get score(): Array<{ home: number, away: number}>,

// const ScoreIO = t.union([
//     t.interface({
//         formatted: t.string, // to remove
//         value: t.array(ScoreValueIO),
//         set: t.union([t.string, t.undefined])
//     }),
//     t.null
// ]);

export enum MARKET_TYPE {
    RACE_WINNER = 'race-winner',
    FORECAST = 'forecast-tricast',
    EXTRA_PLACES = 'each-way-extra', //TODO: replace the name with extra-places when its changed on the backend side
    MATCH_BET = 'match-bet',
    SUB_RACE = 'sub-race',
    WITHOUT = 'betting-without',
    WIN_ONLY = 'win-only',
    LENGTHEN_THE_ODDS = 'lengthen-the-odds',
}

function filterMarketsByTemplateId(
    eventMarkets: Array<EventMarketItemType>,
    type: MARKET_TYPE,
    getMarketCallback: (id: TypeId.MarketId) => MarketModel | null
): Array<MarketModel> {
    const marketsOutput: Array<MarketModel> = [];
    for (const market of eventMarkets) {
        if (market.templateId.includes(type)) {
            const fullMarketModel = getMarketCallback(market.id);
            if (fullMarketModel !== null) {
                marketsOutput.push(fullMarketModel);
            }
        }
    }
    return marketsOutput;
}

function filterMarketsByTemplateName(
    eventMarkets: Array<EventMarketItemType>,
    templateName: string,
    getMarketCallback: (id: TypeId.MarketId) => MarketModel | null
): Array<MarketModel> {
    const marketsOutput: Array<MarketModel> = [];
    for (const market of eventMarkets) {
        if (market.templateName.includes(templateName)) {
            const fullMarketModel = getMarketCallback(market.id);
            if (fullMarketModel !== null) {
                marketsOutput.push(fullMarketModel);
            }
        }
    }
    return marketsOutput;
}

export type ScoreType = {
    formatted: string;
    value: Array<ScoreValueType>;
    set?: string;
};

export class EventModel {
    private readonly modelBoxContext: ModelBoxContext;

    private readonly model: Value<EventModelType>;

    // private readonly streamResource: Resource<StreamModelType | null>;

    //TODO - to remove in near future
    private get data(): EventModelType {
        return this.model.getValue();
    }

    private computedMarketsId: LazyComputed<Array<TypeId.MarketId>>;
    private computedMarkets: LazyComputed<Array<MarketModel>>;
    private computedMarketsAll: LazyComputed<Array<MarketModel>>;
    private computedMarketsOpen: LazyComputed<Array<MarketModel>>;
    private computedAllMarketsLoading: LazyComputed<boolean>;
    private computedMarketFilterByGoalScorer: LazyComputed<Array<MarketModel>>;
    private computedSortedMarketsIds: LazyComputed<Array<TypeId.MarketId>>;
    private computedTimeSettingsStartTimeUnixMs: LazyComputed<number>;

    private computedTimeSearchFrom: LazyComputed<boolean>;
    private computedTimeMatchInPlay: LazyComputed<boolean>;
    private computedTimeMatchStartedAndFinished: LazyComputed<boolean>;
    private computedTimeMatchToday: LazyComputed<boolean>;
    private computedTimeMatchTomorrow: LazyComputed<boolean>;
    private computedTimeMatchWeekend: LazyComputed<boolean>;
    private computedTimeMatchCurrentWeek: LazyComputed<boolean>;
    private computedTimeMatchNextWeek: LazyComputed<boolean>;
    private computedTimeMatchNextOff: LazyComputed<boolean>;
    private computedTimeMatchUpcoming: LazyComputed<boolean>;

    public constructor(modelBoxContext: ModelBoxContext, model: Value<EventModelType>) {
        this.modelBoxContext = modelBoxContext;
        this.model = model;

        this.computedMarketsId = new LazyComputed<Array<TypeId.MarketId>>((): Array<TypeId.MarketId> => {
            const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];
            return eventMarkets.map((item) => item.id);
        });

        this.computedMarkets = new LazyComputed<Array<MarketModel>>((): Array<MarketModel> => {
            const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];

            const out: Array<MarketModel> = [];

            for (const eventMarketItem of eventMarkets) {
                const item = this.modelBoxContext.getMarket(eventMarketItem.id);
                if (item !== null) {
                    out.push(item);
                }
            }

            return out;
        }, compareArrays);

        this.computedMarketsAll = new LazyComputed<Array<MarketModel>>((): Array<MarketModel> => {
            const eventMarkets = this.modelBoxContext.getEventMarketsAll(this.id) ?? [];

            const out: Array<MarketModel> = [];

            for (const eventMarketItem of eventMarkets) {
                const item = this.modelBoxContext.getMarket(eventMarketItem.id);
                if (item !== null) {
                    out.push(item);
                }
            }

            return out;
        }, compareArrays);

        this.computedMarketsOpen = new LazyComputed<Array<MarketModel>>((): Array<MarketModel> => {
            const eventMarkets = this.modelBoxContext.getEventMarketsAll(this.id) ?? [];

            const out: Array<MarketModel> = [];

            for (const eventMarketItem of eventMarkets) {
                if (eventMarketItem.state === 'open') {
                    const item = this.modelBoxContext.getMarket(eventMarketItem.id);
                    if (item !== null) {
                        out.push(item);
                    }
                }
            }

            return out;
        }, compareArrays);

        this.computedAllMarketsLoading = new LazyComputed((): boolean => {
            const eventMarkets = this.modelBoxContext.getEventMarkets(this.id);

            if (eventMarkets === null) {
                return true;
            }

            for (const eventMarketItem of eventMarkets) {
                const item = this.modelBoxContext.getMarket(eventMarketItem.id);
                if (item === null) {
                    return true;
                }
            }

            return false;
        });

        this.computedMarketFilterByGoalScorer = new LazyComputed<Array<MarketModel>>((): Array<MarketModel> => {
            return this.markets.filter((market) => {
                return market.isGoalscorer;
            });
        }, compareArrays);

        this.computedSortedMarketsIds = new LazyComputed<Array<TypeId.MarketId>>((): Array<TypeId.MarketId> => {
            const out: Array<TypeId.MarketId> = [];

            const sortedMarkets = [...this.markets].sort((a: MarketModel, b: MarketModel) => {
                const difference = a.displayOrder - b.displayOrder;
                if (difference !== 0) {
                    return difference;
                }
                return a.name.localeCompare(b.name);
            });

            for (const market of sortedMarkets) {
                const item = market.id2.getModel();
                if (item !== null) {
                    out.push(item.id);
                }
            }

            return out;
        });

        this.computedTimeSettingsStartTimeUnixMs = LazyComputed.create(() => {
            const time = DateTime.from(this.timeSettingsStartTime);
            if (time === null) {
                console.error(
                    'this.timeSettingsStartTime is incorrect value, this.timeSettingsStartTime: ',
                    this.timeSettingsStartTime
                );
                return DateTime.current().utc().unixMs();
            }
            return time.utc().unixMs();
        });

        this.computedTimeSearchFrom = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.searchFrom() <= this.timeSettingsStartTimeUnixMs;
        });

        this.computedTimeMatchInPlay = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.inPlay.matchMs(this.timeSettingsStartTimeUnixMs);
        });

        this.computedTimeMatchStartedAndFinished = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.startedAndFinished.matchMs(this.timeSettingsStartTimeUnixMs);
        });

        this.computedTimeMatchToday = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.today.matchMs(this.timeSettingsStartTimeUnixMs);
        });

        this.computedTimeMatchTomorrow = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.tomorrow.matchMs(this.timeSettingsStartTimeUnixMs);
        });

        this.computedTimeMatchWeekend = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.weekend.matchMs(this.timeSettingsStartTimeUnixMs);
        });

        this.computedTimeMatchCurrentWeek = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.currentWeek.matchMs(this.timeSettingsStartTimeUnixMs);
        });

        this.computedTimeMatchNextWeek = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.nextWeek.matchMs(this.timeSettingsStartTimeUnixMs);
        });

        this.computedTimeMatchNextOff = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.nextOff.matchMs(this.timeSettingsStartTimeUnixMs);
        });

        this.computedTimeMatchUpcoming = LazyComputed.create(() => {
            return this.modelBoxContext.serverTime.upcoming.matchMs(this.timeSettingsStartTimeUnixMs);
        });
    }

    public get streamList(): Array<StreamType> {
        const stream = this.data.stream;

        if (stream === undefined || stream === null) {
            return [];
        }
        return stream;
    }

    public getData(): EventModelType {
        return this.model.getValue();
    }

    public getRawData(): EventModelType {
        return this.getData();
    }

    /**
     * @deprecated - please use id2
     */
    public get id(): number {
        return this.getData().id;
    }

    public get id2(): EventId {
        return this.modelBoxContext.websocketId().getEventId(this.getData().id);
    }

    public get uuid(): string | null {
        return this.getData().uuid ?? null;
    }

    public get name(): string {
        return this.getData().name;
    }

    public get rawGameState(): string | null | undefined {
        return this.getData().rawGameState;
    }

    /**
     * @deprecated - please use competition2
     */
    public get competition(): number {
        return this.getData().competition;
    }

    public get competition2(): CompetitionId {
        return this.modelBoxContext.websocketId().getCompetitionId(this.getData().competition);
    }

    public get sport(): string {
        return this.getData().sport;
    }

    public get sportOriginal(): string {
        return this.getData().sportOriginal;
    }

    public get template(): string {
        return this.getData().template;
    }

    public getTag(name: string): string | undefined {
        return this.getData().tags[name];
    }

    public get markets(): Array<MarketModel> {
        return this.computedMarkets.get();
    }

    public get marketsAll(): Array<MarketModel> {
        return this.computedMarketsAll.get();
    }

    public get marketsOpen(): Array<MarketModel> {
        return this.computedMarketsOpen.get();
    }

    public get eventMarkets(): Array<EventMarketItemType> | null {
        return this.modelBoxContext.getEventMarkets(this.id);
    }

    public get marketRaceWinnerAll(): Array<MarketModel> {
        const eventMarkets = this.modelBoxContext.getEventMarketsAll(this.id) ?? [];
        return filterMarketsByTemplateId(eventMarkets, MARKET_TYPE.RACE_WINNER, this.modelBoxContext.getMarket);
    }

    public get marketRaceWinner(): Array<MarketModel> {
        const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];
        return filterMarketsByTemplateId(eventMarkets, MARKET_TYPE.RACE_WINNER, this.modelBoxContext.getMarket);
    }

    public get marketExtraPlaces(): Array<MarketModel> {
        const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];
        return filterMarketsByTemplateId(eventMarkets, MARKET_TYPE.EXTRA_PLACES, this.modelBoxContext.getMarket);
    }

    public get marketWinOnly(): MarketModel | null {
        const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];
        const market = filterMarketsByTemplateId(eventMarkets, MARKET_TYPE.WIN_ONLY, this.modelBoxContext.getMarket)[0];
        return market === undefined ? null : market;
    }

    public get marketMatchBet(): Array<MarketModel> {
        const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];
        return filterMarketsByTemplateId(eventMarkets, MARKET_TYPE.MATCH_BET, this.modelBoxContext.getMarket);
    }

    public get marketSubRace(): Array<MarketModel> {
        const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];
        return filterMarketsByTemplateId(eventMarkets, MARKET_TYPE.SUB_RACE, this.modelBoxContext.getMarket);
    }

    public get marketWithout(): Array<MarketModel> {
        const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];
        return filterMarketsByTemplateId(eventMarkets, MARKET_TYPE.WITHOUT, this.modelBoxContext.getMarket);
    }

    public get marketTrapChallenge(): Array<MarketModel> {
        const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];
        return filterMarketsByTemplateName(eventMarkets, 'Trap Challenge', this.modelBoxContext.getMarket);
    }

    public get marketLengthenTheOdds(): Array<MarketModel> {
        const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];
        return filterMarketsByTemplateId(eventMarkets, MARKET_TYPE.LENGTHEN_THE_ODDS, this.modelBoxContext.getMarket);
    }

    public get allMarketsForEventPage(): Array<MarketModel> {
        return this.computedMarkets.get();
    }

    public get allMarketsLoading(): boolean {
        return this.computedAllMarketsLoading.get();
    }

    public get marketsWithWebsiteMain(): Array<EventMarketItemType> {
        if (!this.display) {
            return [];
        }

        const eventMarkets = this.modelBoxContext.getEventMarkets(this.id) ?? [];

        return eventMarkets.filter((item) => {
            return item.tags['website-main'] === 'yes';
        });
    }

    /**
     * @deprecated - please use sortedMarketsIds2
     */
    public get sortedMarketsIds(): Array<TypeId.MarketId> {
        return this.computedSortedMarketsIds.get();
    }

    public get sortedMarketsIds2(): Array<MarketId> {
        return this.computedSortedMarketsIds.get().map((marketId) => {
            return this.id2.getMarketId(TypeId.getMarketId(marketId));
        });
    }

    /**
     * @deprecated - please use marketsIds2
     */
    public get marketsIds(): TypeId.MarketId[] {
        return this.computedMarketsId.get();
    }

    public get marketsIds2(): Array<MarketId> {
        return this.computedMarketsId.get().map((marketId) => {
            return this.id2.getMarketId(TypeId.getMarketId(marketId));
        });
    }

    public get participants(): Record<string, ParticipantModelType> {
        return this.getData().participants;
    }

    public get tagsTelebetTopEvents(): string | undefined {
        return this.getTag('telebet-top-events');
    }

    public get tagsRegion(): string | undefined {
        return this.getTag('region');
    }

    public get tagsCountry(): string | undefined {
        return this.getTag('country');
    }

    public get tagsOutright(): string | undefined {
        return this.getTag('outright');
    }

    public get tagsDisplayOrder(): string | undefined {
        return this.getTag('display-order');
    }

    public get tagsFootballSpecial(): string | undefined {
        return this.getTag('specials');
    }

    private getFromFeedData(
        key: 'courseType' | 'distance' | 'feedId' | 'going' | 'groupId' | 'handicap' | 'raceComment' | 'surface'
    ): string | undefined {
        const feedData = this.getData().feedData;

        if (feedData !== undefined) {
            const value = feedData[key];
            return value ?? undefined;
        }
    }

    public get feedCourseType(): string | undefined {
        return this.getFromFeedData('courseType');
    }

    public get feedDistance(): string | undefined {
        return this.getFromFeedData('distance');
    }

    private get feedId(): string | undefined | null {
        return this.getFromFeedData('feedId');
    }

    public get feedDataGoing(): string | undefined {
        return this.getFromFeedData('going');
    }

    public get feedGroupId(): string | undefined | null {
        return this.getFromFeedData('groupId');
    }

    public get feedHandicap(): string | undefined {
        return this.getFromFeedData('handicap');
    }

    public get feedRaceComment(): string | undefined {
        return this.getFromFeedData('raceComment');
    }

    public get feedSurface(): string | undefined {
        return this.getFromFeedData('surface');
    }

    public get rabId(): string | null {
        const feedId = this.feedId ?? null;

        if (feedId === null) {
            return null;
        }

        return `01_${feedId}`;
    }

    public get feedSettingsUpdates(): boolean | null | undefined {
        return this.getData().feedSettings?.updates;
    }

    public get feedSettingsResults(): boolean | null | undefined {
        return this.getData().feedSettings?.results;
    }

    public get feedSettingsEachWay(): boolean | null | undefined {
        return this.getData().feedSettings?.eachWay;
    }

    public get feedSettingsPrices(): boolean | null | undefined {
        return this.getData().feedSettings?.prices;
    }

    public get timeSettingsStartTime(): string {
        return this.getData().timeSettings.startTime;
    }

    public get timeSettingsStarted(): boolean {
        const timeSettingsTimeline = this.timeSettingsTimeline;
        switch (timeSettingsTimeline) {
            case 'prematch':
            case 'show':
                return false;
            case 'started':
            case 'finished':
                return true;
            default:
                return assertNever('timeSettingsStarted', timeSettingsTimeline);
        }
    }

    public get timeSettingsTradedInPlay(): boolean {
        return this.getData().timeSettings.tradedInPlay;
    }

    public get timeSettingsTimeZone(): string {
        return this.getData().timeSettings.timeZone;
    }

    public get timeSettingsTimeline(): TimelineType {
        return convertTimeSettingsTimeline(this.getData().timeSettings.timeline);
    }

    public get timeSettingsOffAtStartTime(): boolean | undefined | null {
        return this.getData().timeSettings.offAtStartTime;
    }

    public get gradesInPlay(): GradeItemType {
        return this.getData().grades?.inPlay;
    }

    public get gradesPrematch(): GradeItemType {
        return this.getData().grades?.prematch;
    }

    public get gradesShow(): GradeItemType {
        return this.getData().grades?.show;
    }

    public get autoTakeDown(): boolean | undefined | null {
        return this.getData().autoTakeDown;
    }

    public get score(): Array<SingleScoreType> {
        return parseScore(this.getData().statistics);
    }

    public get scoreHome(): number | null {
        const score = this.score[0];

        if (score !== undefined) {
            return score.home;
        }

        return null;
    }

    public get scoreAway(): number | null {
        const score = this.score[0];

        if (score !== undefined) {
            return score.away;
        }

        return null;
    }

    public get scoreFormatted(): string | null {
        const scoreHome = this.scoreHome;
        const scoreAway = this.scoreAway;

        if (scoreHome !== null && scoreAway !== null) {
            return `${scoreHome}-${scoreAway}`;
        }

        return null;
    }

    public get marketFilterByGoalScorer(): Array<MarketModel> {
        return this.computedMarketFilterByGoalScorer.get();
    }

    // Now we don't receive information about sets
    // public get scoreSet(): string | undefined {
    //     const score = this.score;

    //     if (score !== null) {
    //         return score.set;
    //     }

    //     return undefined;
    // }

    public get active(): boolean {
        return this.getData().active;
    }

    public get display(): boolean {
        return this.getData().display;
    }

    public get state(): string {
        return this.getData().state;
    }

    public get eventType(): string | null {
        return this.getData().eventType ?? null;
    }

    public get homeParticipant(): string | null {
        for (const participant of Object.values(this.participants)) {
            if (participant.role === 'home') {
                return participant.name === undefined ? null : participant.name;
            }
        }
        return null;
    }

    public get awayParticipant(): string | null {
        for (const participant of Object.values(this.participants)) {
            if (participant.role === 'away') {
                return participant.name === undefined ? null : participant.name;
            }
        }
        return null;
    }

    public get antePost(): boolean {
        return this.getData().antePost;
    }

    public get timeSettingsStartTimeUnixMs(): number {
        return this.computedTimeSettingsStartTimeUnixMs.get();
    }

    public get timeSearchFrom(): boolean {
        return this.computedTimeSearchFrom.get();
    }

    public get timeMatchInPlay(): boolean {
        return this.computedTimeMatchInPlay.get();
    }

    public get timeMatchStartedAndFinished(): boolean {
        return this.computedTimeMatchStartedAndFinished.get();
    }

    public get timeMatchToday(): boolean {
        return this.computedTimeMatchToday.get();
    }

    public get timeMatchTomorrow(): boolean {
        return this.computedTimeMatchTomorrow.get();
    }

    public get timeMatchWeekend(): boolean {
        return this.computedTimeMatchWeekend.get();
    }

    public get timeMatchCurrentWeek(): boolean {
        return this.computedTimeMatchCurrentWeek.get();
    }

    public get timeMatchNextWeek(): boolean {
        return this.computedTimeMatchNextWeek.get();
    }

    public get timeMatchNextOff(): boolean {
        return this.computedTimeMatchNextOff.get();
    }

    public get timeMatchUpcoming(): boolean {
        return this.computedTimeMatchUpcoming.get();
    }

    public get raceHasBp(): boolean {
        for (const market of this.markets) {
            if (market.bp) {
                return true;
            }
        }

        return false;
    }

    public get showCashoutIcon(): boolean {
        return this.timeMatchInPlay || this.timeSettingsTimeline === 'prematch';
    }

    public get lsportExternalId(): string | null {
        /*
        Example for LSport:

        platformObject": {
            "externalId":{
                "feedId": null
                "instance": "star"
                "provider": "lsports"
            }
            "id": "lsports_6292771"
            "name": "Cannon Kingsley vs Garrett Johns"
        }
        */

        const platformObject = this.data.platformObject ?? null;

        if (platformObject === null) {
            return null;
        }

        const platformObjectId = platformObject.id ?? null;

        if (platformObjectId === null) {
            return null;
        }

        const chunks = platformObjectId.split('_');

        if (chunks.length !== 2) {
            return null;
        }

        const prefix = chunks[0];
        const id = chunks[1];

        if (prefix === undefined || id === undefined) {
            return null;
        }

        if (prefix === 'lsports') {
            return id;
        }

        return null;
    }

    public convertToSmallEvent(): EventListGroupEventItemType {
        return {
            id: this.id,
            id2: this.id2,
            name: this.name,
            state: this.state,
            template: this.template,
            antePost: this.antePost,
            startTime: this.computedTimeSettingsStartTimeUnixMs.get(),
            timeline: this.timeSettingsTimeline,
            tags: this.getData().tags,
        };
    }
}
