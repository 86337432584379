import styled from '@emotion/styled';
import { PriceBoostIcon } from 'src/domains/layouts/shared/assets/icons/PriceBoostIcon';
import { ThumbsIcon } from 'src/domains/layouts/shared/assets/icons/ThumbsIcon';
import { withConfig } from 'src/withConfig';

export const UnnamedFavouriteLabel = styled('span', { label: 'UnnamedFavouriteLabel' })`
    align-items: center;
    border-right: none;
    display: flex;
    flex: 1 1 0%;
    justify-content: flex-start;
    padding: 1px 8px;
`;

export const SelectionRunner = withConfig(theme => styled('span', { label: 'SelectionRunner' })`
    align-items: flex-start;
    border-right: 1px solid ${theme.star.raceSummary.borderColor};
    color: ${theme.star.raceSummary.txtColor};
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    font-size: ${theme.star.fontSize.xSmall};
    justify-content: center;
    padding-left: 8px;
`);

export const RaceSummaryHeader = withConfig(theme => styled('div', { label: 'RaceSummaryHeader' })`
    border: 1px solid ${theme.star.raceSummary.borderColor};
    display: flex;
    justify-content: space-between;
    margin-bottom: -1px;
    padding: 10px 5px;
`);

export const TrapListItem = withConfig(theme => styled('li', { label: 'TrapListItem' })`
    align-self: center;
    background-color: ${theme.star.eventRow.bgColorQuaternary};
    box-sizing: content-box;
    display: flex;
    font-weight: ${theme.star.fontWeight.medium};
    height: 40px;
    position: relative;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    width: 71px;
    @media ${theme.star.mediaQuery.tablet} {
        box-sizing: border-box;
        width: 68px;
    }
    a {
        width: 100%;
        flex: 0 0 100%;
    }
`);

export const Tags = withConfig(theme => styled('div', { label: 'Tags' })`
    color: ${theme.star.priceBoost.txtColor};
    display: flex;
    filter: drop-shadow(0 3px 10px ${theme.star.priceBoost.bgColorTertiary});
    font-weight: ${theme.star.fontWeight.bold};
    left: 4px;
    line-height: 2;
    margin-top: 2px;
    position: absolute;
    right: 4px;
    text-transform: uppercase;
    top: 100%;
    z-index: ${theme.star.zIndexGlobal.above};
`);

interface TagsAlternativePropsType {
    isPriceBoost?: boolean;
}

export const TagsAlternative = withConfig(theme => styled('div', { label: 'TagsAlternative' })<TagsAlternativePropsType>`
    align-items: center;
    background: ${({ isPriceBoost }): string => isPriceBoost === true ? theme.star.priceBoost.bgColorSecondary : theme.star.priceBoost.bgColor};
    color: ${theme.star.priceBoost.txtColor};
    display: flex;
    font-size: ${theme.star.fontSize.xSmall};
    height: 18px;
    left: -1px;
    line-height: 1.4;
    padding: 2px 8px;
    position: absolute;
    right: -1px;
    top: 100%;
    z-index: ${theme.star.zIndexGlobal.above};
    &::before {
        border-color: transparent transparent ${({ isPriceBoost }): string => isPriceBoost === true ? theme.star.priceBoost.bgColorSecondary : theme.star.priceBoost.bgColor} transparent;
        border-style: solid;
        border-width: 0 4px 4px 4px;
        bottom: 100%;
        content: '';
        height: 0;
        left: 12px;
        position: absolute;
        width: 0;
    }
`);

const iconStyled = `
    fill: currentcolor;
    margin-right: 4px;
`;

export const ThumbsUpIconWrapper = styled(ThumbsIcon, { label: 'ThumbsUpIconWrapper' })`
    width: 10px;
    ${iconStyled};
`;

export const BoostIconWrapper = styled(PriceBoostIcon, { label: 'BoostIconWrapper' })`
    ${iconStyled};
    width: 12px;
`;

export const IconTextWrapper = styled('div', { label: 'IconTextWrapper' })`
    align-items: center;
    display: flex;
    margin-right: 8px;
    &:last-of-type {
        margin-right: 0;
    }
`;

export const RaceRunnerInfo = withConfig(theme => styled('div', { label: 'RaceRunnerInfo' })`
    display: flex;
    flex-flow: column nowrap;
    @media ${theme.star.mediaQuery.tablet} {
        flex: 0 0 50%;
    }
`);

export const RaceRunnerName = withConfig(theme => styled('span', { label: 'RaceRunnerName' })`
    color: ${theme.star.raceSummary.txtColor};
    display: block;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
`);

export const RunnerDescription = withConfig(theme => styled('i', { label: 'RunnerDescription' })`
    color: ${theme.star.raceSummary.txtColorSecondary};
    display: block;
    font-size: ${theme.star.fontSize.xSmall};
    font-style: normal;
    line-height: 1.4;
`);
