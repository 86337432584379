import styled from '@emotion/styled';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AccountContent } from 'src/domains/players/webview/components/Account/accountParts/Account.style';
import { withConfig } from 'src/withConfig';

export const DepositTabWrapper = withConfig(theme => styled(AccountContent, { label: 'DepositTabWrapper' })<{ isSignup?: boolean }>`
    color: ${({ isSignup }): string => (isSignup === true ? theme.star.signUp.txtColor : theme.star.topUp.txtColor)};
    overflow-y: ${({ isSignup }): string => (isSignup === true ? 'visible' : 'auto')};
    padding: ${({ isSignup }): string => (isSignup === true ? '0 16px 16px 16px' : '16px')};
`);

export const CashBalanceWrapper = withConfig(theme => styled('div', { label: 'CashBalanceWrapper' })`
    color: currentcolor;
    display: flex;
    font-size: ${theme.star.fontSize.small};
    justify-content: space-between;
    align-items: center;
`);

export const CashBalance = withConfig(theme => styled('span', { label: 'CashBalance' })`
    font-weight: ${theme.star.fontWeight.bold};
    font-size: ${theme.star.fontSize.xRegular};
`);

export const CardInfo = withConfig(theme => styled('p', { label: 'CardInfo' })`
    color: currentcolor;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 8px 0 22px;
    text-align: center;
`);

export const DepositInputLabel = withConfig(theme => styled(I18n, { label: 'DepositInputLabel' })`
    font-weight: ${theme.star.fontWeight.bold};
`);

export const DepositContainer = styled('div', { label: 'DepositContainer' })`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

