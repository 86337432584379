import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { BogIndicatorRaceWrapper } from 'src/domains/sportsbook/webview/components/bogIndicator/BogIndicatorRace';
import { RaceSummaryLoader } from 'src/domains/sportsbook/webview/components/raceCard/raceCardLoader/RaceSummaryLoader';
import { calculateRaceStatus } from 'src/domains/sportsbook/utils/filterRacesWithFinishState';
import { RaceResults } from 'src/domains/sportsbook/webview/components/raceCard/raceResults/RaceResults';
import { PriceChangeLegend } from 'src/domains/layouts/webview/components/priceChangeLegend/PriceChangeLegend';
import { WeatherState } from './weatherDetails/WeatherDetailsState';
import { RaceSummary } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummary';
import { RaceSummaryCast } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryCast';
import { RaceSummaryMatchBets } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryMatchBets';
import { ExtraPlacesSummary } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/ExtraPlacesSummary';
import { RaceSummarySubRaces } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummarySubRaces';
import { RaceSummaryBettingWithout } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryBettingWithout';
import { HorseRacingSortOrderType } from 'src/domains/sportsbook/webview/components/raceCard/RaceCompetition.state';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { MobxMapAutoNew } from 'src_common/common/mobx-utils/MobxMapAutoNew';
import { CompetitionsTrapChallengeGroup } from 'src/domains/sportsbook/webview/components/containers/competitionsTrapChallenge/CompetitionsTrapChallengeGroup';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ExtraPlacesSummaryNew } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/ExtraPlacesSummaryNew';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { RaceResultsNew } from 'src/domains/sportsbook/webview/components/raceCard/raceResults/RaceResultsNew';
import { TabKeyType } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceDetails.types';
import { RaceDetailsState } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceDetails.state';
import { TableMessageOrFilters } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/TableMessageOrFilters';
import { RaceCardHeader } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceCardHeader';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LengthenTheOddsSummary } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummarySport/lengthenTheOddsSummary/LengthenTheOddsSummary';

export interface TabItemRawType {
    key: TabKeyType;
    label: string;
    content: React.ReactElement;
    onClick: () => void;
    showBogForMarket: boolean;
}

const getFilters = (
    state: RaceDetailsState,
    race: EventModel,
    sortOrder: HorseRacingSortOrderType,
    raceSummaryRowHorseRacingNew: boolean //TODO: temporary props -> flag to show new race summary component
): Array<TabItemRawType> => {
    const markets: Array<TabItemRawType> = [];

    const { getTranslation } = state.language;

    for (const market of race.marketRaceWinnerAll) {
        if (!market.display) {
            continue;
        }

        markets.push({
            key: 'Win or Ew',
            label: raceSummaryRowHorseRacingNew
                ? getTranslation('events.racecard.win-or-ew.label:new-racecard', 'Win / Eachway')
                : getTranslation('events.racecard.win-or-ew.label', 'Win / Ew'),
            content: (
                <RaceSummary
                    sortOrder={sortOrder}
                    key='Win / Ew'
                    marketId={market.id2}
                />
            ),
            onClick: () => {
                state.setActiveFilterId('Win or Ew');
            },
            showBogForMarket: true,
        });

        if (market.forecastsOffered) {
            markets.push({
                key: 'forecast-tricast',
                label: getTranslation('events.racecard.forecast-tricast.label', 'Forecast / Tricast'),
                content: (
                    <RaceSummaryCast
                        sortOrder={sortOrder}
                        key='forecast-tricast'
                        marketId={market.id2}
                    />
                ),
                onClick: () => {
                    state.setActiveFilterId('forecast-tricast');
                },
                showBogForMarket: true,
            });
        }
    }

    if (race.marketWinOnly !== null) {
        markets.push({
            key: 'win-only',
            label: getTranslation('events.racecard.win-only.label', 'Win Only'),
            content: (
                <RaceSummary
                    sortOrder={sortOrder}
                    key='win-only'
                    marketId={race.marketWinOnly.id2}
                />
            ),
            onClick: () => {
                state.setActiveFilterId('win-only');
            },
            showBogForMarket: false,
        });
    }

    if (race.marketExtraPlaces.length > 0 && race.marketExtraPlaces[0] !== undefined) {
        markets.push({
            key: 'extra-places',
            label: getTranslation('events.racecard.extra-places.label', 'Extra Places'),
            content: raceSummaryRowHorseRacingNew ? (
                <ExtraPlacesSummaryNew
                    key='extra-places'
                    race={race}
                />
            ) : (
                <ExtraPlacesSummary
                    key='extra-places'
                    race={race}
                />
            ),
            onClick: () => {
                state.setActiveFilterId('extra-places');
            },
            showBogForMarket: false,
        });
    }

    if (race.marketWithout.length > 0) {
        markets.push({
            key: 'betting-without',
            label: getTranslation('events.racecard.betting-without.label', 'Betting Without'),
            content: (
                <RaceSummaryBettingWithout
                    key='betting-without'
                    eventId={race.id2}
                />
            ),
            onClick: () => {
                state.setActiveFilterId('betting-without');
            },
            showBogForMarket: false,
        });
    }

    if (race.marketMatchBet.length > 0 && race.marketMatchBet[0] !== undefined) {
        markets.push({
            key: 'match-bets',
            label: getTranslation('events.racecard.match-bets.label', 'Match Bets'),
            content: (
                <RaceSummaryMatchBets
                    key='match-bets'
                    eventId={race.id2}
                />
            ),
            onClick: () => {
                state.setActiveFilterId('match-bets');
            },
            showBogForMarket: false,
        });
    }

    if (race.marketSubRace.length > 0) {
        markets.push({
            key: 'sub-race',
            label: getTranslation('events.racecard.sub-race.label', 'Sub Races'),
            content: (
                <RaceSummarySubRaces
                    key='sub-races'
                    eventId={race.id2}
                />
            ),
            onClick: () => {
                state.setActiveFilterId('sub-race');
            },
            showBogForMarket: false,
        });
    }

    if (race.marketTrapChallenge.length > 0) {
        markets.push({
            key: 'trap-challenge',
            label: getTranslation('events.racecard.trap-challenge.label', 'Trap Challenge'),
            content: <CompetitionsTrapChallengeGroup events={[race]} />,
            onClick: () => {
                state.setActiveFilterId('trap-challenge');
            },
            showBogForMarket: false,
        });
    }

    if (race.marketLengthenTheOdds.length > 0) {
        markets.push({
            key: 'lengthen-the-odds',
            label: getTranslation('events.racecard.lengthen-the-odds.label', 'Lengthen The Odds'),
            content: (
                <LengthenTheOddsSummary
                    key='lengthen-the-odds'
                    race={race}
                />
            ),
            onClick: () => {
                state.setActiveFilterId('lengthen-the-odds');
            },
            showBogForMarket: false,
        });
    }

    return markets;
};

const getActiveFilterContent = (state: RaceDetailsState, filters: Array<TabItemRawType>): TabItemRawType | null => {
    const active = state.activeFilterId;

    for (const item of filters) {
        if (item.key === active) {
            return item;
        }
    }

    const firstItem = filters[0];
    if (firstItem !== undefined) {
        return firstItem;
    }

    return null;
};

interface RaceDetailsPropsType {
    eventId: EventId;
    sport?: 'horseracing' | 'greyhoundracing';
    sortOrder: HorseRacingSortOrderType;
    setSortOrder: (sort: HorseRacingSortOrderType) => void;
}

export const RaceDetails = observer('RaceDetails', (propsIn: RaceDetailsPropsType) => {
    const { appSportsBookState } = useAppStateContext();
    const common = useCommon();
    const languagesState = LanguagesState.get(common);
    const { session } = common;
    const { basicData } = BasicDataModel.get(common);
    const { config } = ConfigComponents.get(common);
    const { showHorseRacingSortDropdown, raceSummaryRowHorseRacingNew } = config;
    const { eventId, sport, sortOrder, setSortOrder } = propsIn;

    const [weatherStateFactory] = React.useState(
        () =>
            new MobxMapAutoNew<EventId, WeatherState>((eventId) => {
                return new WeatherState(session, eventId);
            })
    );
    const weatherState = weatherStateFactory.get(eventId);

    const race = eventId.getEventModel();
    const shouldShowHorseRacingDropdown = showHorseRacingSortDropdown === true && sport === 'horseracing';

    const [strategy] = useState(new RaceDetailsState(languagesState));

    if (race === null) {
        return null;
    }

    const isLoading = race.marketRaceWinnerAll.length === 0 && race.marketTrapChallenge.length === 0;
    if (isLoading) {
        return <RaceSummaryLoader />;
    }

    const filters = getFilters(strategy, race, sortOrder, raceSummaryRowHorseRacingNew);
    const selectFilter = getActiveFilterContent(strategy, filters);

    const bogValue = basicData.valueReady?.bpEnabled ?? false;
    const shouldShowBogForMarket = selectFilter?.showBogForMarket ?? false;

    const bogCard =
        bogValue === true && race.raceHasBp === true && shouldShowBogForMarket ? <BogIndicatorRaceWrapper /> : null;

    const raceStatus = calculateRaceStatus(race.timeSettingsTimeline, race.state);
    const isFinishedRace = raceStatus === 'RaceStatusFinished';
    const isRaceInProgress = raceStatus === 'RaceStatusInProgress';
    const hideWeather = isFinishedRace || config.weatherData === false || weatherState.is_active === false;

    const hasStream = config.hasEventLiveStreaming && appSportsBookState.streamingState.hasStream(race.id);

    if (isFinishedRace && sport !== undefined) {
        return (
            <div key={race.id2.toOldId()}>
                <RaceCardHeader
                    bogCard={bogCard}
                    eventId={eventId}
                    hasStream={hasStream}
                    hideWeather={hideWeather}
                    isFinishedRace={isFinishedRace}
                    isRaceInProgress={isRaceInProgress}
                    race={race}
                    shouldShowBogForMarket={shouldShowBogForMarket}
                    strategy={strategy}
                    weatherState={weatherState}
                />

                {raceSummaryRowHorseRacingNew === true && sport === 'horseracing' ? (
                    <RaceResultsNew
                        race={race}
                        sport={sport}
                    />
                ) : (
                    <RaceResults
                        race={race}
                        sport={sport}
                    />
                )}
            </div>
        );
    }

    return (
        <div key={race.id2.toOldId()}>
            <RaceCardHeader
                bogCard={bogCard}
                eventId={eventId}
                hasStream={hasStream}
                hideWeather={hideWeather}
                isFinishedRace={isFinishedRace}
                isRaceInProgress={isRaceInProgress}
                race={race}
                shouldShowBogForMarket={shouldShowBogForMarket}
                strategy={strategy}
                weatherState={weatherState}
            />

            <TableMessageOrFilters
                isRaceInProgress={isRaceInProgress}
                activeFilterId={selectFilter?.key ?? null}
                filters={filters}
                shouldShowHorseRacingDropdown={shouldShowHorseRacingDropdown}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
            />
            {selectFilter?.content}
            <PriceChangeLegend isRace={true} />
        </div>
    );
});
