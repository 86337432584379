import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'src/utils/mobx-react';

const SvgElement = styled('svg', { label: 'SvgElement' })`
    height: auto;
`;

interface BabIconPropsType {
    className?: string;
    onClick?: () => void;
}

export const BabIcon = observer('BabIcon', ({ className, onClick }: BabIconPropsType): JSX.Element => {
    return (
        <SvgElement
            className={className}
            onClick={onClick}
            clipRule='evenodd'
            fill='#072D4F'
            fillRule='evenodd'
            height='24'
            strokeLinejoin='round'
            strokeMiterlimit='2'
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M2.3961e-05 11.9997C2.39579e-05 12.0351 3.15844e-05 12.0685 0.00221168 12.1042C0.0196924 14.167 0.709275 16.1678 1.96641 17.8034C3.71107 20.079 6.45696 21.5481 9.54767 21.5481C12.7365 21.5481 15.5617 19.983 17.2961 17.5778C15.6932 19.5044 13.2798 20.731 10.5799 20.731C7.75479 20.731 5.24297 19.3874 3.64976 17.3062C2.5022 15.8135 1.87206 13.9876 1.85469 12.1049C1.85276 12.0715 1.85277 12.0358 1.85277 12.0005C1.84906 10.0816 2.48095 8.21558 3.64976 6.69379C5.24418 4.61255 7.75601 3.27086 10.5799 3.27086C13.2798 3.27086 15.6928 4.49746 17.2961 6.42094C15.5619 4.01937 12.7365 2.45402 9.54767 2.45402C6.45696 2.45402 3.71131 3.92099 1.96641 6.19874C0.688468 7.86227 -0.00295323 9.902 2.3961e-05 11.9997Z'
                fill='fill'
            />
            <path
                d='M9.54763 21.9647C13.3374 21.9647 16.6326 19.849 18.3175 16.7343L20.5286 16.7343C18.7662 20.3853 15.028 22.9041 10.7035 22.9041C6.92338 22.9041 3.59108 20.9792 1.63513 18.0561C3.45636 20.432 6.32292 21.9647 9.54763 21.9647Z'
                fill='fill'
            />
            <path
                d='M1.63459 5.94385C3.45679 3.56918 6.32384 2.0363 9.54806 2.0363C13.3366 2.0363 16.6321 4.15098 18.3184 7.26664L20.5297 7.26664C18.7659 3.61595 15.0292 1.09589 10.7041 1.09589C6.92139 1.09589 3.59055 3.02058 1.63459 5.94385Z'
                fill='fill'
            />
            <path
                d='M16.6526 10.6477L16.6526 13.3516L19.0272 13.3516C18.8998 14.2941 18.6373 15.2132 18.2477 16.0808L21.1518 16.0808C21.4524 15.1962 21.6536 14.2808 21.7516 13.3516L24 13.3516L24 10.6477L21.7516 10.6477C21.6538 9.71917 21.4526 8.8045 21.1518 7.92065L18.2492 7.92065C18.6387 8.78749 18.9012 9.70593 19.0287 10.6477L16.6526 10.6477Z'
                fill='fill'
            />
            <path
                d='M6.65234 6.60504L11.8274 6.60504C12.7129 6.60504 13.3998 6.85438 13.8883 7.35306C14.387 7.85175 14.6363 8.51326 14.6363 9.33761C14.6363 9.7447 14.5804 10.0907 14.4684 10.3757C14.3666 10.6606 14.2242 10.8947 14.041 11.0779C13.868 11.2611 13.6593 11.3985 13.4151 11.4901C13.181 11.5715 12.9266 11.6173 12.6518 11.6275L12.6518 11.7191C12.9062 11.7191 13.1708 11.7649 13.4456 11.8565C13.7306 11.9481 13.9901 12.0956 14.2242 12.2992C14.4582 12.4925 14.6516 12.747 14.8043 13.0625C14.9671 13.378 15.0485 13.7647 15.0485 14.2227C15.0485 14.6399 14.9773 15.0368 14.8348 15.4134C14.7025 15.7798 14.5142 16.1003 14.27 16.3751C14.0257 16.6499 13.7357 16.8687 13.3998 17.0316C13.064 17.1842 12.6976 17.2605 12.3007 17.2605L6.65234 17.2605L6.65234 6.60504ZM8.97274 15.3065L11.6442 15.3065C11.9496 15.3065 12.1887 15.2251 12.3617 15.0623C12.5449 14.8893 12.6365 14.6501 12.6365 14.3448L12.6365 13.8257C12.6365 13.5204 12.5449 13.2864 12.3617 13.1235C12.1887 12.9505 11.9496 12.864 11.6442 12.864L8.97274 12.864L8.97274 15.3065ZM8.97274 10.971L11.2779 10.971C11.5832 10.971 11.8223 10.8845 11.9954 10.7115C12.1684 10.5385 12.2549 10.2994 12.2549 9.99404L12.2549 9.53607C12.2549 9.23075 12.1684 8.99159 11.9954 8.81858C11.8223 8.64556 11.5832 8.55906 11.2779 8.55906L8.97274 8.55906L8.97274 10.971Z'
                fill='fill'
            />
        </SvgElement>
    );
});
