import { Resource } from 'src_common/common/mobx-utils/Resource';
import { computed, makeObservable } from 'mobx';
import { withdrawalsList } from 'src_server/trpc/types/wallet';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';

export class WithdrawalsListState {
    public static get = AutoWeakMap.create((common: Common) => new WithdrawalsListState(common));
    private readonly withdrawalsResource: Resource<withdrawalsList.ResponseType>;

    private constructor(private readonly common: Common) {
        makeObservable(this);
        this.withdrawalsResource = new Resource(async (): Promise<withdrawalsList.ResponseType> => {
            return await this.common.trpcClient.client.walletRouter.withdrawalsList.query();
        });
    }

    @computed public get withdrawals(): withdrawalsList.SuccessResponseType | [] {
        const result = this.withdrawalsResource.get();
        if (result.type === 'ready' && result.value.responseStatus === 'success') {
            return result.value.response;
        }
        return [];
    }

    public async refresh(): Promise<void> {
        await this.withdrawalsResource.refresh();
    }
}
