import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const RaceOverviewDataItem = withConfig(theme => styled('span', { label: 'RaceOverviewDataItem' })`
    position: relative;
    &::before {
        content: '|';
        margin: 0 4px;
    }
    @media ${theme.star.mediaQuery.tablet} {
        &::after {
            content: '|';
            margin: 0 4px;
        }
        &::before {
            content: none;
        }
        &:last-of-type {
            &::after {
                content: none;
            } 
        }
    }
`);

interface RaceOverviewDetailsType {
    clickable: boolean;
    /** Please remove when RAS Gold details are available. */
    isRasGoldActiveTemp: boolean;
}

const setClickableElement = ( clickable: boolean, isRasGoldActiveTemp: boolean, theme: EmotionTheme): SerializedStyles => {
    if (isRasGoldActiveTemp) {
        if (clickable) {
            return css`
                color: ${theme.star.raceCardDetails.txtColorSecondary};
                cursor: pointer;
                font-weight: ${theme.star.fontWeight.bold};
                position: relative;
                transition: opacity ease 0.2s;
                &::after {
                    background: ${theme.star.raceCardDetails.txtColorSecondary};
                    content: '';
                    height: 1px;
                    inset: calc(100% - 2px) 0 0 auto;
                    opacity: 1;
                    position: absolute;
                }
                &:hover {
                    &::after {
                        opacity: 0;
                    }
                }
            `;
        }
    }
    return css`
        color: ${theme.star.raceSummary.txtColorSecondary};
        pointer-events: none;
    `;
};

export const RaceOverviewDetails = withConfig(theme => styled('span', { label: 'RaceOverviewDetails' })<RaceOverviewDetailsType>`
    ${({ clickable, isRasGoldActiveTemp }): SerializedStyles => setClickableElement(clickable, isRasGoldActiveTemp, theme)};
`);

export const RaceOverviewData = withConfig(theme => styled('div', { label: 'RaceOverviewData' })`
    color: ${theme.star.raceSummary.txtColorSecondary};
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
    @media ${theme.star.mediaQuery.tablet} {
        flex-flow: column nowrap;
        display: flex;
        gap: 2px;
    }
`);
