import { action, makeObservable, observable } from 'mobx';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { HorseRacingSortOrderType } from 'src/domains/sportsbook/webview/components/raceCard/RaceCompetition.state';
import { TabKeyType } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceDetails.types';

export class RaceDetailsState {
    @observable public activeFilterId: TabKeyType | null = null;
    @observable public sortOrder: HorseRacingSortOrderType = null;

    public constructor(public readonly language: LanguagesState) {
        makeObservable(this);
    }

    @action public setActiveFilterId(id: TabKeyType): void {
        this.activeFilterId = id;
    }

    @action public setSortOrder(sort: HorseRacingSortOrderType): void {
        this.sortOrder = sort;
    }
}
