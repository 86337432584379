import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';
import { BreakpointsState } from 'src/domains/layouts/state/breakpointsState/BreakpointsState';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import {
    RaceOverviewData,
    RaceOverviewDataItem,
    RaceOverviewDetails,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummaryRowSport/RunnerDetails.style';
import { RaceSummaryRowHorseRacingNewState } from 'src/domains/sportsbook/webview/components/raceSummary/raceSummaryRowSport/RaceSummaryRowHorseRacingNew.state';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface RunnerDetailsPropsType {
    runnerDetailsState: RaceSummaryRowHorseRacingNewState;
    selectionModel: SelectionModel;
}

export const RunnerDetails = observer(
    'RunnerInformation',
    ({ selectionModel, runnerDetailsState }: RunnerDetailsPropsType) => {
        const eventModel = selectionModel.getEvent();
        if (eventModel === null) {
            return null;
        }

        const common = useCommon();
        const { config } = ConfigComponents.get(common);
        const isTablet = BreakpointsState.get(common).tablet.isBiggerOrEq;

        const horseWeightRaw = selectionModel.metaDataWeight ?? '';
        const horseWeightFormatted = horseWeightRaw.trim() === '' ? 'n/a' : horseWeightRaw;

        const horseAgeRaw = selectionModel.metaDataAge ?? '';
        const horseAgeFormatted = horseAgeRaw.trim() === '' ? 'n/a' : horseAgeRaw;

        const horseFormRaw = selectionModel.metaDataForm ?? '';
        const horseFormFormatted = horseFormRaw.trim() === '' ? 'n/a' : horseFormRaw;

        const handleDetailsClick = (): void => runnerDetailsState.handleDetailsClick(selectionModel.id2.key);

        const isHorseFormClickable = horseFormFormatted !== 'n/a';

        const isRasGoldActiveTemp = config.isRasGoldActive;

        const tabletContent = (
            <>
                <div>
                    <RaceOverviewDataItem>Weight: {horseWeightFormatted}</RaceOverviewDataItem>
                    <RaceOverviewDataItem>Age: {horseAgeFormatted}</RaceOverviewDataItem>
                </div>
                <div>
                    Form:{' '}
                    <RaceOverviewDetails
                        isRasGoldActiveTemp={isRasGoldActiveTemp}
                        clickable={isHorseFormClickable}
                        onClick={isHorseFormClickable ? handleDetailsClick : undefined}
                    >
                        {horseFormFormatted}
                    </RaceOverviewDetails>
                </div>
            </>
        );

        const nonTabletContent = (
            <>
                <RaceOverviewDetails
                    isRasGoldActiveTemp={isRasGoldActiveTemp}
                    clickable={isHorseFormClickable}
                    onClick={handleDetailsClick}
                >{`Form: ${horseFormFormatted}`}</RaceOverviewDetails>
                <RaceOverviewDataItem>W: {horseWeightFormatted}</RaceOverviewDataItem>
                <RaceOverviewDataItem>A: {horseAgeFormatted}</RaceOverviewDataItem>
            </>
        );

        return <RaceOverviewData>{isTablet === true ? tabletContent : nonTabletContent}</RaceOverviewData>;
    }
);
