import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';


export const DescriptionsWrapper = withConfig(theme => styled('div', { label: 'DescriptionsWrapper' })`
    border: solid 1px ${theme.star.topUp.borderColor};
    margin: 16px 0 0;
    padding: 16px;
`);

export const Description = withConfig(theme => styled('p', { label: 'Description' })`
    font-size: ${theme.star.fontSize.small};
    line-height: 1.3334;
    margin: 0;
`);

export const AdditionalDescription = styled(Description, { label: 'AdditionalDescription' })`
    margin-top: 8px;
`;
export const AdditionalDescriptionUpdated = styled(Description, { label: 'AdditionalDescriptionUpdated' })`
    margin-top: 8px;
    margin-bottom: 8px;
`;

export const MarkedTxt = withConfig(theme => styled('a', { label: 'MarkedTxt' })`
    color: ${theme.star.topUp.txtColorSecondary};
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;
`);

export const ShowMoreWrapper = withConfig(theme => styled('div', { label: 'ShowMoreWrapper' })`
    color: ${theme.star.topUp.txtColorSecondary};
    margin: 12px 0;
    display: flex;
    align-items: center;
    height: 16px;
`);

export const Arrow = styled(ChevronIcon, { label: 'Arrow' })`
    fill: currentcolor;
    width: 12px;
`;

export const ShowMoreOrLess = withConfig(theme => styled('span', { label: 'ShowMoreOrLess' })`
    display: inline-block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.3334;
    margin-left: 4px;
`);
