import React, { useState } from 'react';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { RaceCardHeaderState } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceCardHeader.state';
import {
    RaceHeaderDetails,
    RaceHeaderDetailsDistance,
    RaceHeaderDetailsHeader,
    RaceHeaderWrapper,
    RaceWeatherImgWrapper,
} from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceCardHeader.style';
import { Meta } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceCardMeta';
import { RaceDetailsState } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceDetails.state';
import {
    ArticleExcerpt,
    // ArticleOverview,
    RaceOverviewContentWrapper,
    RaceOverviewHeader,
    // RaceOverviewHeaderInline,
    RaceOverviewWrapper,
    ReadMore,
    // TipsItem,
} from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceOverview.style';
import { WeatherState } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/weatherDetails/WeatherDetailsState';
import { RenderStreamButton } from 'src/domains/sportsbook/webview/components/raceCard/raceStreamButton/RaceStreamButton';
import { observer } from 'src/utils/mobx-react';
import { EventId } from 'src_common/common/websocket2/id/WebsocketId';
import { EventModel } from 'src_common/common/websocket2/models/EventModel';

interface RaceCardHeaderPropsType {
    bogCard: JSX.Element | null;
    eventId: EventId;
    hasStream: boolean;
    hideWeather: boolean;
    isFinishedRace: boolean;
    isRaceInProgress: boolean;
    race: EventModel;
    shouldShowBogForMarket: boolean;
    strategy: RaceDetailsState;
    weatherState: WeatherState;
}

export const RaceCardHeader = observer('RaceCardHeader', (props: RaceCardHeaderPropsType) => {
    const [state] = useState(() => new RaceCardHeaderState());

    const common = useCommon();
    const configState = ConfigComponents.get(common).config;
    const raceComment = props.race.feedRaceComment?.trim() ?? '';

    return (
        <RaceHeaderWrapper>
            <RaceHeaderDetails>
                <RaceHeaderDetailsHeader
                    data-test='race-header-details-header'
                    isFinishedRace={props.isFinishedRace}
                >
                    {props.hideWeather ? null : (
                        <RaceWeatherImgWrapper src={props.weatherState.getWeatherDetails?.forecastWeatherIcon} />
                    )}
                    {props.race.name}
                </RaceHeaderDetailsHeader>
                {props.isRaceInProgress ? null : props.bogCard}
                <RaceHeaderDetailsDistance isFinishedRace={props.isFinishedRace}>
                    <Meta
                        activeFilterId={props.strategy.activeFilterId}
                        race={props.race}
                        eventId={props.eventId}
                        isFinishedRace={props.isFinishedRace}
                        shouldShowBogForMarket={props.shouldShowBogForMarket}
                    />
                </RaceHeaderDetailsDistance>

                {configState.isRasGoldActive && raceComment !== '' ? (
                    <RaceOverviewWrapper>
                        <RaceOverviewHeader>Race Overview:</RaceOverviewHeader>

                        <RaceOverviewContentWrapper isExpanded={state.isExpanded}>
                            <ArticleExcerpt isExpanded={state.isExpanded}>{raceComment}</ArticleExcerpt>

                            {/* <ArticleOverview isExpanded={state.isExpanded}>
                                <RaceOverviewHeaderInline>Tips:</RaceOverviewHeaderInline>
                                <TipsItem>1st Red Rum</TipsItem>
                                <TipsItem>2nd Frankel</TipsItem>
                                <TipsItem>3rd Seabiscuit</TipsItem>
                            </ArticleOverview> */}

                            <ReadMore onClick={state.handleExpanded}>
                                {state.isExpanded ? 'Read Less' : 'Read More'}
                            </ReadMore>
                        </RaceOverviewContentWrapper>
                    </RaceOverviewWrapper>
                ) : null}

                {props.hasStream && !props.isFinishedRace ? <RenderStreamButton race={props.race} /> : null}
            </RaceHeaderDetails>
        </RaceHeaderWrapper>
    );
});
