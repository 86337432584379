import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const Container = styled.div`
    padding: 0 1px 1px;
`;

export const Header = styled('div', { label: 'Header' })`
    padding-top: 16px;
    text-align: center;
    margin-bottom: 24px;
`;

export const Heading = withConfig(theme => styled('h4', { label: 'Heading' })`
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4;
    margin: 0;
`);

export const Text = withConfig(theme => styled('p', { label: 'Text' })<{isBold?: boolean}>`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${({ isBold }): string => isBold === true ? theme.star.fontWeight.bold : theme.star.fontWeight.regular};
    line-height: 1.3333;
    margin-top: 4px;
`);

export const ButtonsGroup = styled('div', { label: 'ButtonsGroup' })`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

