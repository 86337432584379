import { CollectionWithBingoType } from 'src/domains/casino/types/types';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { ConfigFeaturesType } from 'src/domains/common/configFeatures/ConfigFeatures';

// GROWTH TYPES
export type HeaderLinksType =
    | 'sports'
    | 'in-play'
    | 'lucky6'
    | 'mcbookie-blog'
    | 'star-news'
    | 'rhino-promotions'
    | 'dragonbet-blog'
    | CollectionWithBingoType;
export interface LanguageDetailsType {
    langCode: string;
    langName: string;
}
export type LayoutType = 'primary' | 'secondary';
export enum ButtonVariantType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export enum TooltipVariantType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export enum InputVariantType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export type LayoutOptionsType = {
    // Secondary button it's with rounded corners
    buttonVersion: ButtonVariantType;
    tooltipVersion: TooltipVariantType;
    layoutOptions: InputVariantType;
};
export type SignupType = 'primary' | 'secondary';

export type PromotedEventRouteType =
    | 'aks-big-prices'
    | 'specials'
    | 'betzoneevents'
    | 'starevents'
    | 'yeeehaaaevents'
    | 'welshevents'
    | 'promoted-events'
    | '';

// promotions for Rhino as it has different icon here
export type MobileFooterLinksType =
    | 'cash-out'
    | 'football-european'
    | 'football'
    | 'home'
    | 'in-play'
    | 'notifications'
    | 'offers'
    | 'promos'
    | 'racing'
    | 'sports-list'
    | 'sportsbook'
    | 'extra'
    | 'ak-big-prices'
    | 'ak-rewards'
    | Exclude<CollectionWithBingoType, 'virtuals'>;

export type UsefulLinksKeysType =
    | 'about-us'
    | 'responsible-gambling'
    | 'safer-gambling'
    | 'faq'
    | 'privacy-policy'
    | 'terms-and-conditions'
    | 'betting-rules'
    | 'give-feedback'
    | 'affiliates';

export type FooterUsefulLinksItemType = {
    id: UsefulLinksKeysType;
    order: number;
    to: RightHandSideViewType | string;
    target?: '_blank' | '_self' | '_parent' | '_top' | null;
    route?: string;
    isStaticLink?: boolean;
};

// PLAYERS TYPES

export type PaysafePaymentProviderType = {
    type: 'paysafe';
    apiKey: string;
    environment: 'TEST' | 'LIVE';
    cardAccounts: { GBP: string; EUR: string };
};

export type PaymentProvidersType =
    | { type: 'realex' }
    | { type: 'swiftyGlobalProvider' }
    | { type: 'praxis' }
    | PaysafePaymentProviderType;

export type ContactPreferencesType = 'email' | 'sms' | 'phone' | 'whatsApp';

// SPORTSBOOK TYPES
export type ListOfSportsType =
    | 'americanfootball'
    | 'australianrules'
    | 'badminton'
    | 'baseball'
    | 'basketball'
    | 'boxing'
    | 'cricket'
    | 'cycling'
    | 'darts'
    | 'esports'
    | 'football'
    | 'formulaone'
    | 'gaelicfootball'
    | 'gaelichurling'
    | 'golf'
    | 'greyhoundracing'
    | 'handball'
    | 'horseracing'
    | 'horseracingantepost'
    | 'icehockey'
    | 'internationalhorseracing'
    | 'mixedmartialarts'
    | 'motorsport'
    | 'olympics'
    | 'politics'
    | 'rugbyleague'
    | 'rugbyunion'
    | 'snooker'
    | 'tabletennis'
    | 'tennis'
    | 'tennisdoubles'
    | 'tvspecials'
    | 'volleyball';

export type LiveTrackerType = null | {
    providerName: string;
    scriptSrcUrl: string;
};

// the first item in an array is a default language for the operator
export type LanguagesAvailableType = Array<LanguageDetailsType>; // monte

export type ConfigType = ConfigFeaturesType;

export type ConfigKeysType = keyof ConfigType;

export const configTypesDesc: Record<keyof ConfigType, string> = {
    // CASINO CONFIG
    /* A */
    antillephoneId: 'id for antillephone if exists',
    antillephoneDisplaySector: 'define where antillephone icon should be displayed',
    /* B */
    bonusEngine: 'accounts bonuse engine',
    bonusEngineHidePopupIncident: 'bonusEngineHidePopupIncident - hide for star multi',

    /* C */
    casinoJackPot: 'set casino jackpot games for each operator',
    casinoMiniGames: 'contact possibilities for Contact Us section in the main footer',
    /* G */
    gameStatusBar: 'turn on/off game status bar for slots',
    gameThumbnailSettings: 'defines what features should be displayed on SingleGameExt',
    /* H */
    hasContinuePlayingName: 'turn on/off users name in continue playing headline', // false only for LK but on designs it should be there - fix and remove
    hasHeadlinePromo: 'turn on/off headline carousel view',
    hasGamesOnHomepage: 'turn on/off games on homepage',
    hideAllCasinoGamesOnChristmas: 'show / hide all casino game on Christmas day.',
    /* I */
    imageOptimization: 'turn on/of image optimization with Cloudflare',
    /* R */
    reloadGameAfterDepositForProviders: 'list of providers that require reload game after deposit',
    ringFencedFundsUpdated: 'use updated version of ringFencedFunds',
    /* S */
    socketPragmatic: 'DGA for live casino',

    // GROWTH CONFIG
    /* A */
    accountDrawerNew: 'New account drawer',
    appDownloadLink: 'Links for mobile app in store',
    /* C */
    cookieBotId: "Operator's cookiebot cookie consent banner id.",
    contactDetails: 'contact possibilities for Contact Us section in the main footer', // used only for LK email
    contactUsTemporaryDefaultMock: 'temporary contact us list',
    /* F */
    footerAdditionalContent: 'Changes Responsible Gambling to Safer Gambling and adds 3 more gambling-protection logos',
    filtersVersion: 'set proper version for all filters across the website.',
    footerCEGLinks: 'set footer links for CEG verification',
    footerCopyrightTemporaryDefaultMock: 'temporary footer copyright',
    footerUsefulLinks: 'Useful links section in footer',
    freshDeskChat: 'Fresh Desk chat',
    fullstory: 'fullstory tool to track user behaviour on website ',
    /* G */
    gtm: 'google tag to google tag manager - prod and non prod',
    googleExperimentIdDev: 'google optimize experiment id for dev',
    googleExperimentIdProd: 'google optimize experiment id for production',
    /* H */
    sendInfoAboutSignUpToMobileApp: 'has to collect info about signup on mobile app',
    hasCarouselPromo: 'turn on/off promo carousel view',
    hasFooterCopyrightTCLink: 'link terms & conditions in footer copyright text',
    hasXcmScript: 'has XCM script in footer',
    headerLinks: 'header links for universes',
    headerMetaScale: '',
    headerMetaTitleName: '',
    headerMetaFacebookDomainVerification: 'Unique code for facebook domain verification meta tag',
    headerSpecialLink: "link to the operator's blog / news section",
    hiddenSportsList: 'sports which have to be hidden',
    hideEmptySports: 'flag to hide sports with no events on A-Z sports list',
    /* I */
    idMission: 'enable idMission',
    isDarkUniverse: 'add icon ios in headerMeta',
    isGamblingComissionRGLogo: 'show Gambling Comission logo in Responsible Gambling footer section',
    isNoConnectionPageVisible: 'Show no connection page', // all true
    isSeoPagesContentOn: 'turn on/off seo pages content',
    isRealityCheckAvailable: 'enable Reality check popup', // all true
    isRasGoldActive: 'Show RAS Gold data on horse racing',
    /* L */
    languagesAvailable: 'languages available for an operator', // monte
    layoutOptions: 'Custom elements of the layout',
    layout: 'Specifies which layout to use',
    layoutHomepage: 'Specifies which layout to use on homepage',
    limitsAndResponsibleGambling: 'keeps routes and components of Limits & Responsible Gambling', // all true but monte
    limitsAndResponsibleGamblingOnSignup: 'keeps routes and components of "Limits & Responsible Gambling" on signup',
    /* M */
    metaOpenGraph: 'apply meta open graph in html header',
    mobileFooterLinks: 'mobile footer links for all universes',
    /* N */
    navigationPopular: 'sports to show in popular section',
    navigationFavourites: 'show favourites section',
    navigationRecentlyViewed: 'show recently viewed section',
    navigationMobileLinks:
        'set mobile navigation links different to the top nav/header ones or the same like top nav/header ones',
    newPragmatic: 'switch between old and new pragmatic integration',
    newGamblingCommissionRegulations: 'turn on new gambling commission regulations features and urls',
    notificationsList: 'switch to render notifications', // all true
    /* O */
    operatorFullName: 'Full name of each operator',
    outsideAllowedRoutes:
        'Routes from external server with the same domain which are used for promotion, news, blogs etc', // all the same
    /* P */
    page404Default: 'add additional elements and styles when 404 error page is not a default one',
    premierLeagueIframe: 'Show premier league iframe on sports/premier-league endpoint',
    problemGambling: 'Show Problem Gambling link in responsible gambling section',
    promotedEvents: "Show promoted events in LHS menu and main page and set route'accountHelperMail",
    /* R */
    raceSummaryRowHorseRacingNew: 'turns on new component (with increased silk) for horse racing selection',
    responsibleGamblingVerificationScript: 'show responsible gambling logotypes on footer',
    /* S */
    seoContent: 'seo headers and content',
    seoMeta: 'seo title, description',
    seoMetaSport: 'seo headers, title, description for sport',
    scoreboard:
        'set scoreboard version: basic (only score and time), standard (old scoreboard with animation), liveTracker (new live tracker with animation), lSport (LSport tennis scoreboard), imgArena for golf',
    sentryUrl: 'Sentry error tracker url', // all the same
    siteVerificationMetaMap: 'header meta css',
    statsCoreGroupId: 'Set groupId from Statscore to see pre-match widget and live match widget for tennis and golf.', // all the same
    socialWidgets: 'configuration social widget on RHS menu',
    stickyDesktopHeader: 'Sticky main header',
    /* T */
    termsAndConditionsDownloadLink: 'option to download T&C',
    traderChat: 'turn on/off trader chat',
    /* T */
    unSupportedBrowsers: 'turn on component for unSupportedBrowsers',
    unSupportedBrowsersLogo: 'set logo for unSupportedBrowsers component',
    /* W */
    weatherData: 'show or hide weather details',
    worldCupIframe: 'show world cup 2022 iframe',

    // PLAYERS CONFIG
    /* A */
    accountHelperMail: 'account for help contact',
    accountIdPrefix: 'prefix for account Id',

    /* C */
    casinoPublishedCollections: 'defines published casino collections',
    contactPreferences: 'defines contact preferences options',
    countryOnlyDefault: 'only default country in signup', // monte
    countrySignUpDefault: 'default country in sign up',
    cryptoOperatorAccountType: 'diffrent account tabs',
    currencyDefault: 'default currency',
    currencyDefaultSelectedInSignup: 'have default currency selected in signup',
    currencyOnlyDefault: 'only default currency in signup', // latam
    currencySignUpList: 'list of currencys in signup',
    customerFundsProtection: 'disable customer funds protection tab',
    /* D */
    decimalLength: 'specify how many places after comma we want to have in value',
    decimalFormatTextForPlaceholder: 'specify how we will display stake input placeholder text',
    detectLoginInMobileApp: 'detect login after signup in mobile app',
    depositLimitsType: 'ui change for picking limits based on calendar or time window (rolling)',
    depositsTurnOffForLeavingOperator: 'turn off deposits for the leaving operator',
    /* E */
    emailKycWarning: 'email to kyc warning',
    /* F */
    forgotPasswordInputLabel: 'input label type in forgot password form',
    forgotPasswordInputType: 'input type in forgot password form `email` | `text`',
    failedVerificationDetailed: '@description detailed failed verification page in sign up journey',
    faqSection: 'turn on/off faq section for authorized nad non authorized user.',
    /* H */
    hasCashoutEnabled: 'turn on/off able to cashout', // all true
    hasNeedHelpAccountSection: 'turn on/off section with help in account', // all true
    hasUserAddressChangeEnabled: 'turn on/off able to change address', // all true
    hasUserContactNumberChangeEnabled: 'turn on/off able to change contact number', // all true
    hasUserEmailChangeEnabled: ' turn on/off able to change email address', // all true
    /* I */
    isVerifyAccountWithSMSAvailable: 'enable the account verification via SMS',
    intercomChat: 'enable intercom Chat',
    /* L */
    loginOnResetPasswordSuccess: 'autologin after reset password success',
    licenseName: 'license name',
    lifeSpanActive: 'use lifeSpan',
    lifeSpanSocketUrls: 'Websockets urls to lifespan',
    /* M */
    minDepositAmount: 'minimum deposit amount',
    minWithdrawAmount: 'minimum withdraw amount',
    /* N */
    navigationHelpSupport: 'show help and support section',
    /* P */
    payoutRules: 'show component with payout rules text',
    prefixAndFlagDefaultForPersonalDetails: 'Display only one flag and prefix in personal details page', // monte
    prefixAndPhoneNumberDefault: 'show default prefix for personal details in account',
    prefixMultiPhoneNumber: 'Show dropdown for mobile prefixes in registration and settings', // all true
    priorityPrefixes: 'priority prefixes that are in the front of phone number prefixes list',
    pof: 'Proof of Funds features',

    /* R */
    resetPasswordForm: 'reset password form ResetPasswordForm | McBookieValidateAccountForm',
    ringFencedFundsSetByDefault: 'set ringFencedFunds by default during signup',
    rollingNetDepositLimit:
        'turn on rolling deposit limits in signup and responsible gambling tab plus check it when user logs in',
    rollingNetDepositLimitFirstTimePopupType:
        'popup for users after adding new feature to operators - only when feature was created',

    /* S */
    showKycStatus: 'show KycStatus',
    showKycDepositWaring: 'show Kyc warning in deposit tab',
    signUpDisableButtonYourDetails: 'signUp disable button YourDetails', //all false
    signUpFooterMail: '',
    signUpShowPromoCode: '',
    signUpAddressSearchByPostCode: 'turn on/off searching addres by postcode in singup procedure', // monte
    signUpOmitAgeVerification: 'pass registration without checking age verification',
    signUpSecondAddressLine: 'show second line option to choose during signup',
    signUpTitle: 'show title option to choose during signup',
    signupType: 'signup journey type',
    signupPrivacyPolicyLink: 'show signup privacy policy link on signup',
    signupTurnOffForLeavingOperator: 'turn off signup for leaving operator',
    signUpCheckboxTC: 'signup checkbox for Terms & Conditions',
    srgQuestionnaire: 'show the SRG Questionnaire form modal',
    /* T */
    temporaryDepositPage: 'Show temporary deposit page',
    temporaryWithdrawalPage: 'Show temporary withdrawal page',
    timeoutToTestSignupFlow: 'use to change customer settings in BO', // dev for all
    /* U */
    usePaymentFormForUniverse: 'choose the payment provider',
    useOpenBanking: 'Open Banking Payment provider',
    usesCrypto: 'changes way of adding money to the balance',
    userCanChangeRealityCheck: 'user can change Reality Check duration',
    /* Y */
    yaspaMobileAppValidVersion: 'Version from which Yaspa is valid on mobile apps',
    /* Z */
    zipCodeAndCountryCheck: 'Changed name of postcode to zip code and validate signup dropdown field',

    // SPORTSBOOK CONFIG
    /* B */
    buildRacecardCheckboxColorMode: 'set checkbox colors for Build Racecard',

    /* C */
    couponForEnv: 'coupon for environment',
    /* E */
    eventsEuropeanEliteMcbookie: 'get ids europeanEliteTemplate from template mcbookie', // 4 years ago for mcb - check if still needed
    eventsOnLaterHomePage: 'if route is starEvents show only eventsOnlater in mid layout',
    excludedCountriesFromSignUp: 'Remove specific countries from signup page',
    /* H */
    hasEventLiveStreaming: 'show icons and buttons for live event stream',
    hasInPlayDelayInformation: 'turn on delay information in InPlayComponent', // all true
    hasTopUpEnabled: 'turn on/off top up in betslip', // all true
    /* I */
    isAlternativeRacingBoost: 'display alternative price boosts',
    isAntePostReverse: 'add icon ios in headerMeta',
    isAutomaticCarousel: 'should promo carousel be automatic',
    isStreamControlledByTime: 'decide if stream component on Special Event should be controlled by time', // all false
    isStreamVisibleForUnauthorized: 'decide if stream component should be visible for unauthorized users', // all true
    /* L */
    liveTracker: 'live tracking scoreboard configuration - provider name and script src',
    liveTrackerForFootballOutrights: 'Specifies whether live tracker should be displayed for football outrights',
    /* N */
    noBetslip: 'universe has no betslip',
    nextRacesNew: 'turn on new view for Next Races (next off)',
    /* O */
    oddsFormatShortDefault: 'default odds for anonymous user',
    /* P */
    priceHistoryLineChart: 'show price history line chart in racing', // all true but LK that does not have races on
    /* R */
    rab: 'turn on RAB events',
    rabCashoutAvailable: 'decscription in YouDecideHeader (Unavailable for cashout)',
    rabHeaderIcon: 'BetBuilderIcon', // all the same
    rabHeaderTitle: 'title for rab header and bets',
    racingNextOffToggle: 'set switch between HR and GR next off',
    racingPageTabNames: 'set names for racing page filter tabs',
    /* S */
    shouldDisplaySilkWithoutQuestionMark: 'turn on/off render seats on live-casino games covers',
    showHorseRacingSortDropdown: 'show sort dropdown on Horse Racing Racecard',
    shouldPlaceBetToWatchStream: 'decide if user has to place a bet to enable stream',
    sliceNameRaceLabel: 'turn on slice name in race label',
    sportGreyhoundMeetingsSplitByCountry: 'decide if GR sport meetings are splitted by country',
    sportHorseracingMeetingsSplitByCountry: 'decide if HR sport meetings are splitted by country',
    sportsNamesCapitalized: 'make sport names in LHS capitized', // monte
    startingSoonEvents: 'show starting soon events on homepage',
    /* T */
    trapChallenge: 'enable Trap Challenge',
    /* U */
    universeSetup: '', //sbk
    /* W */
    withdrawValidateBtcAddress: 'determines whether to validate the wallet address in BTC',
};

/*
    Prod - Star
host: "dga.pragmaticplaylive.net",
casinoId: "ppcdg00000004198",

    Prod - Mcbookie
host: "dga.pragmaticplaylive.net",
casinoId: "ppcdg00000004197",

    Prod - NeBet
host: "dga.pragmaticplaylive.net",
casinoId: "ppcdg00000004199",

    STG - NeBet
host: "prelive-dga.pragmaticplaylive.net",
casinoId: "ppcdk00000005026",

    STG - Mcbookie
host: "prelive-dga.pragmaticplaylive.net",
casinoId: "ppcdk00000005025",
    */
