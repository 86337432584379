import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';
import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const MeetingsInfo = withConfig(theme => styled('div', { label: 'MeetingsInfo' })`
    background: ${theme.star.meetingsAntePost.bgColorSecondary};
    border-color: ${theme.star.meetingsAntePost.borderColor};
    border-style: solid;
    border-width: 0 1px;
    color: ${theme.star.meetingsAntePost.txtColor};
    flex: 1 1 0%;
    font-size: ${theme.star.fontSize.xRegular};
    padding: 10px 5px;
    position: relative;
    text-align: center;
`);

export const MeetingsEventHeader = withConfig(theme => styled('header', { label: 'MeetingsEventHeader' })`
    align-items: center;
    background-color: ${theme.star.meetingsAntePost.bgColorTertiary};
    color: ${theme.star.meetingsAntePost.txtColorTertiary};
    display: flex;
    @media ${theme.star.mediaQuery.xMobile} {
        color: inherit;
        background-color: transparent;
    }
`);

export const BtnBack = withConfig(theme => styled('div', { label: 'BtnBack' })`
    align-items: center;
    background-color: ${theme.star.meetingsAntePost.bgColorQuinary};
    color: ${theme.star.meetingsAntePost.txtColorTertiary};
    cursor: pointer;
    display: flex;
    padding: 10px;
    @media ${theme.star.mediaQuery.xMobile} {
        color: inherit;
        background-color: ${theme.star.meetingsAntePost.bgColorQuaternary};
    }
`);

export const MeetingsBackTitle = styled('span', { label: 'MeetingsBackTitle' })`
    padding: 8px;
`;

export const ChevronLeftIconWrapper = styled(ChevronIcon, { label: 'ChevronLeftIconWrapper' })`
    fill: currentcolor;
    width: 20px;
`;

export const LoaderWrapper = styled(Loader, { label: 'LoaderWrapper' })`
    justify-content: center;
`;

export const MeetingsEvent = withConfig(theme => styled('div', { label: 'MeetingsEvent' })`
    background-color: ${theme.star.meetingsAntePost.bgColor};
`);
