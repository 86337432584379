import { EventModel } from 'src_common/common/websocket2/models/EventModel';
import { MarketModel } from 'src_common/common/websocket2/models/MarketModel';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { ModelBoxContext } from 'src_common/common/websocket2/common/ModelBoxContext';
import { CompetitionModel } from 'src_common/common/websocket2/models/CompetitionModel';
import { ServerTimeState } from 'src_common/common/websocket2/ServerTimeState';
import { EventMarketItemType, decodeEventMarkets } from 'src_common/common/websocket2/modelsApi/EventMarkets';
import { SportModelType, isSport } from 'src_common/common/websocket2/modelsApi/Sport';
import { EventsCollectionQueryType, EventListGroupType, convertEventListGroup, decodeEventListGroup } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { CompetitionModelType, isCompetitionModel } from 'src_common/common/websocket2/modelsApi/Competition';
import { EventBasicModelType, EventModelType, decodeEventBasicModel, decodeEventModel } from 'src_common/common/websocket2/modelsApi/Event';
import { SelectionModelType, MarketModelType, MarketApiModelType, decodeMarketResponse } from 'src_common/common/websocket2/modelsApi/Market';
import { SelectionAnimation } from 'src_common/common/websocket2/models/SelectionModel/SelectionAnimation';
import { SportModel } from 'src_common/common/websocket2/models/SportModel/SportModel';
import { EventMarketsModel } from 'src_common/common/websocket2/models/EventMarketsModel';
import { EventsCollectionQueryModel } from 'src_common/common/websocket2/models/EventsCollectionQueryModel';
import { RabMarketType } from 'src_common/common/websocket2/modelsApi/RabMarket';
import { RabMarketsModel } from 'src_common/common/websocket2/models/RabMarketsModel';
import { WebsocketId } from 'src_common/common/websocket2/id/WebsocketId';
import { TypeId } from 'src_common/common/websocket2/type';
import { ModelsStateSocketConfig } from 'src_common/common/websocket2/ModelsStateSocketConfig';
import { SocketController } from 'src_common/common/mobx-utils/Socket/SocketController';
import { SocketMap } from 'src_common/common/mobx-utils/Socket/SocketMap';
import { createMessageSubscriptions } from 'src_common/common/websocket2/SocketClient/common/MessageToServer';
import { Value } from 'src_common/common/mobx-utils/Value';
import { SocketConnection, SocketEventType } from 'src_common/common/mobx-utils/Socket/SocketConnection';
import { assertNever } from 'src_common/common/assertNever';
import { isMessageMessage, isMessagePong, isMessageUpdate } from 'src_common/common/websocket2/SocketClient/common/MessageToBrowser';
import { SubscriptionResourceUpdateType } from 'src_common/common/websocket2/SocketClient/common/subscriptionId';
import { timeout } from 'src_common/common/mobx-utils/timeout';

const TIMEOUT_ANIMATION = 900;
const TIMEOUT_BATCH_UPDATE = 50;
const TIMEOUT_FOR_NEW_CONNECTION = 10000;

export class ModelsEventStateNew {
    private modelBoxContext: ModelBoxContext;
    private socketController: SocketController;

    private eventMap: SocketMap<number, EventModelType, EventModel>; //TODO - Must be changed to EventId
    private marketMap: SocketMap<TypeId.MarketId, MarketModelType, MarketModel>; //TODO - Must be changed to MarketId
    private selectionMap: SocketMap<number, SelectionModelType, SelectionModel>; //TODO - Must be changed to SelectionId
    private eventMarketsMap: SocketMap<number, Array<EventMarketItemType>, EventMarketsModel>; //TODO - Must be changed to EventId
    private eventMarketsAllMap: SocketMap<number, Array<EventMarketItemType>, EventMarketsModel>; //TODO - Must be changed to EventId
    private sportMap: SocketMap<string, SportModelType, SportModel>;
    private eventQueryMap: SocketMap<string, EventListGroupType, EventsCollectionQueryModel>;
    private competitionMap: SocketMap<number, CompetitionModelType, CompetitionModel>; //TODO - Must be changed to CompetitionId
    private rabMarketsMap: SocketMap<string, Array<RabMarketType>, RabMarketsModel>;

    public constructor(
        isBrowser: boolean,
        modelsStateSocketConfig: ModelsStateSocketConfig,
        websocket_host_v2: string,
        websocketId: () => WebsocketId,
    ) {
        const serverTime = new ServerTimeState();

        this.modelBoxContext = {
            modelsStateSocketConfig,
            serverTime: serverTime,
            getEvent: this.getEvent,
            getEventMarkets: this.getEventMarkets,
            getEventMarketsAll: this.getEventMarketsAll,
            getMarket: this.getMarket,
            getSelection: this.getSelection,
            websocketId,
        };

        this.socketController = new SocketController(
            'Events',
            websocket_host_v2,
            isBrowser,
            TIMEOUT_FOR_NEW_CONNECTION,
            TIMEOUT_BATCH_UPDATE
        );

        this.eventMap = new SocketMap(
            this.socketController,
            (eventId: number): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelEvent',
                        id: eventId,
                    },
                    active: true
                }]));
            },
            (eventId: number): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelEvent',
                        id: eventId,
                    },
                    active: false
                }]));
            },
            (value: Value<EventModelType>): EventModel => {
                return new EventModel(this.modelBoxContext, value);
            }
        );

        this.marketMap = new SocketMap(
            this.socketController,
            (marketId: TypeId.MarketId): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelMarket',
                        eventId: TypeId.getEventId(marketId),
                        id: TypeId.getMarketId(marketId),
                    },
                    active: true
                }]));
            },
            (marketId: TypeId.MarketId): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelMarket',
                        eventId: TypeId.getEventId(marketId),
                        id: TypeId.getMarketId(marketId),
                    },
                    active: false
                }]));
            },
            (value: Value<MarketModelType>): MarketModel => {
                return new MarketModel(this.modelBoxContext, value);
            }
        );

        this.selectionMap = new SocketMap(
            this.socketController,
            null,
            null,
            (value: Value<SelectionModelType>): SelectionModel => {
                const animation = new SelectionAnimation(TIMEOUT_ANIMATION);
                return new SelectionModel(this.modelBoxContext, animation, value);
            }
        );

        this.eventMarketsMap = new SocketMap(
            this.socketController,
            (eventId: number): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelEventMarkets',
                        id: eventId,
                    },
                    active: true
                }]));
            },
            (eventId: number): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelEventMarkets',
                        id: eventId,
                    },
                    active: false
                }]));
            },
            (value: Value<Array<EventMarketItemType>>): EventMarketsModel => {
                return new EventMarketsModel(value);
            }
        );

        this.eventMarketsAllMap = new SocketMap(
            this.socketController,
            (eventId: number): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelEventMarketsAll',
                        id: eventId,
                    },
                    active: true
                }]));
            },
            (eventId: number): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelEventMarketsAll',
                        id: eventId,
                    },
                    active: false
                }]));
            },
            (value: Value<Array<EventMarketItemType>>): EventMarketsModel => {
                return new EventMarketsModel(value);
            }
        );

        this.sportMap = new SocketMap(
            this.socketController,
            (sportId: string): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelSport',
                        id: sportId,
                    },
                    active: true
                }]));
            },
            (sportId: string): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelSport',
                        id: sportId,
                    },
                    active: false
                }]));
            },
            (value: Value<SportModelType>): SportModel => {
                return new SportModel(value);
            }
        );

        this.eventQueryMap = new SocketMap(
            this.socketController,
            (query: string): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'QueryEvents',
                        query,
                    },
                    active: true
                }]));
            },
            (query: string): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'QueryEvents',
                        query,
                    },
                    active: false
                }]));
            },
            (value: Value<EventListGroupType>): EventsCollectionQueryModel => {
                return new EventsCollectionQueryModel(
                    this.modelBoxContext.websocketId,
                    this.modelBoxContext.serverTime,
                    value
                );
            }
        );

        this.competitionMap = new SocketMap(
            this.socketController,
            (competitionId: number): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelCompetition',
                        id: competitionId,
                    },
                    active: true
                }]));
            },
            (competitionId: number): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelCompetition',
                        id: competitionId,
                    },
                    active: false
                }]));
            },
            (value: Value<CompetitionModelType>): CompetitionModel => {
                return new CompetitionModel(
                    this.modelBoxContext,
                    value
                );
            }
        );

        this.rabMarketsMap = new SocketMap(
            this.socketController,
            (platformId: string): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelRabMarkets',
                        platformId,
                    },
                    active: true
                }]));
            },
            (platformId: string): string => {
                return JSON.stringify(createMessageSubscriptions([{
                    id: {
                        type: 'ModelRabMarkets',
                        platformId,
                    },
                    active: false
                }]));
            },
            (value: Value<Array<RabMarketType>>): RabMarketsModel => {
                return new RabMarketsModel(
                    value
                );
            }
        );

        this.socketController.onData((data: SocketEventType) => {
            if (data.type === 'open') {
                this.eventMap.onNewSocket(data.socket);
                this.marketMap.onNewSocket(data.socket);
                this.selectionMap.onNewSocket(data.socket);
                this.eventMarketsMap.onNewSocket(data.socket);
                this.eventMarketsAllMap.onNewSocket(data.socket);
                this.sportMap.onNewSocket(data.socket);
                this.eventQueryMap.onNewSocket(data.socket);
                this.competitionMap.onNewSocket(data.socket);
                this.rabMarketsMap.onNewSocket(data.socket);

                this.runPingLoop(data.socket);
                return;
            }

            if (data.type === 'message') {
                const json = JSON.parse(data.message);

                if (isMessagePong(json)) {
                    return;
                }

                if (isMessageMessage(json)) {
                    data.socket.log.info(`Info from socket: ${json.text}`);
                    return;
                }

                if (isMessageUpdate(json)) {
                    this.processMessage(json.item);
                    return;
                }

                console.error('websocket: unsupported type of server message', json);
                return;
            }

            if (data.type === 'close') {
                return;
            }

            return assertNever('socketController onData', data);
        });
    }

    private runPingLoop(socket: SocketConnection): void {
        const getCurrentTimeInSeconds = (): number => Math.floor(Date.now() / 1000);
    
        let conectionActive = true;
        let lastPong: number = 0;

        (async (): Promise<void> => {
            while (conectionActive) {
                socket.send(JSON.stringify({
                    type:'Ping'
                }));

                await timeout(5000);

                const current = getCurrentTimeInSeconds();
                const lastPingDelta = current - lastPong;

                if (lastPingDelta > 8) {
                    socket.close();
                    return;
                }
            }
        })().catch((error) => {
            console.error(error);
        });

        const unsubscribe = this.socketController.onData((data) => {
            if (data.socket !== socket) {
                return;
            }

            if (data.type === 'message') {
                const json = JSON.parse(data.message);

                if (isMessagePong(json)) {
                    lastPong = getCurrentTimeInSeconds();
                    return;
                }
            }

            if (data.type === 'close') {
                conectionActive = false;
                unsubscribe();
                return;
            }
        });
    }

    private processMessage(item: SubscriptionResourceUpdateType): void {
        if (item.type === 'ModelSport') {
            if (isSport(item.data)) {
                this.sportMap.onMessage(item.id, item.data);
            } else {
                console.error('socket, model ModelSport: message decoding error', item);
            }
            return;
        }

        if (item.type === 'ModelCompetition') {
            if (isCompetitionModel(item.data)) {
                this.competitionMap.onMessage(item.id, item.data);
            } else {
                console.error('socket, model ModelCompetition: message decoding error', item);
            }
            return;
        }

        if (item.type === 'ModelMarket') {

            const model = decodeMarketResponse(item.data);

            if (model instanceof Error) {
                console.error(model);
                return;
            }
    
            const marketId = model.id;
            const eventId = model.event.id;
    
            const newMarketId = TypeId.newMarketId(model.event.id, item.id);
            this.marketMap.onMessage(newMarketId, {
                ...model,
                selections: this.transformSelections(eventId, marketId, model.selections),
                id: TypeId.newMarketId(eventId, model.id),
            });
            return;
        }

        if (item.type === 'ModelEvent') {
            const basicModel = decodeEventBasicModel(item.event);

            if (basicModel instanceof Error) {
                console.error(basicModel);
                return;
            }
    
            const rawData = this.replaceSport(basicModel);
    
            const model = decodeEventModel(rawData);
    
            if (model instanceof Error) {
                console.error(model);
                return;
            }
    
            this.eventMap.onMessage(item.id, model);
            return;
        }

        if (item.type === 'ModelEventMarkets') {
            const data = decodeEventMarkets(item.data);

            if (data instanceof Error) {
                console.error(data);
                return;
            }
            
            const newModels = [];
            for (const model of data) {
                newModels.push({
                    ...model,
                    id: TypeId.newMarketId(item.id, model.id),
                    id2: this.modelBoxContext.websocketId().getMarketId(item.id, model.id),
                });
            }

            this.eventMarketsMap.onMessage(item.id, newModels);
            return;
        }

        if (item.type === 'ModelEventMarketsAll') {
            const data = decodeEventMarkets(item.data);

            if (data instanceof Error) {
                console.error(data);
                return;
            }
            
            const newModels = [];
            for (const model of data) {
                newModels.push({
                    ...model,
                    id: TypeId.newMarketId(item.id, model.id),
                    id2: this.modelBoxContext.websocketId().getMarketId(item.id, model.id),
                });
            }

            this.eventMarketsAllMap.onMessage(item.id, newModels);
            return;
        }

        if (item.type === 'QueryEvents') {
            const data = decodeEventListGroup(item.data.group);

            if (data instanceof Error) {
                console.error(data);
                return;
            }
    
            const modelData = convertEventListGroup(this.modelBoxContext.websocketId, data);
            this.eventQueryMap.onMessage(item.query, modelData);
            return;
        }

        if (item.type === 'ModelRabMarkets') {
            this.rabMarketsMap.onMessage(item.platformId, item.rabMarkets);
            return;
        }

        console.error('websocket: unsupported model', item);
    }

    private transformSelections(eventId: number, marketId: number, selections: MarketApiModelType['selections']): Array<number> {
        return Object.values(selections).map((item) => {
            const selectionId = item.id;
            const oldPrice = this.selectionMap.getById(item.id)?.price?.d ?? null;

            this.selectionMap.onMessage(selectionId, {
                ...item,
                eventId,
                marketId: TypeId.newMarketId(eventId, marketId),
            });

            const selectionModel = this.selectionMap.getById(item.id);

            if (selectionModel === null) {
                console.error('Something went wrong, selection model is null.');
            } else {
                const newPrice = selectionModel.price?.d ?? null;
                if (oldPrice === null || newPrice === null) {
                    return selectionId;
                }
                if (newPrice === oldPrice) {
                    //we do nothing in this scenario
                } else if (newPrice > oldPrice) {
                    selectionModel.setPriceAnimation('up');
                } else {
                    selectionModel.setPriceAnimation('down');
                }                
            }

            return selectionId;
        });
    }

    private replaceSport(basicModel: EventBasicModelType): EventBasicModelType & { sportOriginal: string } {
        for (const [sport, subsport] of Object.entries(this.modelBoxContext.modelsStateSocketConfig.apiEventListIncludes)) {
            if (subsport.includes(basicModel.sport)) {
                return {
                    ...basicModel,
                    sport: sport,
                    sportOriginal: basicModel.sport,
                };
            }
        }

        return {
            ...basicModel,
            sportOriginal: basicModel.sport,
        };
    }

    public getSelectionAndLoad = (marketId: TypeId.MarketId, selectionId: number): SelectionModel | null => {
        //Important
        //Fake subscription. It must stay. This is for loading selectiona data.
        //The selection model is part of the market model.
        this.getMarket(marketId);

        return this.selectionMap.getById(selectionId);
    };

    /**
     * @deprecated - If the new version of the socket handling code works. This method will be to delete
     */
    public refreshSocket = (): void => {
    };

    /**
     * @deprecated
     */
    public getSelection = (selectionId: number): SelectionModel | null => {
        return this.selectionMap.getById(selectionId);
    };

    public getMarket = (id: TypeId.MarketId): MarketModel | null => {
        return this.marketMap.getById(id);
    };

    public getEvent = (id: number): EventModel | null => {
        return this.eventMap.getById(id);
    };

    public getEventMarkets = (id: number): Array<EventMarketItemType> | null => {
        return this.eventMarketsMap.getById(id)?.getValue() ?? null;
    };

    public getEventMarketsAll = (id: number): Array<EventMarketItemType> | null => {
        return this.eventMarketsAllMap.getById(id)?.getValue() ?? null;
    };

    public getCompetitionModel(id: number): CompetitionModel | null {
        return this.competitionMap.getById(id);
    }

    public getSport(sportId: string): SportModel | null {
        return this.sportMap.getById(sportId);
    }

    public getEventQuery(query: EventsCollectionQueryType): EventsCollectionQueryModel | null {
        const queryString = JSON.stringify(query);
        return this.eventQueryMap.getById(queryString);
    }

    public getRabMarkets(platformId: string): RabMarketsModel | null {
        return this.rabMarketsMap.getById(platformId);
    }
}


