import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';
import { InfoIcon } from 'src/domains/layouts/shared/assets/iconography/interface/InfoIcon';
import { TooltipVariantType } from 'src/domains/layouts/config/features/types';

export const TooltipContainer = styled('div', { label: 'TooltipContainer' })`
    &:hover .tooltip-content {
        visibility: visible;
    }

    &:hover .tooltip-triangle {
        visibility: visible;
    }
`;

export const IconContainer = styled('div', { label: 'IconContainer' })`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const Icon = styled(InfoIcon, { label: 'Icon' })`
    height: 16px;
    fill: currentcolor;
    color: currentcolor;
`;

export const TooltipTriangle = withConfig(theme => styled('div', { label: 'TooltipTriangle' })<{isError?: boolean}>`
    position: absolute;
    top: 18px;
    width: 0;
    height: 0;
    margin-left: 4px;
    margin-right: 8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: ${theme.star.zIndexLevelBase.baseUp};
    border-bottom: ${({ isError }): string => isError === true ? `8px solid ${theme.star.tooltip.bgColorTertiary}`: `8px solid ${theme.star.tooltip.bgColorSecondary}` };
    visibility: hidden;
`);

type TooltipPropsType = {
    isError?: boolean;
    version: 'primary' | 'secondary';
};

export const TooltipContent = withConfig(theme => styled('div', { label: 'TooltipContent' })<TooltipPropsType>`
    position: absolute;
    top: 26px;
    width: 100%;
    left: 0;
    background: ${({ isError }): string => isError === true ? `${theme.star.tooltip.bgColorTertiary}`: `${theme.star.tooltip.bgColorSecondary}`};
    color: ${theme.star.tooltip.txtColorSecondary};
    font-size: ${theme.star.fontSize.small};
    padding: 4px 8px;
    z-index: ${theme.star.zIndexLevelBase.baseUp};
    visibility: hidden;
    border-radius: ${({ version }): string => version === TooltipVariantType.SECONDARY ? '4px' : '0'};
`);
