import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import {
    DescriptionsWrapper,
    Description,
    AdditionalDescription,
    MarkedTxt,
    ShowMoreWrapper,
    Arrow,
    ShowMoreOrLess,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/Deposit.style';
import { useCommon } from 'src/domains/common/Common';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { RingFencedFundsMessageUpdated } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/ringFencedFundsWrapper/RingFencedFundsWrapperUpdated';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';

interface RingFencedFundsTypesABodyType {
    isMoreShown: boolean;
    toggleShowMore: () => void;
}

type RingFencedFundsType = RingFencedFundsTypesABodyType;

const RingFencedFundsMessage = observer('RingFencedFundsMessage', (props: RingFencedFundsTypesABodyType) => {
    const common = useCommon();
    const languagesState = LanguagesState.get(common);

    const { isMoreShown, toggleShowMore } = props;

    const additionalDescription = languagesState.getTranslation(
        'signup-journey.deposit.additional-description-part1',
        'They would form part of the assets of the business in the event of insolvency. These accounts include bank accounts, investments accounts and reserve funds we hold with our payment processors. This procedure meets the British Gambling Commission’s requirements for the segregration of customer funds at the level '
    );
    const markedTxt = languagesState.getTranslation(
        'signup-journey.deposit.additional-description-part2',
        'not protected'
    );
    const showLess = languagesState.getTranslation('signup.deposit.ring-fenced-funds.show-less', 'Show Less');
    const showMore = languagesState.getTranslation('signup.deposit.ring-fenced-funds.show-more', 'Show More');

    return (
        <DescriptionsWrapper>
            <Description data-test='ringfenced-funds-description'>
                <I18n
                    langKey='signup-journey.deposit.description-line1'
                    defaultText='We hold customer funds in accounts separate from business accounts.'
                />
            </Description>
            {isMoreShown ? (
                <AdditionalDescription data-test='ringfenced-funds-additional-description'>
                    {additionalDescription}
                    <MarkedTxt
                        href='https://www.gamblingcommission.gov.uk/public-and-players/guide/page/what-it-means-if-your-money-is-not-protected'
                        target='_blank'
                        rel='noopener'
                        data-test='ringfenced-funds-not-protected'
                    >
                        {markedTxt}
                    </MarkedTxt>
                    .
                </AdditionalDescription>
            ) : null}
            <ShowMoreWrapper onClick={toggleShowMore}>
                <Arrow position={isMoreShown ? 'up' : 'down'} />
                <ShowMoreOrLess data-test='ringfenced-funds-show-more-or-less'>
                    {isMoreShown ? showLess : showMore}
                </ShowMoreOrLess>
            </ShowMoreWrapper>
            <Description data-test='ringfenced-funds-description'>
                <I18n
                    langKey='signup-journey.deposit.description-line2'
                    defaultText='By clicking pay below you acknowledge receipt of this information.'
                />
            </Description>
        </DescriptionsWrapper>
    );
});

export const RingFencedFundsWrapper = observer(
    'RingFencedFundsWrapper',
    (props: RingFencedFundsType): JSX.Element | null => {
        const common = useCommon();
        const configComponents = ConfigComponents.get(common);

        if (configComponents.config.ringFencedFundsUpdated) {
            return <RingFencedFundsMessageUpdated />;
        } else {
            return (
                <RingFencedFundsMessage
                    isMoreShown={props.isMoreShown}
                    toggleShowMore={props.toggleShowMore}
                />
            );
        }
    }
);
