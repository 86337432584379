import { buildQueryString, parse, ParseResult } from './Router/url';
import { action, computed, observable, makeObservable } from 'mobx';
import { configRoutes as configRoutesStar } from 'src/domains/layouts/state/router/Router/configRoutes';
import { UniverseType } from 'src_common/common/universe';
import { ExternalApiEmmiters } from 'src/domains/layouts/state/externalApi/ExternalApiEmmiters';
import { RouteViewType } from './newRouter/mainRouteTypes';
import { RightHandSideViewType } from './newRouter/rhsRouteType';
import { assertNever } from 'src_common/common/assertNever';

type ParamsType = Record<string, string | boolean | number | null | undefined>;

interface RoutePartialType {
    url: string;
    matcher: RegExp;
    params: Array<string>;
}

interface RouteType {
    url: string;
    matcher: RegExp;
    params: Array<string>;
    name: string;
}

export interface FindRouteType {
    name: string;
    params: Record<string, string>;
}

interface ReadonlyRouteType {
    readonly name: string;
    readonly params: Readonly<Record<string, string>>;
}

function parseRoute(route: string): RoutePartialType {
    const params: Array<string> = [];

    // eslint-disable-next-line
    const matcher = new RegExp(
        `^${route.replace(/:([^\s/.]+)/g, function (_$0, $1) {
            params.push($1);
            return '([\\w-]+)';
        })}$`
    );

    return {
        url: route,
        matcher,
        params,
    };
}

const convertRoute = (routes: Record<string, string>): Array<RouteType> => {
    return Object.keys(routes).map(
        (route): RouteType => ({
            ...parseRoute(route),
            //@ts-expect-error
            name: routes[route],
        })
    );
};

const defaultFindRoute = {
    name: 'error',
    params: {
        code: '404',
    },
};

const handle = (routes: Array<RouteType>, url: ParseResult): FindRouteType => {
    for (const route of routes) {
        const match = route.matcher.exec(url.path);

        if (match !== null) {
            const newRoute = {
                name: route.name,
                params: { ...url.query },
            };

            route.params.forEach((name, idx) => {
                // @ts-expect-error
                newRoute.params[name] = match[idx + 1];
            });

            return newRoute;
        }
    }

    return defaultFindRoute;
};

const findRoute = (
    routes: Array<RouteType>,
    route: string | null | undefined,
    params: ParamsType
): RouteType | null => {
    for (const r of routes) {
        const filteredParams = r.params.filter((p) => params[p] === undefined);

        if (r.name === route && filteredParams.length === 0) {
            return r;
        }
    }

    return null;
};

const buildUrl = (routes: Array<RouteType>, route: string, params: ParamsType = {}): string => {
    const routing = findRoute(routes, route, params);
    if (routing !== null) {
        //@ts-expect-error
        const url = routing.url.replace(/:([^\s/.]+)/g, ($0, $1) => params[$1]);

        params = { ...params };

        Object.keys(params)
            .filter((key) => routing.params.includes(key))
            .forEach((key) => delete params[key]);

        const qs = buildQueryString(params);

        if (qs !== '') {
            return `${url}?${qs}`;
        }

        return url;
    }

    return '/';
};

const clearParams = (params: ParamsType): Record<string, string> => {
    const out: Record<string, string> = {};

    for (const [key, value] of Object.entries(params)) {
        if (value !== undefined && value !== null) {
            out[key] = value.toString();
        }
    }

    return out;
};

export class Router {
    private readonly externalApiEmmitersInner: ExternalApiEmmiters;
    private readonly routes: Array<RouteType>;

    private callbacks: Array<(prev_url: FindRouteType, next_url: FindRouteType) => void>;

    @observable.ref public routeInner: FindRouteType;

    public constructor(
        url: string,
        universe: UniverseType,
        public readonly scrollToTop: () => void
    ) {
        makeObservable(this);
        this.externalApiEmmitersInner = new ExternalApiEmmiters();

        const routes = configRoutesStar(universe);
        this.routes = convertRoute(routes);
        this.callbacks = [];

        this.routeInner = handle(this.routes, parse(url));
    }

    @action private setRouteInner(new_value: FindRouteType): void {
        for (const callback of this.callbacks) {
            callback(this.routeInner, new_value);
        }

        this.routeInner = new_value;
    }

    /**
     * @deprecated
     */
    public parseUrl(url: string): FindRouteType {
        return handle(this.routes, parse(url));
    }

    @action public setUrl(url: string): void {
        this.setRouteInner(this.parseUrl(url));
    }

    @action private redirectInner(route: string | undefined | null, params: ParamsType = {}): void {
        if (params.account === 'login' && this.externalApiEmmitersInner.isLoginDisabled) {
            this.externalApiEmmitersInner.emitEventLoginClick();
            return;
        }

        if (typeof route === 'string') {
            this.setRouteInner({
                name: route,
                params: clearParams(params),
            });
        } else {
            this.setRouteInner({
                name: this.routeInner.name,
                params: clearParams({
                    ...this.routeInner.params,
                    ...params,
                }),
            });
        }
    }

    public onChange = (callback: (prev_url: FindRouteType, next_url: FindRouteType) => void): void => {
        this.callbacks.push(callback);
    };

    /**
     * @deprecated Create a new specific method inside this class, like `redirectToLogin`
     */
    @action public redirect(route: string | undefined | null, params: ParamsType = {}): void {
        // params from file rhsRouteType.ts
        if (params.static === undefined) {
            params.static = null;
        }

        if (params.email === undefined) {
            params.email = null;
        }

        if (params.token === undefined) {
            params.token = null;
        }

        if (params.isVerify === undefined) {
            params.isVerify = null;
        }

        if (params.receivedVia === undefined) {
            params.receivedVia = null;
        }

        if (params.notificationId === undefined) {
            params.notificationId = null;
        }
        if (params.promo === undefined) {
            params.promo = null;
        }

        if (params.type === undefined) {
            params.type = null;
        }

        this.redirectInner(route, params);
    }

    public buildUrl = (route: string | undefined | null, params: ParamsType = {}): string => {
        if (typeof route === 'string') {
            return buildUrl(this.routes, route, clearParams(params));
        } else {
            return buildUrl(
                this.routes,
                this.routeInner.name,
                clearParams({
                    ...this.routeInner.params,
                    ...params,
                })
            );
        }
    };

    @computed public get url(): string {
        return buildUrl(this.routes, this.routeInner.name, this.routeInner.params);
    }

    /**
     * @deprecated Create specific computed getters for paramaters like @computed get eventId()
     */
    public get route(): ReadonlyRouteType {
        return this.routeInner;
    }

    /**
     * @deprecated Create specific computed getters, to keep logic inside Router appstate
     */
    @computed public get routeName(): string {
        return this.routeInner.name;
    }

    /**
     * @deprecated
     */
    public get externalApiEmmiters(): ExternalApiEmmiters {
        return this.externalApiEmmitersInner;
    }

    public get isLogoutDontMoveToLoginPage(): boolean {
        return this.externalApiEmmitersInner.isLogoutDontMoveToLoginPage;
    }

    public get isLoginDisabled(): boolean {
        return this.externalApiEmmitersInner.isLoginDisabled;
    }

    public emitEventLogoutClick(): void {
        this.externalApiEmmitersInner.emitEventLogoutClick();
    }

    public buildUrlTo = (to: RouteViewType | RightHandSideViewType): string => {
        const [route, params] = this.convertViewToParams(to);
        return this.buildUrl(route, params);
    };

    public redirectTo(to: RouteViewType | RightHandSideViewType, scrollToTop: boolean): void {
        const [route, params] = this.convertViewToParams(to);

        this.redirect(route, params);

        if (scrollToTop) {
            this.scrollToTop();
        }
    }

    private convertViewToParams(to: RouteViewType | RightHandSideViewType): [string | null | undefined, ParamsType] {
        if ('account' in to) {
            return [undefined, to];
        }

        if (to.name === 'sport') {
            if (to.nameType === 'races') {
                const { name, nameType: nameType_, event, ...rest } = to;
                const rest2: ParamsType = {
                    ...rest,
                    eventId: event?.id,
                    slug: event?.slug,
                };
                return [name, rest2];
            }

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (to.nameType === 'regular') {
                const { name, nameType: nameType_, ...rest } = to;
                const rest2: ParamsType = { ...rest };
                return [name, rest2];
            }

            return assertNever('nameType', to);
        }

        if (to.name === 'racecard') {
            if ('racecardBuildIds' in to) {
                const { name, racecardBuildIds, ...rest } = to;
                const rest2: ParamsType = {
                    racecardBuildIds: racecardBuildIds.join('-'),
                    ...rest,
                };
                return [name, rest2];
            }

            const { name, ...rest } = to;
            const rest2: ParamsType = { ...rest };

            return [name, rest2];
        }

        const { name, ...rest } = to;
        const restParams: ParamsType = { ...rest };
        return [name, restParams];
    }
}
