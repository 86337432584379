import * as React from 'react';
import { LogoContainerStyled } from './LogoContainer.style';
import { observer } from 'src/utils/mobx-react';

interface PropsType {
    children: JSX.Element;
    to?: string;
    type?: 'primary' | 'secondary';
}

export const LogoContainer = observer('LogoContainer', ({ children, to, type = 'primary' }: PropsType): JSX.Element => {
    return (
        <LogoContainerStyled
            to={to}
            type={type}
        >
            {children}
        </LogoContainerStyled>
    );
});
