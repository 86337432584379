import styled from '@emotion/styled';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { withConfig } from 'src/withConfig';

export const OverlayDefault = withConfig(theme => styled('div', { label: 'OverlayDefault' })`
    align-items: center;
    background: ${theme.star.utilityPage.bgColor};
    color: ${theme.star.utilityPage.txtColor};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 0 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);

export const Content = styled('div', { label: 'Content' })`
    max-height: 100%;
    max-width: 352px;
    margin: 0 24px 24px 24px;
    position: relative;
    min-width: 288px;
    text-align: center;
`;

export const PopupHeader = withConfig(theme => styled('div', { label: 'PopupHeader' })`
    margin: 24px 0 16px 0;
    font-weight: ${theme.star.fontWeight.bold};
`);

export const PopupDescription = withConfig(theme => styled('p', { label: 'PopupDescription' })`
    margin: 0 0 16px 0;
    font-size: ${theme.star.fontSize.small};
    line-height: 1.1667;
`);

export const AcceptBtn = styled(Button, { label: 'AcceptBtn' })`
    width: 100%;
    margin-bottom: 5px;
`;

export const BtnWrapper = styled('div', { label: 'BtnWrapper' })`
    text-align: center;
`;

export const LinkWrapper = withConfig(theme => styled(Link, { label: 'LinkWrapper' })`
    color: ${theme.star.staticPage.txtColor};
    cursor: pointer;
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;

    &:hover {
        color: ${theme.star.utilityPage.txtColor};
    }
`);

export const TermsBox = withConfig(theme => styled('div', { label: 'TermsBox' })`
    background: ${theme.star.signUp.bgColorSecondary};
    height: 185px;
    margin-bottom: 16px;
    padding: 8px;
    position: relative;
`);


export const TermsBoxInner = withConfig(theme => styled('div', { label: 'TermsBoxInner' })`
    font-size: ${theme.star.fontSize.xSmall};
    inset: 8px 6px 8px 8px;
    position: absolute;

    > div {
        padding: 0 8px 0 0 !important;
        overflow-y: auto;

        scrollbar-color: ${theme.star.buttons.primary.bgColorSecondary} transparent;
        scrollbar-width: thin;

        /* safari support */
        ::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: ${theme.star.buttons.primary.bgColorSecondary};
        }
    }

    > p {
        line-height: 1.1667;
    }
`);
