import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { LoginResponseType, Session } from 'src_common/sdk/session';

export const loginUser = async (
    session: Session,
    disable_geo: true | undefined,
    email: string,
    password: string,
    googleTagManager: GoogleTagManagerState
): Promise<LoginResponseType> => {
    const loginStatus = await session.loginCustomer(email, password, disable_geo ?? false);
    if (loginStatus.type !== 'CreateSessionResponseOk') {
        return loginStatus;
    }

    const accountId = loginStatus.accountId;

    googleTagManager.loginTag(accountId);

    return loginStatus;
};
