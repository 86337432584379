import styled from '@emotion/styled';
import { withConfig } from 'src/withConfig';

export const RaceHeaderWrapper = withConfig(theme => styled('div', { label: 'RaceHeaderWrapper' })`
    background: ${theme.star.raceCardColors.bgColorHeaderDetails};
    border-color: ${theme.star.raceCardColors.borderColorHeaderDetails};
    border-style: solid;
    border-width: 1px 1px 0 1px;
    position: relative;
`);


export const RaceHeaderDetails = styled('header', { label: 'RaceHeaderDetails' })`
    padding: 6px 8px 8px;
`;

interface RaceHeaderPropsType {
    isFinishedRace: boolean;
}

export const RaceHeaderDetailsHeader = withConfig(theme => styled('h4', { label: 'RaceHeaderDetailsHeader' })<RaceHeaderPropsType>`
    align-items: center;
    color: ${({ isFinishedRace }): string => isFinishedRace ? theme.star.raceCardDetails.txtColor : 'inherit'};
    display: flex;
    font-size: ${theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 0;
    @media ${theme.star.mediaQuery.tablet} {
        font-size: ${theme.star.fontSize.xMedium};
        line-height: 1.4;
    }
`);

export const RaceHeaderDetailsDistance = withConfig(theme => styled('div', { label: 'RaceHeaderDetailsDistance' })<RaceHeaderPropsType>`
    color: ${({ isFinishedRace }): string => isFinishedRace ? theme.star.raceCardDetails.txtColor : theme.star.raceCardColors.txtColorMeta};
    display: flex;
    flex-flow: row wrap;
    margin-top: 3px;
`);

export const RaceWeatherImgWrapper = styled('img', { label: 'RaceWeatherImgWrapper' })`
    height: 40px;
    margin-right: 6px;
`;
