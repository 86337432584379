import React from 'react';
import { Icon, IconContainer, TooltipContainer, TooltipContent, TooltipTriangle } from './TooltipSecondary.style';
import { observer } from 'src/utils/mobx-react';
import { useCommon } from 'src/domains/common/Common';

type TooltipPropsType = {
    content: React.ReactNode;
    isError?: boolean;
};
export const TooltipSecondary = observer('TooltipSecondary', ({ content, isError }: TooltipPropsType): JSX.Element => {
    const common = useCommon();
    const tooltipVersion = common.features.layoutOptions.tooltipVariant;

    return (
        <TooltipContainer>
            <IconContainer>
                <Icon />
            </IconContainer>
            <TooltipTriangle
                className='tooltip-triangle'
                isError={isError}
            />
            <TooltipContent
                className='tooltip-content'
                isError={isError}
                version={tooltipVersion}
            >
                {content}
            </TooltipContent>
        </TooltipContainer>
    );
});
