import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { ChevronIcon } from 'src/domains/layouts/shared/assets/icons/ChevronIcon';
import { withConfig } from 'src/withConfig';

interface SeoBoxType {
    isCasino: boolean;
}

const setSeoColors = (isCasino: boolean, theme: EmotionTheme): SerializedStyles => {
    if (isCasino) {
        return css`
            border-color: ${theme.star.seoBox.casino.borderColor};
            color: ${theme.star.seoBox.casino.txtColor};
        `;
    }
    return css`
        border-color: ${theme.star.seoBox.borderColor};
        background-color: ${theme.star.seoBox.bgColor};
    `;
};

export const SeoContentWrapper = withConfig(theme => styled('div', { label: 'SeoContentWrapper' })<SeoBoxType>`
    ${({ isCasino }): SerializedStyles => setSeoColors(isCasino, theme)};
    border-style: solid;
    border-width: 1px;
    margin-top: -1px;
    padding: 0 8px;
`);

interface SeoContentTitlePropsType {
    isFallback?: boolean;
}

export const SeoContentTitle = withConfig(theme => styled('h1', { label: 'SeoContentTitle' })<SeoContentTitlePropsType>`
    font-size: ${({ isFallback }): string => isFallback === true ? theme.star.fontSize.xRegular : theme.star.fontSize.regular};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.5;
    margin: 8px 0;
`);

export const SeoContent = withConfig(theme => styled('div', { label: 'SeoContent' })`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.3333;
    margin-bottom: 8px;

    ul {
        list-style: disc;
        padding-left: 16px;

        ul {
            list-style: circle;
        }
    }

    h2, h3 {
        font-size: ${theme.star.fontSize.xRegular};
    }

    p {
        font-size: ${theme.star.fontSize.small};
    }
`);

interface ShowMoreWrapperPropsType {
    isShowMore: boolean;
    isCasino: boolean;
}

export const ShowMoreWrapper = withConfig(theme => styled('div', { label: 'ShowMoreWrapper' }) <ShowMoreWrapperPropsType>`
    color: ${({ isCasino }): string => isCasino ? theme.star.seoBox.casino.txtColorSecondary : theme.star.seoBox.txtColor};
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: ${({ isShowMore }): string => isShowMore ? '16px 0' : '16px 0 8px 0'};
`);

export const Arrow = styled(ChevronIcon, { label: 'Arrow' })`
    width: 12px;
    fill: currentcolor;
`;

export const ShowMoreOrLess = withConfig(theme => styled('span', { label: 'ShowMoreOrLess' })`
    display: inline-block;
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.bold};
    line-height: 1.4285;
    margin-left: 8px;
`);
