import { action, computed, observable, makeObservable } from 'mobx';
import { Response200Type } from 'src/api_openapi/v2/bonus-engine/apiGetCampaign';
import { Common } from 'src/domains/common/Common';
import { CustomKeyboardState } from 'src/domains/players/state/CustomKeyboardState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { AutoMap } from 'src_common/common/mobx-utils/AutoMap';
import { Resource } from 'src_common/common/mobx-utils/Resource';

const STATIC_FREE_SPINS_TERMS_SLUG = 'default-free-spins-terms-and-conditions';

type TermsToLoadType =
    | {
          type: 'static-free-spins';
      }
    | {
          type: 'campaign';
          id: string;
      }
    | null;

type CreditType = 'free-bets' | 'free-spins';

export class TermsDrawerState {
    @observable public displayPage: boolean = false;
    @observable public termsToLoad: TermsToLoadType = null;
    private campaignsMap: AutoMap<string, Resource<Response200Type | null>>;

    public constructor(
        private readonly common: Common,
        private readonly customKeyboard: CustomKeyboardState,
        private readonly usersState: UsersState
    ) {
        makeObservable(this);
        this.campaignsMap = new AutoMap(
            (id: string) =>
                new Resource(async (): Promise<Response200Type | null> => {
                    const data = await this.common.trpcClient.client.bonusEngineRouter.getCampaign.mutate({
                        id: id,
                    });
                    if (data.status === 200) {
                        return data.body;
                    }
                    return null;
                })
        );
    }

    @computed public get displayTermsPage(): boolean {
        return this.displayPage && this.terms !== null && this.terms !== '';
    }

    @action public showTermsPage = (creditType: CreditType, id: string | null | undefined): void => {
        if (id === null || id === undefined) {
            if (creditType === 'free-spins') {
                this.getCampaignTC({ type: 'static-free-spins' });
            }

            if (creditType === 'free-bets') {
                return;
            }
        } else {
            this.getCampaignTC({ type: 'campaign', id: id });
        }

        this.displayPage = true;

        if (this.customKeyboard.asideElement !== null) {
            this.customKeyboard.asideElement.scrollTo(0, 0);
        }
    };

    @action public getCampaignTC = (termsToLoad: TermsToLoadType): void => {
        this.termsToLoad = termsToLoad;
    };

    @computed public get terms(): string | null {
        if (this.termsToLoad === null) {
            return null;
        }

        switch (this.termsToLoad.type) {
            case 'static-free-spins': {
                return this.staticFreeSpinsTerms;
            }
            case 'campaign': {
                return this.campaignTerms;
            }
        }
    }

    @computed private get staticFreeSpinsTerms(): string | null {
        const newPage = this.usersState.getPage(STATIC_FREE_SPINS_TERMS_SLUG);

        if (newPage.type === 'ready') {
            return newPage.value?.content ?? null;
        }

        if (newPage.type === 'loading') {
            return null;
        }
        return null;
    }

    @computed private get campaignTerms(): string | null {
        if (this.termsToLoad === null || this.termsToLoad.type === 'static-free-spins') {
            return null;
        }

        const newPage = this.campaignsMap.get(this.termsToLoad.id).get();
        if (newPage.type === 'ready') {
            return newPage.value?.description ?? null;
        }

        if (newPage.type === 'loading') {
            return null;
        }
        return null;
    }

    @action public closePage = (): void => {
        this.displayPage = false;
    };
}
