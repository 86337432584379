import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { LoadingWrapper } from 'src/domains/layouts/shared/loaders';
import {
    BannerContainer,
    BannerButton,
    BannerText,
    BannerWrapper,
    BannerHeader,
    IdentityWrapper,
} from './BannerBox.style';
import { BannerBoxState } from './BannerBox.state';
import { InfoIcon } from 'src/domains/layouts/shared/assets/icons/InfoIcon';

type BannerType = 'srgBanner' | 'idMissionBanner';

interface PropsType {
    bannerType: BannerType;
}

export const BannerBox = observer('BannerBox', ({ bannerType }: PropsType) => {
    const {
        appPlayersState: { usersState, identityState },
    } = useAppStateContext();
    const [state] = useState<BannerBoxState>(() => new BannerBoxState());

    const handleClick = (): void => {
        if (bannerType === 'srgBanner') {
            usersState.toggleSrgModal();
        } else if (identityState !== null) {
            identityState.toggleIdMissionPopup();
        }
    };

    const isSrgBanner = bannerType === 'srgBanner';

    if (isSrgBanner) {
        return (
            <LoadingWrapper computedHeight={state.boxHeight}>
                <BannerWrapper ref={state.setRef}>
                    <InfoIcon />
                    <BannerContainer>
                        <BannerHeader>
                            <I18n
                                langKey='srg-reopen.box.header'
                                defaultText='Customer Interaction Questionnaire'
                            />
                        </BannerHeader>
                        <BannerText>
                            <I18n
                                langKey='srg-reopen.box.message'
                                defaultText='Please complete the remaining questions in order to activate your account.'
                            />
                        </BannerText>
                    </BannerContainer>

                    <BannerButton
                        onClick={handleClick}
                        size='small'
                        dataTest='srg-reopen-button'
                    >
                        <I18n
                            langKey='srg-reopen.box.button'
                            defaultText='Open Questionnaire'
                        />
                    </BannerButton>
                </BannerWrapper>
            </LoadingWrapper>
        );
    } else {
        return (
            <LoadingWrapper computedHeight={state.boxHeight}>
                <BannerWrapper ref={state.setRef}>
                    <IdentityWrapper>
                        <BannerContainer>
                            <BannerHeader>
                                <I18n
                                    langKey='id-mission.box.header'
                                    defaultText='Identity Verification Required'
                                />
                            </BannerHeader>
                            <BannerText>
                                <I18n
                                    langKey='id-mission.box.message'
                                    defaultText='Please verify your identity to continue using your account.'
                                />
                            </BannerText>
                        </BannerContainer>

                        <BannerButton
                            onClick={handleClick}
                            size='small'
                            dataTest='id-mission-button'
                        >
                            <I18n
                                langKey='id-mission.box.button'
                                defaultText='Verify Now'
                            />
                        </BannerButton>
                    </IdentityWrapper>
                </BannerWrapper>
            </LoadingWrapper>
        );
    }
});
