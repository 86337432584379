import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    DetailsItem,
    DetailsItemEmpty,
    HeadingRaceDetails,
    HeadingRaceDetailsWrapper,
    RaceDetailsListItem,
    RaceDetailsName,
    RaceDetailsWrapper,
    RaceSummaryRowDetailsWrapper,
    RunnerCountWrapper,
} from 'src/domains/sportsbook/webview/components/raceSummary/raceSummaryRowSport/RaceSummaryRowDetails.style';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { formatAsOrdinalSuffix } from 'src_common/utils/format/formatData';

interface RaceSummaryRowDetailsPropsType {
    selectionModel: SelectionModel;
}

const isValidDetail = (detail?: string | null): detail is string => {
    return detail !== undefined && detail !== null && detail.trim() !== '';
};

export const RaceSummaryRowDetails = observer(
    'RaceSummaryRowDetails',
    ({ selectionModel }: RaceSummaryRowDetailsPropsType) => {
        const runnerComment = selectionModel.metaDataRunnerComment;
        const racingFormDetails = selectionModel.computedRacingFormDetails ?? [];

        return (
            <RaceSummaryRowDetailsWrapper>
                <HeadingRaceDetailsWrapper>
                    <HeadingRaceDetails>Last Run</HeadingRaceDetails>
                    <span>{runnerComment}</span>
                </HeadingRaceDetailsWrapper>

                {racingFormDetails.length > 0 ? (
                    <ul>
                        {racingFormDetails.map((detail, idx) => {
                            const {
                                finishPosition,
                                runnersCount,
                                courseName,
                                date,
                                distance,
                                handicap,
                                courseType,
                                startingPrice,
                            } = detail;

                            const formattedPosition =
                                finishPosition === undefined ? 'N/A' : formatAsOrdinalSuffix(finishPosition);

                            return (
                                <RaceDetailsListItem key={idx}>
                                    <RunnerCountWrapper>
                                        <b>{formattedPosition}</b>/{runnersCount}
                                        <RaceDetailsName>{courseName}</RaceDetailsName>
                                    </RunnerCountWrapper>

                                    <RaceDetailsWrapper>
                                        <DetailsItem title='Date'>{isValidDetail(date) ? date : 'N/A'}</DetailsItem>
                                        <DetailsItem title='Distance'>
                                            {isValidDetail(distance) ? distance : 'N/A'}
                                        </DetailsItem>
                                        {handicap === true && <DetailsItem title='Handicap'>Handicap</DetailsItem>}
                                        <DetailsItem title='Course type'>
                                            {isValidDetail(courseType) ? courseType : 'N/A'}
                                        </DetailsItem>
                                        <DetailsItem title='SP'>
                                            {isValidDetail(startingPrice) ? startingPrice : 'N/A'}
                                        </DetailsItem>
                                    </RaceDetailsWrapper>
                                </RaceDetailsListItem>
                            );
                        })}
                    </ul>
                ) : (
                    <DetailsItemEmpty>No racing form details available.</DetailsItemEmpty>
                )}
            </RaceSummaryRowDetailsWrapper>
        );
    }
);
