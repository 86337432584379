import { css, SerializedStyles } from '@emotion/react';

import styled from '@emotion/styled';

import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';

import { NotRunningPriceViewType } from 'src/domains/sportsbook/webview/components/raceSummary/RaceSummaryRow';

import { withConfig } from 'src/withConfig';



interface PropsType {

    isRaceInProgress?: boolean;

}



export const RaceHeaderListMeta = styled('ul', { label: 'RaceHeaderListMeta' })``;



export const RaceHeaderListItemMeta = withConfig(theme => styled('li', { label: 'RaceHeaderListItemMeta' })`

    display: inline-block;

    font-weight: ${theme.star.fontWeight.medium};

    line-height: 1.3334;

    margin: 0 5px 0 0;

    &:last-of-type {

        margin-right: 0;

    }

`);



export const RaceHeaderListItemSeparatorMeta = styled(RaceHeaderListItemMeta, { label: 'RaceHeaderListItemSeparatorMeta' })`

    position: relative;

    width: 1px;

    &:last-of-type {

        display: none;

    }

    &::after {

        background-color: currentcolor;

        bottom: 0;

        content: '';

        height: 100%;

        left: 0;

        margin: auto;

        position: absolute;

        right: 0;

        top: 0;

        width: 1px;

    }

`;



export const RaceCardFeed = withConfig(theme => styled('div', { label: 'RaceCardFeed' })`

    align-items: center;

    display: flex;

    font-weight: ${theme.star.fontWeight.medium};

`);





export const RaceSummary = withConfig(theme => styled('div', { label: 'RaceSummary' })`

    font-size: ${theme.star.fontSize.small};

    line-height: 1.3;

    margin-top: -1px;

    &:first-of-type {

        margin-top: -1px;

    }

`);



interface RaceSummaryNoHistoryPropsType {

    isPriceHistoryHidden: boolean;

}



export const RaceSummaryNoHistory = styled(RaceSummary, { label: 'RaceSummaryNoHistory' })<RaceSummaryNoHistoryPropsType>`

    .race-summary__selection__price-history { /* Please remove after component refactor */

        ${({ isPriceHistoryHidden }): string => isPriceHistoryHidden ? 'display: none;' : ''}

    }

`;



export const RaceSummaryMeta = styled('div', { label: 'RaceSummaryMeta' })`

    text-align: right;

    padding: 0 10px;

    /* To refactor */

    li {

        vertical-align: middle;

        display: inline-block;

        margin-right: 5px;

        &:last-of-type {

            margin-right: 0;

        }

    }

`;



/* Table caption and cells */

export const RaceSummaryCaption = withConfig(theme => styled('li', { label: 'RaceSummaryCaption' })`

    background: ${theme.star.raceSummary.bgColorSecondary};

    border: 1px solid ${theme.star.raceSummary.borderColor};

    color: ${theme.star.raceSummary.txtColorTertiary};

    display: flex;

    flex-flow: row nowrap;

    font-size: ${theme.star.fontSize.xSmall};

    justify-content: space-between;

    line-height: 1.4;

`);



export const setRaceCell = (theme: EmotionTheme): SerializedStyles => css`

    align-items: center;

    border-right: 1px solid ${theme.star.raceSummary.borderColor};

    display: flex;

    font-size: ${theme.star.fontSize.xSmall};

    justify-content: center;

`;



export const PlaceCaption = withConfig(theme => styled('span', { label: 'PlaceCaption' })`

    ${setRaceCell(theme)};

    flex: 0 0 24px;

    text-align: center;

    @media ${theme.star.mediaQuery.tablet} {

        flex: 0 0 32px;

    }

`);



export const PlaceCell = withConfig(theme => styled(PlaceCaption, { label: 'PlaceCell' })`

    border-right: 1px solid ${theme.star.raceSummary.borderColor};

    flex-flow: column nowrap;

`);



export const SilkCaption = withConfig(theme => styled('span', { label: 'SilkCaption' })`

    ${setRaceCell(theme)};

    flex: 0 0 40px;

    @media ${theme.star.mediaQuery.tablet} {

        flex: 0 0 65px;

    }

`);



export const SilkCell = withConfig(theme => styled(SilkCaption, { label: 'SilkCell' })`

    border-right: 1px solid ${theme.star.raceSummary.borderColor};

    img {

        width: 24px;

    }

    @media ${theme.star.mediaQuery.tablet} {

        padding: 17px 20px !important;

    }

`);



export const RunnerCaption = withConfig(theme => styled('div', { label: 'RunnerCaption' })`

    ${setRaceCell(theme)};

    flex: 1 1 0%;

    padding: 1px 8px;

    justify-content: flex-start;

`);



interface RunnerCellPropsType {

    isHideRunnerInfo?: boolean;

}



export const RunnerCell = withConfig(theme => styled(RunnerCaption, { label: 'RunnerCell' })<RunnerCellPropsType>`

    border-right: 1px solid ${theme.star.raceSummary.borderColor};

    align-items: flex-start;

    flex-flow: column nowrap;

    justify-content: center;

    min-width: 111px;

    i {

        ${({ isHideRunnerInfo }): string => isHideRunnerInfo === true ? 'display: none': ''};

    }

`);



export const RunnerCellDetails = withConfig(theme => styled(RunnerCaption, { label: 'RunnerCellDetails' })<RunnerCellPropsType>`

    border-right: 1px solid ${theme.star.raceSummary.borderColor};

    align-items: start;

    flex-flow: column nowrap;

    justify-content: center;

    min-width: 111px;

    padding: 14px 12px;

    i {

        ${({ isHideRunnerInfo }): string => isHideRunnerInfo === true ? 'display: none': ''};

    }

    @media ${theme.star.mediaQuery.tablet} {

        align-items: center;

        flex-direction: row;

        justify-content: start;

    }

`);





export const WeightCaption = withConfig(theme => styled('span', { label: 'WeightCaption' })`

    display: none !important;

    @media ${theme.star.mediaQuery.tablet} {

        ${setRaceCell(theme)};

        display: flex !important;

        flex: 0 0 64px;

        padding: 1px 2px !important;

        text-align: center;

    }

`);



export const WeightCell = withConfig(theme => styled(WeightCaption, { label: 'WeightCell' })`

    @media ${theme.star.mediaQuery.tablet} {

        border-right: 1px solid ${theme.star.raceSummary.borderColor};

    }

`);



export const SelectionCaption = withConfig(theme => styled('span', { label: 'SelectionCaption' })<PropsType>`

    ${setRaceCell(theme)};

    color: ${({ isRaceInProgress }): string => isRaceInProgress === true ? theme.star.raceCardColors.txtColorInProgressRace : 'inherit'};

    flex: 0 0 48px;

    padding: 1px 4px !important;

    text-align: center;

    margin-left: -1px;

    &:last-of-type {

        border-right: 0;

    }

    @media ${theme.star.mediaQuery.tablet} {

        flex: 0 0 64px;

    }

`);





export const PriceHistoryCaption = withConfig(theme => styled('span', { label: 'PriceHistoryCaption' })<PropsType>`

    ${setRaceCell(theme)};

    flex: 0 0 76px;

    padding: 0 !important;

    text-align: center;

    @media ${theme.star.mediaQuery.tablet} {

        flex: 0 0 160px;

    }

`);



export const PriceHistoryCell = withConfig(theme => styled(PriceHistoryCaption, { label: 'PriceHistoryCell' })`

    border-right: 1px solid ${theme.star.raceSummary.borderColor};

    overflow: hidden;

    padding: 4px !important;

`);



export const NonRunnerCell = withConfig(theme => styled('span', { label: 'NonRunnerCell' })<NotRunningPriceViewType>`

    ${setRaceCell(theme)};

    border-right: 1px solid ${theme.star.raceSummary.borderColor};

    flex: ${({ extraPlaces }): string => extraPlaces === true ? '0 0 64px' : '0 0 48px'};

    width: ${({ extraPlaces }): string => extraPlaces === true ? '64px' : '48px'};

    font-size: ${theme.star.fontSize.small};

    margin-left: -1px;

    @media ${theme.star.mediaQuery.tablet} {

        flex: 0 0 64px;

        width: 64px;

        font-size: ${theme.star.fontSize.xRegular};

    }

`);



export const NonRunnerCellMatchBet = withConfig(theme => styled(NonRunnerCell, { label: 'NonRunnerCellMatchBet' })`

    border-left: 1px solid ${theme.star.raceSummary.borderColor};

`);



interface RaceSummaryRowPropsType {

    isNonRunner?: boolean;

    tags?: React.ReactNode;

    isAlternativeTag?: boolean;

}



const setTags = (isAlternativeTag?: boolean, tags?: React.ReactNode): SerializedStyles | null => {

    if (tags === undefined || tags === null) {

        return null;

    }



    if (isAlternativeTag === true) {

        return css`

            margin-bottom: 18px;

        `;

    }



    return css`

        margin-bottom: 28px;

    `;

};



export const RaceSummaryRow = withConfig(theme => styled('li', { label: 'RaceSummaryRow' })<RaceSummaryRowPropsType>`

    ${({ isAlternativeTag, tags }): SerializedStyles | null => setTags(isAlternativeTag, tags)};

    background-color: ${theme.star.raceSummary.bgColor};

    color: ${theme.star.raceSummary.txtColor};

    border-bottom: 1px solid  ${theme.star.raceSummary.borderColor};

    display: flex;

    flex-flow: row nowrap;

    min-height: 48px;

    justify-content: space-between;

    opacity: ${({ isNonRunner }): number => isNonRunner === true ? 0.4 : 1};

    pointer-events: ${({ isNonRunner }): string => isNonRunner === true ? 'none' : 'initial'};

    user-select: ${({ isNonRunner }): string => isNonRunner === true ? 'none' : 'initial'};

    position: relative;

    @media ${theme.star.mediaQuery.tablet} {

        border-left: 1px solid  ${theme.star.raceSummary.borderColor};

        border-right: 1px solid  ${theme.star.raceSummary.borderColor};

        min-height: 56px;

    }

`);

