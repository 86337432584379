import React from 'react';
import { useCommon } from 'src/domains/common/Common';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { FiltersNewView, FiltersType } from 'src/domains/layouts/webview/components/filters/Filters';
import { FiltersScrollableWrapper } from 'src/domains/layouts/webview/components/filters/FiltersScrollableWrapper';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { HorseRacingSortOrderType } from 'src/domains/sportsbook/webview/components/raceCard/RaceCompetition.state';
import { HorseRacingDropdown } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/HorseRacingDropdown';
import { TabKeyType } from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/RaceDetails.types';
import {
    DetailsTableMessage,
    FilterContainer,
    MegaphoneIcon,
} from 'src/domains/sportsbook/webview/components/raceCard/raceDetails/TableMessageOrFilters.style';
import { observer } from 'src/utils/mobx-react';

interface TableMessageOrFiltersPropsType {
    isRaceInProgress: boolean;
    activeFilterId: TabKeyType | null;
    filters: Array<FiltersType>;
    shouldShowHorseRacingDropdown: boolean;
    sortOrder: HorseRacingSortOrderType;
    setSortOrder: (sort: HorseRacingSortOrderType) => void;
}

export const TableMessageOrFilters = observer('TableMessageOrFilters', (props: TableMessageOrFiltersPropsType) => {
    const { isRaceInProgress, activeFilterId, filters, shouldShowHorseRacingDropdown, sortOrder, setSortOrder } = props;

    const common = useCommon();
    const configState = ConfigComponents.get(common).config;

    if (isRaceInProgress) {
        return (
            <DetailsTableMessage>
                <MegaphoneIcon />
                <I18n
                    langKey='events.racecard.table-message.race-in-progress'
                    defaultText='Race is in progress'
                />
            </DetailsTableMessage>
        );
    }
    return (
        <FilterContainer>
            <FiltersScrollableWrapper>
                <FiltersNewView
                    select={activeFilterId}
                    skin={configState.filtersVersion.raceCardMarketTab}
                    filters={filters}
                />
            </FiltersScrollableWrapper>

            <HorseRacingDropdown
                shouldShowHorseRacingDropdown={shouldShowHorseRacingDropdown}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
            />
        </FilterContainer>
    );
});
