import * as t from 'io-ts';
// eslint-disable-next-line
import { createGuard } from '../createGuard';
import { ParticipantModelIO } from 'src_common/common/websocket2/modelsApi/Event';

const MarketIO = t.interface({
    market_name: t.string,
    participants: t.record(t.string, ParticipantModelIO),
    selections: t.array(t.interface({
        name: t.string,
        marketName: t.string,
    }))
});

export type ParticipantModelType = t.TypeOf<typeof ParticipantModelIO>

const MarketsIO = t.record(t.string, t.array(MarketIO));

export const isMarketsArray = createGuard(MarketsIO);
const findMatchingParts = (regExp: RegExp, text: string, parametersArray:Array<Record<string, string>>, id: string): string | null => {
    const matchParts = text.match(regExp);
    if (matchParts?.length === 1 && text === matchParts[0]) {
        return null;
    }
    if (matchParts !== null && matchParts.length > 0) {
        for (let i = 0; i < matchParts.length; i++) {
            const matchPart = matchParts[i]?.toString();

            if (matchPart !== undefined) {
                const keyName: string = `${id}${i+1}`;
                parametersArray.push( { [keyName]: matchPart } );
                text = text.replace(matchPart, `{${keyName}}`); 
            }
        }
    }
    return text;
};

const isLetter = (c: string | undefined): boolean => {
    if (c === undefined) {
        return false;
    }
    const n = c.charCodeAt(0);
    return (n >= 65 && n < 91) || (n >= 97 && n < 123);
};

const validateNumberInText = (text:string, parametersArray:Array<Record<string, string>>): string | null => {
    const numberRegEx = /[+-]{0,1}[0-9]+\.?[0-9]*/g;
    const indexPairs = [];
    let matchArr;
    let replacementCounter = 0;
    const initialText = text;
    //Find all matching expressions
    while (null !== (matchArr = numberRegEx.exec(initialText))) {
        indexPairs.push({ startIndex: matchArr.index, stopIndex: numberRegEx.lastIndex });
    }
    for (const indexPair of indexPairs) {
        const substring = initialText.substring(indexPair.startIndex, indexPair.stopIndex);
        if (substring === text){
            return null;
        }
        const numberParsed = parseFloat(substring);
        //Check previous and next character to no translate "1st" "3rd" etc.
        const checkIfNextCharIsLetter = isLetter(initialText[indexPair.stopIndex]);
        const checkIfPreviousCharIsLetter = isLetter(initialText[indexPair.startIndex - 1]);
        const isValidNumber = !isNaN(numberParsed) && checkIfNextCharIsLetter === false && checkIfPreviousCharIsLetter === false;

        if (isValidNumber) {
            const keyName: string = `d${replacementCounter + 1}`;
            parametersArray.push( { [keyName]: substring } );
            text = text.replace(substring, '{*}');       
            replacementCounter++; 
        }
    }

   
    for (let i = 0; i <= replacementCounter; i++) {
        text = text.replace('{*}', `{d${i + 1}}`);  
    }


    return text;
};

export const calculateTranslationParameters = (orginalText: string, participants: Record<string, ParticipantModelType>, type: 'market' | 'selection'): [string, Array<Record<string, string>>] | null => {
    let currentTranslation = orginalText.toLowerCase();
    const parametersArray: Array<Record<string, string>> = [];


    for (const participant in participants) {
        const name = participants[participant]?.name ?? null;
        const nameLowerCase = name?.toLowerCase() ?? null;
        const role = participants[participant]?.role ?? null;

        if (orginalText === name?.trim() && role === null){
            return null;
        }
        
        if (nameLowerCase !== null && name !== null && role !== null && orginalText.includes(name) && role === 'home') {

            currentTranslation = currentTranslation.replaceAll(nameLowerCase, '{pH}');
            parametersArray.push({ 'pH': name });
        }

        if (nameLowerCase !== null && name !== null && role !== null && orginalText.includes(name) && role === 'away') {
            currentTranslation = currentTranslation.replaceAll(nameLowerCase, '{pA}');
            parametersArray.push({ 'pA': name });
        }
    }

    const REGEXP_FRACTION = /\d+\/\d+/g;
    if (REGEXP_FRACTION.test(orginalText)){
        const translated = findMatchingParts(REGEXP_FRACTION, currentTranslation, parametersArray, 'f');
        if (translated === null){
            return null;
        }
        currentTranslation = translated;
    }

    const REGEXP_RANGE = /\d+[.]*\d*[ ]{0,1}-[ ]{0,1}\d+[.]*\d*/g;
    if (REGEXP_RANGE.test(orginalText)){
        const translated = findMatchingParts(REGEXP_RANGE, currentTranslation, parametersArray, 'r');
        if (translated === null){
            return null;
        }
        currentTranslation = translated;
    }


 
    const translated = validateNumberInText(currentTranslation, parametersArray);
    if (translated === null){
        return null;
    }

    currentTranslation = translated;
    
    if (type === 'market') {

        return [`_market.${currentTranslation}`, parametersArray];
    } else {
        return [`_selection.${currentTranslation}`, parametersArray];
    }

};
