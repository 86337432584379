import { z } from 'zod';


// #/components/schemas/identitySessionIdResponse
export const IdentitySessionIdResponseZod = z.object({
    id: z.string(),
});
export type IdentitySessionIdResponseType = z.TypeOf<typeof IdentitySessionIdResponseZod>;


// #/components/schemas/identityFinalizeRequest
export const IdentityFinalizeRequestZod = z.object({
    accountId: z.number(),
    universe: z.string(),
    rawIdmidssionResult: z.string(),
});
export type IdentityFinalizeRequestType = z.TypeOf<typeof IdentityFinalizeRequestZod>;


// #/components/schemas/identityEmptyResponse
export const IdentityEmptyResponseZod = z.unknown();
export type IdentityEmptyResponseType = z.TypeOf<typeof IdentityEmptyResponseZod>;


// #/components/schemas/identityVerificationProgress
export const IdentityVerificationProgressZod = z.union([z.literal('NotStarted'), z.literal('Initiated'), z.literal('Failed'), z.literal('Finished')]);
export type IdentityVerificationProgressType = z.TypeOf<typeof IdentityVerificationProgressZod>;


// #/components/schemas/identityVerificationVariant
export const IdentityVerificationVariantZod = z.union([z.literal('IdCard'), z.literal('IdCardProofOfAddress'), z.literal('IdCardAndFace'), z.literal('IdCardAndFaceProofOfAddress')]);
export type IdentityVerificationVariantType = z.TypeOf<typeof IdentityVerificationVariantZod>;


// #/components/schemas/identityVerificationProgressResponse
export const IdentityVerificationProgressResponseZod = z.object({
    id: z.number(),
    universe: z.string(),
    account_id: z.number(),
    progress: IdentityVerificationProgressZod,
    raw_result: z.unknown(),
    created_at: z.string(),
    finalized_at: z.string().optional(),
    verification_variant: IdentityVerificationVariantZod,
});
export type IdentityVerificationProgressResponseType = z.TypeOf<typeof IdentityVerificationProgressResponseZod>;

