import { action, makeObservable, observable } from 'mobx';

export class RaceSummaryRowHorseRacingNewState {
    @observable public expandedRaceId: string | null = null;

    public constructor() {
        makeObservable(this);
    }

    @action public handleDetailsClick = (key: string): void => {
        this.expandedRaceId = this.expandedRaceId === key ? null : key;
    };
}
