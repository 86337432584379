import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import {
    IssueSignUpInfo,
    IssueSignUpTitle,
    SignUpIssueWrapper,
    WarningIconBox,
    WarningIconWrapper,
} from './operatorTurnOffSignUp.style';
import {
    SignUpWrapper,
    SignUpWrapperOld,
    SignupCloseIconWrapper,
} from 'src/domains/players/webview/components/SignUp/signupParts/Signup.style';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';

interface OperatorTurnOffDepositsType {
    operatorName: string;
    date: string;
}

export const OperatorTurnOffSignUp = observer(
    'OperatorTurnOffSignUp',
    ({ operatorName, date }: OperatorTurnOffDepositsType) => {
        const {
            common,
            config: { accountHelperMail, noBetslip, accountDrawerNew },
            appPlayersState: { customKeyboard },
        } = useAppStateContext();
        const language = LanguagesState.get(common);
        const starRouter = StarRouter.get(common);

        const text = language.translateTokens(
            language.getTranslation(
                'account.sign-up.operator-turn-off.info.text--secondary',
                '[Attention][operatorName] will cease operations on [date] and is not accepting new registrations at this time.[br] For more information or assistance, please get in touch with us at [accountHelperMail] [br2] Thank you for your understanding.'
            ),
            (singleParam: LanguageTokenType) => {
                if (singleParam.tag === 'Attention') {
                    return (
                        <strong>
                            <I18n
                                langKey='account.sign-up.operator-turn-off.info.attention'
                                defaultText='Attention: '
                            />
                        </strong>
                    );
                }
                if (singleParam.tag === 'operatorName') {
                    return operatorName;
                }
                if (singleParam.tag === 'date') {
                    return date;
                }
                if (singleParam.tag === 'br' || singleParam.tag === 'br2') {
                    return <div></div>;
                }
                if (singleParam.tag === 'accountHelperMail') {
                    return <a href={`mailto: ${accountHelperMail}`}>{accountHelperMail}</a>;
                }
            }
        );

        const content = (
            <>
                <SignupCloseIconWrapper
                    layout='primary'
                    onClick={starRouter.closeAccount}
                    isWelcomePage={true}
                />
                <SignUpIssueWrapper>
                    <WarningIconBox>
                        <WarningIconWrapper />
                    </WarningIconBox>
                    <IssueSignUpTitle>
                        <I18n
                            langKey='account.sign-up.operator-turn-off.title'
                            defaultText='Sign-up is not available at this time'
                        />
                    </IssueSignUpTitle>
                    <IssueSignUpInfo>{text}</IssueSignUpInfo>
                </SignUpIssueWrapper>
            </>
        );

        if (accountDrawerNew) {
            return (
                <SignUpWrapper
                    ref={customKeyboard.setAsideElementRef}
                    data-test='SignUpStepsContainer'
                >
                    {content}
                </SignUpWrapper>
            );
        }

        return (
            <SignUpWrapperOld
                ref={customKeyboard.setAsideElementRef}
                shouldBeMoved={noBetslip === false}
                data-test='SignUpStepsContainer'
            >
                {content}
            </SignUpWrapperOld>
        );
    }
);
