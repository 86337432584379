import * as React from 'react';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import {
    PriceLegend,
    PriceLegendItem,
    PriceLegendWrapper,
    InPlayRedirect,
    InPlayRedirectWrapper,
} from './PriceChangeLegend.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';

interface PropsType {
    isRace: boolean;
}

export const PriceChangeLegend = observer('PriceChangeLegend', ({ isRace }: PropsType): React.ReactElement | null => {
    const { appLayoutsState } = useAppStateContext();
    const { configComponents, env, languagesState } = appLayoutsState;
    const { config } = configComponents;

    const getTranslation = languagesState.getTranslation;
    const theme = env.emotionTheme;

    const PRICE_LEGEND_LIST = [
        {
            label: getTranslation('special-box.legend.selected', 'Selected'),
            type: 'betslip',
            color: theme.star.priceLegendList.statusFirst,
        },
        {
            label: getTranslation('special-box.legend.price-going-out', 'Price going out (Drifting)'),
            type: 'drifting',
            color: theme.star.priceLegendList.statusSecond,
        },
        {
            label: getTranslation('special-box.legend.price-coming-in', 'Price coming in (Shortening)'),
            type: 'shortening',
            color: theme.star.priceLegendList.statusThird,
        },
        {
            label: getTranslation('special-box.legend.suspended', 'Suspended'),
            type: 'suspended',
            color: theme.star.priceLegendList.statusFourth,
        },
    ];

    return (
        <PriceLegendWrapper>
            <PriceLegend>
                {PRICE_LEGEND_LIST.map((listItem) => {
                    return (
                        <PriceLegendItem
                            itemColor={listItem.color}
                            key={listItem.type}
                        >
                            {listItem.label}
                        </PriceLegendItem>
                    );
                })}
            </PriceLegend>
            {config.hasInPlayDelayInformation && isRace === false ? (
                <InPlayRedirectWrapper>
                    <I18n
                        langKey='events.in-play-delay-information'
                        defaultText='In-play information may be subject to delay.'
                    />
                    <InPlayRedirect to={{ account: 'static', static: 'in-play' }}>
                        <I18n
                            langKey='events.in-play-delay-information.button.more'
                            defaultText='More'
                        />
                    </InPlayRedirect>
                </InPlayRedirectWrapper>
            ) : null}
        </PriceLegendWrapper>
    );
});
