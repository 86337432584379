import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { action, computed, observable, makeObservable } from 'mobx';
import { getErrorByCode } from 'src/domains/layouts/webview/components/common/errorMessage/errors';
import { VerifyAccountState } from 'src/domains/players/state/VerifyAccountState';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { FormModel, Result } from 'src_common/common/mobx-utils/Form2/FormModel';
import { validateEmailOrTelebettingID, validateRequire } from 'src/domains/players/webview/components/ValidatorsNew';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ModalState } from 'src/domains/layouts/webview/components/modals/Modal.state';
import { assertNever } from 'src_common/common/assertNever';
import { Common } from 'src/domains/common/Common';

const NUMBER_MAP = new Map([
    [2, 'Second'],
    [3, 'Third'],
    [4, 'Fourth'],
    [5, 'Fifth'],
    [6, 'Sixth'],
    [7, 'Seventh'],
    [8, 'Eighth'],
    [9, 'Ninth'],
    [10, 'Tenth'],
]);

interface PlayersCallbacksType {
    whenUserLogin: () => void;
}

export interface LoginFormModelPropsType {
    email: string;
    password: string;
}

const toLowerCase = (value: string): Result<string> => {
    return Result.createOk(value.trim().toLowerCase());
};

export const mapErrorLabel = (common: Common, message: string, failedLoginLimit?: number | null): string => {
    const language = LanguagesState.get(common);
    const config = ConfigComponents.get(common);

    const messageTxt = language.getTranslation('login.error.suspended-account', 'Your account is suspended');

    if (message === 'Invalid username or password') {
        return language.getTranslation(
            'login.error.invalid-username-or-password',
            'Looks like you are having some issues with login! Try again or use the Forgot password for help. {failedLoginLimit} failed attempt will suspend your account.',
            { failedLoginLimit: NUMBER_MAP.get(failedLoginLimit ?? 3) ?? '' }
        );
    }

    if (message === 'Account status: suspended - too many attempts') {
        return language.getTranslation(
            'login.error.invalid-username-or-password.to-many-attempts',
            'Your account has been suspended for security reasons. Please contact Customer Support at {accountHelperMail}',
            { accountHelperMail: config.config.accountHelperMail }
        );
    }

    if (message === 'Account status: suspended') {
        return messageTxt;
    }

    if (message === 'Account status: blocked') {
        return messageTxt;
    }

    if (message === 'Account status: closed') {
        return messageTxt;
    }

    if (message === 'GamStop validation failed') {
        return messageTxt;
    }

    return message;
};

export class StarLoginState {
    private readonly router: StarRouter;

    @observable public loginErrorMsg: string | null = null;

    @observable public isShowPassword: boolean = false;

    public readonly emailState: FormInputState<string, string>;

    public readonly passwordState: FormInputState<string, string>;

    public loginFormModel: FormModel<LoginFormModelPropsType>;
    public readonly verifyAccountState: VerifyAccountState;

    @observable public inProgress: boolean;

    public constructor(
        private readonly common: Common,
        router: StarRouter,
        private readonly callbacks: PlayersCallbacksType,
        private readonly modalState: ModalState,
        public readonly accountState: AccountState,
        public readonly config: ConfigComponents
    ) {
        makeObservable(this);
        this.verifyAccountState = VerifyAccountState.get(common);
        this.router = router;

        this.emailState = FormInputState.new('')
            .map(validateRequire)
            .map(toLowerCase)
            .map(validateEmailOrTelebettingID);

        this.passwordState = FormInputState.new('').map(validateRequire);

        this.loginFormModel = FormModel.group({
            email: this.emailState,
            password: this.passwordState,
        });

        this.inProgress = false;
    }

    @computed public get isFormValid(): boolean {
        return this.emailState.result.value.type === 'ok' && this.passwordState.result.value.type === 'ok';
    }

    public mapErrorLabel = (message: string, failedLoginLimit?: number | null): string => {
        return mapErrorLabel(this.common, message, failedLoginLimit);
    };

    private loginUserRequest = async (data: { email: string; password: string }): Promise<void> => {
        const response = await this.accountState.loginUser(data.email, data.password);

        switch (response.type) {
            case 'CreateSessionResponseOk': {
                this.loginFormModel.reset();
                await this.modalState.closeAll();
                this.callbacks.whenUserLogin();
                break;
            }
            case 'CreateSessionResponseErrors': {
                this.setLoginErrorMsg(this.mapErrorLabel(response.error_description, response.failedLoginLimit));
                break;
            }
            case 'CreateSessionResponseErrorAccess': {
                this.verifyAccountState.setEmail(data.email);
                this.router.redirectToVerifyAccount();

                break;
            }
        }
    };

    @action public logIn = async (): Promise<void> => {
        if (this.inProgress) {
            console.warn('logIn in progress - ignore event');
            return;
        }

        this.inProgress = true;
        this.loginFormModel.setAsVisited();

        const result = this.loginFormModel.result;

        switch (result.value.type) {
            case 'ok': {
                const data = {
                    email: result.value.data.email,
                    password: result.value.data.password,
                };

                try {
                    await this.loginUserRequest(data);
                } catch (err) {
                    console.error(err);
                    this.setLoginErrorMsg(getErrorByCode('ERROR_UNKNOWN'));
                }
                break;
            }
            case 'error': {
                this.setLoginErrorMsg(result.errors()[0] ?? '');
                break;
            }
            default:
                assertNever('Result value: ', result.value);
        }

        this.inProgress = false;
    };

    @action public setLoginErrorMsg = (loginErrorMsg: string): void => {
        this.loginErrorMsg = loginErrorMsg;
    };

    @action public resetLoginErrorMsg = (): void => {
        this.loginErrorMsg = null;
    };

    @action public resetState = (): void => {
        this.loginFormModel.reset();
        this.resetLoginErrorMsg();
    };
    @action public setShowPassword = (): void => {
        this.isShowPassword = !this.isShowPassword;
    };
    @computed public get emailToVerifyAccount(): string | null {
        return this.verifyAccountState.email;
    }

    @action public logOut = async (e?: React.SyntheticEvent): Promise<void> => {
        this.router.emitEventLogoutClick();
        const { isLogoutDontMoveToLoginPage } = this.router;

        if (e !== undefined) {
            e.preventDefault();
        }

        await this.accountState.handleLogout();

        if (isLogoutDontMoveToLoginPage) {
            this.router.redirectToHomepage();
        } else {
            this.router.redirectToLoginHomepage();
        }
    };
}
