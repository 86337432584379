//The contents of this file have been generated automatically. Do not edit this file.

import { z } from 'zod';
import {
    IdentityVerificationProgressResponseZod,
} from './schemas';

export const ParamsTypeZOD = z.object({
    account_id: z.number(),
});
export type ParamsType = z.TypeOf<typeof ParamsTypeZOD>;

const checkResponse = <A>(code: number, decoder: z.ZodType<A>, data: unknown): A => {
    const decodeResult = decoder.safeParse(data);
    if (decodeResult.success) {
        return decodeResult.data;
    }
    console.error('error details', {
        errors: decodeResult.error.errors,
        data
    });
    throw Error(`Response decoding error /identity/v1/verify/progress/{account_id} -> get -> ${code}`);
};


export const Response200ZOD = IdentityVerificationProgressResponseZod;
export type Response200Type = z.TypeOf<typeof Response200ZOD>;

export const Response400ZOD = z.string();
export type Response400Type = z.TypeOf<typeof Response400ZOD>;

export const Response404ZOD = z.string();
export type Response404Type = z.TypeOf<typeof Response404ZOD>;

export const Response500ZOD = z.unknown();
export type Response500Type = z.TypeOf<typeof Response500ZOD>;


const ExtraHeadersZod = z.object({
    authorization: z.string().optional(),
});
type ExtraHeadersType = z.TypeOf<typeof ExtraHeadersZod>;


export type ApiVerifyProgressParamsType = ParamsType;


export type ApiVerifyProgressResponseType = 
    {
        status: 200;
        body: Response200Type;
    } | 
    {
        status: 400;
        body: Response400Type;
    } | 
    {
        status: 404;
        body: Response404Type;
    } | 
    {
        status: 500;
        body: Response500Type;
    };

export type ApiVerifyProgressResponse200Type = Response200Type;

const convertHeaders = (params: Record<string, string | null | undefined>): Record<string, string> => {
    const result: Record<string, string> = {};

    for (const [name, value] of Object.entries(params)) {
        if (value !== null && value !== undefined) {
            result[name] = value;
        }
    }

    return result;
};

export const apiVerifyProgressRequest = async (
    api_url: string,
    params: ParamsType,
    extraHeaders: ExtraHeadersType
): Promise<ApiVerifyProgressResponseType> => {
    const ok = ParamsTypeZOD.safeParse(params);
    if (!ok.success) {
        console.error('Input parameters do not match the definition from openapi');
    }

    
        
    const query = '';
    const url = `${api_url}/identity/v1/verify/progress/${params.account_id}${query}`;
    

    const headers = convertHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        ...extraHeaders
    });

    const response = await fetch(url, {
        method: 'GET',
        headers,
        body: JSON.stringify(undefined),
        credentials: 'include'
    });

    const status = response.status;

    let json = null;

    try {
        const text = await response.text();
        if (text !== '') {
            json = JSON.parse(text);
        }
    } catch (_err) {
        throw Error(`Http status ${status} - json was expected`);
    }
    
    if (status === 200) {
        return { status: 200, body: checkResponse(200, Response200ZOD, json) };
    }

    if (status === 400) {
        return { status: 400, body: checkResponse(400, Response400ZOD, json) };
    }

    if (status === 404) {
        return { status: 404, body: checkResponse(404, Response404ZOD, json) };
    }

    if (status === 500) {
        return { status: 500, body: checkResponse(500, Response500ZOD, json) };
    }

    throw new Error(`apiVerifyProgressRequest - unhandled response ${status}`);
};
