import React from 'react';
import { AppCasinoState } from 'src/domains/casino/state/AppCasino.state';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { observer } from 'src/utils/mobx-react';
import { HeaderWrapper, HeaderTitle, CasinoIconWrapper } from './GridHeader.style';
import { PlayNowButton } from './play-now-button/PlayNowButton';

interface GridHeaderPropsType {
    appCasinoState: AppCasinoState;
}
export const GridHeader = observer('GridHeader', (props: GridHeaderPropsType) => {
    return (
        <HeaderWrapper>
            <CasinoIconWrapper />
            <HeaderTitle>
                <I18n
                    langKey='casino.mini-games-widget.title'
                    defaultText='Casino Mini Games'
                />
            </HeaderTitle>
            <PlayNowButton appCasinoState={props.appCasinoState} />
        </HeaderWrapper>
    );
});
