import styled from '@emotion/styled';

import { withConfig } from 'src/withConfig';



export const GoalScorerMarketWrapper = withConfig(theme => styled('div', { label: 'GoalScorerMarketWrapper' })`

    display: flex;

    flex-flow: row nowrap;

    position: relative;

    &::after {

        border-bottom: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};

        content: '';

        left: 0;

        margin-top: -1px;

        position: absolute;

        top: 100%;

        width: 100%;

    }

`);



export const GoalScorerMarketListWrapper = withConfig(theme => styled('div', { label: 'GoalScorerMarketListWrapper' })`

    border-right: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};

    display: flex;

    flex-flow: column nowrap;

    flex: 1 1 100%;

    &:empty {

        display: none;

    }

`);



export const GoalScorerMarketListItem = styled('div', { label: 'GoalScorerMarketListItem' })`

    display: flex;

    flex-flow: row nowrap;

`;



export const MarketGroupCaptionDesktop = withConfig(theme => styled('div', { label: 'MarketGroupCaptionDesktop' })`

    align-items: center;

    border-bottom: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};

    display: flex;

    justify-content: space-between;

    line-height: 1.4;

    padding-top: 1px;

    position: relative;

`);



interface CaptionDesktopPropsType {

    isFirst?: boolean;

}



export const CaptionDesktop = withConfig(theme => styled('span', { label: 'CaptionDesktop' })<CaptionDesktopPropsType>`

    border-right: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};

    border-left: 1px solid ${theme.star.marketGroupGoalscorer.borderColor};

    margin-left: -1px;

    color: ${theme.star.marketGroupGoalscorer.txtColor};

    font-size: ${theme.star.fontSize.xSmall};

    text-align: ${({ isFirst }): string => isFirst === true ? 'left': 'center'};

    padding-left: ${({ isFirst }): string => isFirst === true ? '8px': '0'};

    width: ${({ isFirst }): string => isFirst === true ? '100%': '50px'};

    &:first-of-type {

        border-left: none;

    }

    &:last-of-type {

        &:not(:only-of-type) {

            border-right: none;

        }

    }

    @media ${theme.star.mediaQuery.tablet} {

        width: ${({ isFirst }): string => isFirst === true ? '100%': '68px'};

    }

`);



export const CaptionGroupDesktop = styled('div', { label: 'CaptionGroupDesktop' })`

    display: flex;

`;



export const CaptionDesktopRab = withConfig(theme => styled(CaptionDesktop, { label: 'CaptionDesktopRab' })<CaptionDesktopPropsType>`

    width: ${({ isFirst }): string => isFirst === true ? '100%': '49px'};



    @media ${theme.star.mediaQuery.tablet} {

        width: ${({ isFirst }): string => isFirst === true ? '100%': '64px'};

    }

`);

