import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'src/domains/layouts/webview/components/router/NewLink';
import { EmotionTheme } from 'src/domains/layouts/config/emotionTheme';
import { withConfig } from 'src/withConfig';

export const AccountHelperWrapper = withConfig(theme => styled('div', { label: 'AccountHelperWrapper' })<{smallerPadding?: boolean}>`
    font-size: ${theme.star.fontSize.small};
    text-align: center;
    padding: ${({ smallerPadding }):string => smallerPadding === true ? "16px 16px 0px 16px": "16px 16px 40px 16px"};
`);

interface AccountHelperLinkType {
    version: 'primary' | 'secondary';
}

const buildAccountHelperLink = (theme: EmotionTheme, version: 'primary' | 'secondary'): SerializedStyles | null => {

    if (version === 'primary'){
        return css`
            color: ${theme.star.accountHelperInfo.txtColor};
            &:hover {
                opacity: 0.8;
            }
        `;
    }

    return css`
        color: ${theme.star.signUp.footer.txtColor};
        &:hover {
            color: ${theme.star.signUp.footer.txtColorSecondary};
        }
    `;
};

export const AccountHelperLink = withConfig(theme => styled(Link, { label: 'AccountHelperLink' })<AccountHelperLinkType>`
    transition: 0.2s;
    font-weight: ${theme.star.fontWeight.bold};
    text-decoration: none;

    ${({ version }): SerializedStyles | null => buildAccountHelperLink(theme, version)};
`);

export const Separator = withConfig(theme => styled('span', { label: 'Separator' })<AccountHelperLinkType>`
    color: ${({ version }): string => version === 'primary' ? theme.star.accountHelperInfo.txtColor : theme.star.signUp.footer.txtColor};
    margin: 0 8px;
`);
