import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { ButtonsGroup, Container, Header, Heading, Text } from './IdMissionPopup.style';
import { action, computed, makeObservable, observable } from 'mobx';
import { apiGetSessionIdRequest } from 'src/api_openapi/v2/idMission/apiGetSessionId';
import { Common, useCommon } from 'src/domains/common/Common';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { Popup } from 'src/domains/players/webview/ui/Popup';
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { IdMissionValidation } from './components/IdMissionValidation';
import { IdentityVerificationVariantType } from 'src/api_openapi/v2/idMission/schemas';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import { IdentityAPI } from 'src/domains/players/state/IdMission/IdentityAPI';
import { PersonalData } from 'idmission-web-sdk/dist/components/submission/types';

export type IdmissionSDKType = IdentityVerificationVariantType | null;

export interface ErrorType {
    type: 'known' | 'unknown';
    message: string;
}
export class IdMissionState {
    @observable public isIframeOpen: boolean = false;
    @observable public errors: ErrorType[] = [];
    @observable public sessionId: string | null = null;
    public readonly sessionIdResource: Resource<string | null>;

    public constructor(private common: Common) {
        makeObservable(this);

        this.sessionIdResource = new Resource(async (): Promise<string | null> => {
            const { gatewayApiHost } = this.common.envVariables;
            if (gatewayApiHost === null) {
                this.createErrorMessage('Unknown gatewayApiHost');
                return null;
            }
            try {
                const response = await apiGetSessionIdRequest(gatewayApiHost, { requestBody: {} }, {});
                if (response.status === 200) {
                    return response.body.id;
                } else {
                    this.createErrorMessage(`${response.status} - api error`);
                    return null;
                }
            } catch (error: unknown) {
                if (error instanceof Error) {
                    this.createErrorMessage(error.message);
                } else {
                    this.createErrorMessage(String(error));
                }
                return null;
            }
        });
    }

    @action public handleIframeToggle = (): void => {
        this.isIframeOpen = !this.isIframeOpen;
    };

    @action public handleFinalize = async (id: number, response: unknown): Promise<void> => {
        try {
            const accountId = this.common.session.userId;

            if (accountId === null) {
                this.createErrorMessage('Unknown accountId');
                return;
            }

            const identityAPI = IdentityAPI.get(this.common);

            await identityAPI.postFinalizeRequest({
                id,
                requestBody: {
                    universe: this.common.envVariables.universe,
                    accountId,
                    rawIdmidssionResult: JSON.stringify(response),
                },
            });
        } catch (e) {
            this.createErrorMessage('Unknown api error');
        }
    };

    @computed public get transformCustomerData(): PersonalData | null {
        const customerData = BasicDataModel.get(this.common).basicDataReady;

        if (customerData === null) {
            return null;
        }

        return {
            addressLine1: customerData.addressLine1 ?? undefined,
            addressLine2: customerData.addressLine2 ?? undefined,
            city: customerData.city ?? undefined,
            country: customerData.country ?? undefined,
            email: customerData.email ?? undefined,
            name: `${customerData.firstName ?? ''} ${customerData.surname ?? ''}`,
            postalCode: customerData.postCode ?? '',
            phone: customerData.mobilePhone?.number,
            dob: customerData.birthDate.split('-').reverse().join('/'),
        };
    }

    @action public createErrorMessage = (message?: string): void => {
        const error: ErrorType =
            message === undefined ? { type: 'unknown', message: 'Unknown Error' } : { type: 'known', message };
        this.errors.push(error);
    };
}

export const IdMission = observer('IdMission', () => {
    const common = useCommon();
    const [state] = useState(() => new IdMissionState(common));

    const { appPlayersState } = useAppStateContext();
    const sessionId = state.sessionIdResource.getReady();

    const isOpen = appPlayersState.identityState?.processIdMission.isOpen ?? false;
    const id = appPlayersState.identityState?.processIdMission.id ?? null;

    if (isOpen === false || id === null) {
        return null;
    }

    return (
        <Popup
            className='idmission-popup'
            overlayClose={true}
            closeButton={true}
            maxWidth='320px'
        >
            <Container>
                <Header>
                    <Heading data-test='id-mission.popup.header'>
                        <I18n
                            langKey='id-mission.popup.header-v2'
                            defaultText='Modal Title'
                        />
                        <Text data-test='id-mission-popup.text'>
                            <I18n
                                langKey='id-mission.popup.text'
                                defaultText='Lorem ipsum dolor sit amet consectetur. Enim lacus magnis at proin suspendisse enim cursus auctor justo.'
                            />
                        </Text>
                    </Heading>
                </Header>
                <ButtonsGroup>
                    <Button
                        size='medium'
                        onClick={state.handleIframeToggle}
                    >
                        <I18n
                            langKey='id-mission.popup.button.verify'
                            defaultText='Verify Now'
                        />
                    </Button>
                    <Button
                        size='medium'
                        version='secondary-light'
                        onClick={(): void => appPlayersState.identityState?.toggleIdMissionPopup()}
                    >
                        <I18n
                            langKey='id-mission.popup.button.remind'
                            defaultText='Remind me later'
                        />
                    </Button>
                </ButtonsGroup>

                {state.errors.length > 0 && (
                    <div style={{ padding: '16px' }}>
                        {state.errors.map((error) => (
                            <Messages
                                type='error'
                                marginTop='8px'
                                key={error.type}
                                message={error.message}
                            />
                        ))}
                    </div>
                )}

                {sessionId !== null && state.isIframeOpen && state.transformCustomerData !== null && (
                    <IdMissionValidation
                        sessionId={sessionId}
                        openType={appPlayersState.identityState?.processIdMission.type ?? 'IdCard'}
                        handleToggle={state.handleIframeToggle}
                        personalData={state.transformCustomerData}
                        handleFinalize={state.handleFinalize}
                        id={id}
                    />
                )}
            </Container>
        </Popup>
    );
});
