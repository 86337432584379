import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { DepositFailure } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { PaysafeTabState } from './PaysafeTab.state';
import { PaysafeTab } from './PaysafeTab';
import { PaysafeFrame } from './paysafeFrame/PaysafeFrame';
import { assertNever } from 'src_common/common/assertNever';
import { DepositFinalView } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/DepositFinalView';
import { useCommon } from 'src/domains/common/Common';
import { YaspaDeposit } from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/YaspaDeposit';

interface PropsType {
    isSignup: boolean;
}

export const PaysafeJourney = observer('PaysafeJourney', ({ isSignup }: PropsType): JSX.Element => {
    const common = useCommon();

    const [state] = useState(() => new PaysafeTabState(common, isSignup));

    const renderContent = (): JSX.Element => {
        switch (state.stepsState.step.type) {
            case 'set-method':
                return <PaysafeTab state={state} />;
            case 'set-method-signup':
                return (
                    <PaysafeTab
                        state={state}
                        isSignup={true}
                    />
                );
            case 'iframe-view':
                return (
                    <PaysafeFrame
                        paymentIframeData={state.stepsState.step.paymentIframeData}
                        paysafeTabState={state}
                        paysafePaymentProvider={state.paysafePaymentProviderConfig}
                    />
                );

            case 'iframe-view-yaspa':
                return (
                    <YaspaDeposit
                        src={state.stepsState.step.url}
                        onMessage={state.stepsState.redirectFromYaspaIframe}
                        yaspaView='iframe-view'
                        accountHelperMail={state.accountHelperMail}
                        isSignup={isSignup}
                        hideDepositSuccess={state.hideDepositSuccess}
                    />
                );

            case 'final-view':
                return (
                    <DepositFinalView
                        transactionId={state.stepsState.step.transactionId}
                        isSignup={isSignup}
                        accountHelperMail={state.accountHelperMail}
                        hideDepositSuccess={state.hideDepositSuccess}
                        paymentProvider='paysafe'
                    />
                );
            case 'failure-view':
                return (
                    <DepositFailure
                        isSignup={isSignup}
                        issueType={state.stepsState.step.failureType}
                        accountHelperMail={state.accountHelperMail}
                        transactionCallback={state.stepsState.step.transactionCallback}
                        hideDepositSuccess={state.stepsState.redirectToSetMethod}
                    />
                );
            default:
                return assertNever('PaysafeJourney step', state.stepsState.step);
        }
    };

    return <>{renderContent()}</>;
});
